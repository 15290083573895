import React, { Component } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { connect } from "react-redux";
import { Ripple } from "primereact/ripple";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { mapKey } from "../../utilities/constant";
import { Badge } from "primereact/badge";
import moment from "moment";
import { VehicleSrvice } from "../../service/VehicleService";
import { convertValue } from "../../utilities/unitConversion";
import { Card } from "primereact/card";
import { Timeline } from "primereact/timeline";
import { classNames } from "primereact/utils";
import { secondsToHm, dispatchAction } from "../../utilities/utillFunction";
import { AssetService } from "../../service/AssetService";
import { Toast } from "primereact/toast";
import { FuelSrvice } from "../../service/FuelService";
import { customizedMarker, customizedContent, loader } from "../../utilities/UICommonFunc";
import { drivingScoreCard } from "../newVehTrackingWithFuel/Components";

const map_start_icon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#00A42F; stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>';

class VehicleOBDLive extends Component {
    mapRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            H: null,
            map: null,
            vehicleDetails: null,
            thingName: this.props.thingName,
            make: this.props.make,
            model: this.props.model,
            checked1: false,
            greenDrivingScore: null,
            aceleration: null,
            braking: null,
            cornering: null,
            speeding: null,
            displayBasic: false,
            displayBasic1: false,
            displayBasic2: false,
            displayBasic3: false,
            allData: null,
            currentWhether: null,
        };
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.serviceID = localStorage.getItem("serviceId");
        this.serviceDetails = JSON.parse(localStorage.getItem("service"));
        this.form_date = moment(this.props.date, "YYYY-MM-DD").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.firstEvtData = null;

        this.imagePath = process.env.REACT_APP_IMAGE_STATIC_URL + "/" + this.serviceID + "/" + this.props.thingName + ".jpg";
        this.vehicleService = new VehicleSrvice();
        this.AssestService = new AssetService();
        this.fuelService = new FuelSrvice();
        this.showWarn = this.showWarn.bind(this);
        this.panelHeaderTemplate = this.panelHeaderTemplate.bind(this);

        this.sampleGeoEvents = [
            { thingname: "cb-chevywht1", state: "InsideGF", name: "GeoFence_1" },
            { thingname: "cb-chevywht1", state: "OutsideGF", name: "GeoFence_1" },
        ];
    }
    onClick(name, position) {
        let state = {
            [`${name}`]: true,
        };

        if (position) {
            state = {
                ...state,
                position,
            };
        }

        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false,
        });
    }
    showWarn() {
        this.toast.show();
    }

    componentWillUnmount = () => {
        this.ws.close();
    };

    onmessage = (evt) => {
        let evtData = JSON.parse(evt.data);

        const { region } = this.serviceDetails;
        let weatherUnitSys = region === "us" ? "Imperial" : "Metric";
        let position = {
            lat: evtData.destination.lat,
            lng: evtData.destination.lng,
        };
        this.vehicleService.getCurrentWeather(position, weatherUnitSys).then((item) => {
            let currentWhether = item;
            if (currentWhether && currentWhether.weather.length > 0) {
                this.setState({ currentWhether: currentWhether });
            }
        });
        if (!this.firstEvtData) {
            this.firstEvtData = JSON.parse(JSON.stringify(evtData));
            evtData.first = true;
        }

        if (evtData && Object.keys(evtData).length) {
            if (this.state.map && this.state.vehicleDetails && !evtData.first) {
                let prevData = JSON.parse(JSON.stringify(this.state.vehicleDetails));
                if (this.state.vehicleDetails) {
                    if (evtData.totalTimeTravelled) prevData.totalTimeTravelled += evtData.totalTimeTravelled;
                    if (evtData.totalDistanceTravelled) prevData.totalDistanceTravelled += evtData.totalDistanceTravelled;
                    if (evtData.maxSpeed && evtData.maxSpeed > prevData.maxSpeed) prevData.maxSpeed = evtData.maxSpeed;
                    if (evtData.totalDistanceTravelled && evtData.totalTimeTravelled) {
                        prevData.avgSpeed = (evtData.totalDistanceTravelled / evtData.totalTimeTravelled) * 3.6;
                    }
                    if (evtData.origin && evtData.origin.lat && evtData.destination && evtData.destination.lat && evtData.origin.lat !== evtData.destination.lat) {
                        let wayPoints = [];
                        if (evtData.wayPoints && evtData.wayPoints.length) {
                            for (let i = 0; i < evtData.wayPoints.length; i++) {
                                wayPoints.push(`via=${evtData.wayPoints[i].lat},${evtData.wayPoints[i].lng}`);
                            }
                        }
                        this.vehicleService
                            .getRoute(`${evtData.origin.lat},${evtData.origin.lng}`, `${evtData.destination.lat},${evtData.destination.lng}`, wayPoints.length ? wayPoints.join("&") : null)
                            .then((response) => {
                                this.updateLocOfLastPing(evtData.destination);
                                this.appendPolyLineToMap(response.routes[0], prevData);
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    } else {
                        this.appendPolyLineToMap(null, prevData);
                    }
                }
            } else {
                if (evtData.first) this.processData(evtData);
            }
        }
    };

    updateLocOfLastPing = (loc) => {
        let objects = this.state.group.getObjects();
        let objectIndex = objects.findIndex((o) => o.data && o.data.name && o.data.name === "endMarker");
        if (objectIndex > -1) objects[objectIndex].setGeometry({ lat: loc.lat, lng: loc.lng });
    };

    appendPolyLineToMap = (route, vehData) => {
        if (route && route.sections && route.sections.length) {
            route.sections.forEach((section) => {
                let linestring = this.state.H.geo.LineString.fromFlexiblePolyline(section.polyline);
                let polyline = new this.state.H.map.Polyline(linestring, {
                    style: {
                        lineWidth: 5,
                        fillColor: "white",
                        strokeColor: "rgba(10, 3, 241, 1)",
                        lineDash: [0, 2],
                        lineTailCap: "arrow-tail",
                        lineHeadCap: "arrow-head",
                    },
                });
                this.state.group.addObject(polyline);
                this.state.map.getViewModel().setLookAtData({
                    bounds: polyline.getBoundingBox(),
                    zoom: 13,
                });
            });
            this.setState({ map: this.state.map, group: this.state.group, vehicleDetails: vehData });
        }
    };

    componentDidMount = () => {
        // let payload = {
        //     operation: "aggregate",
        //     aggregate: [
        //         {
        //             $match: {
        //                 thingName: this.state.thingName,
        //                 ts: { $gte: moment(new Date()).startOf("day").format("YYYY-MM-DD HH:mm:ss"), $lte: moment(new Date()).endOf("day").format("YYYY-MM-DD HH:mm:ss") },
        //                 greendrivingtype: {
        //                     $gt: 0,
        //                 },
        //             },
        //         },
        //         {
        //             $group: {
        //                 _id: "$greendrivingtype",
        //                 data: {
        //                     $push: {
        //                         greendrivingtype: "$greendrivingtype",
        //                         greendrivingvalue: "$greendrivingvalue",
        //                         greendrivingeventduration: "$greendrivingeventduration",
        //                         overspeeding: "$overspeeding",
        //                         longitude: "$longitude",
        //                         latitude: "$latitude",
        //                         ts: "$ts",
        //                     },
        //                 },
        //             },
        //         },
        //     ],
        //     allowDiskUse: true,
        // };

        // this.vehicleService.getVehicleDivingData(this.serviceID, payload).then((res) => {
        //     let aceleration;
        //     let braking;
        //     let cornering;
        //     let speeding;
        //     res.forEach((item) => {
        //         let stoppege_point = item.data;
        //         stoppege_point.forEach((item, index) => {
        //             let geoPoint = `${item.latitude},${item.longitude}`;
        //             this.vehicleService.getLatLongDetails(geoPoint).then((res) => {
        //                 item.index = index + 1;
        //                 item.adderss = res.items[0].address.label;
        //             });
        //         });

        //         if (item._id === 1) {
        //             aceleration = stoppege_point;
        //         } else if (item._id === 2) {
        //             braking = stoppege_point;
        //         } else if (item._id === 3) {
        //             cornering = stoppege_point;
        //         } else if (item._id === 4) {
        //             speeding = stoppege_point;
        //         }
        //     });
        //     this.setState({ aceleration: aceleration, braking: braking, cornering: cornering, speeding: speeding, greenDrivingScore: 0 });
        // });

        this.AssestService.getServiceObserSen(this.serviceID, "fleet").then((res) => {
            let vehicleStatusFIlter = res.filter((res) => res._id.fsmstate === "ONLINE");
            if (vehicleStatusFIlter && vehicleStatusFIlter.length) {
                let vehicleStatusData = vehicleStatusFIlter[0].ids;
                let index = vehicleStatusData.findIndex((item) => item.id === this.serviceID + ":" + this.props.thingName + ":totalrecords");
                if (index === -1) {
                    this.toast.show({ severity: "warn", summary: "Vehicle is offline", detail: `The vehicle is currently offline. Data shown is of ${moment(this.props.date).format("Do MMM YYYY")} when the vehicle was last online.`, life: 40000 });
                }
            }
        });
        let geoEventQuery = { ts: { $gte: this.form_date }, thingname: this.state.thingName };
        this.vehicleService.getGeoEvents(geoEventQuery).then((geoEvents) => {
            this.processGeofenceData(geoEvents);
            this.ws = new WebSocket(process.env.REACT_APP_LIVE_WEB_SOCKET + "/" + this.serviceID + "/" + this.props.thingName);
            this.ws.onmessage = this.onmessage;
        });
    };

    panelHeaderTemplate = (options) => {
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <span className={titleClassName}>Traking Basic Details</span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    };

    processData = (res) => {
        if (res.totalDistanceTravelled && res.totalTimeTravelled) {
            res.avgSpeed = (res.totalDistanceTravelled / res.totalTimeTravelled) * 3.6;
        }

        const origin = `${res.origin.lat},${res.origin.lng}`;
        const destination = `${res.destination.lat},${res.destination.lng}`;
        Promise.all([this.vehicleService.getLatLongDetails(origin), this.vehicleService.getLatLongDetails(destination)]).then((response) => {
            res.startAddress = response[0] && response[0].items && response[0].items[0] ? response[0].items[0].address : "-";
            res.endAddress = response[1] && response[1].items && response[1].items[0] ? response[1].items[0].address : "-";

            this.setState(
                {
                    vehicleDetails: res,
                },
                () => {
                    this.getVehicleRealTimeDetailedMap(res);
                }
            );
        });
    };

    getStrengthMarker = (H, map, station, icon, ui, address, data) => {
        let marker = new H.map.Marker({ lat: station.lat, lng: station.lng }, { icon: icon, data: station });
        let bubble;

        marker.addEventListener(
            "tap",
            function (evt) {
                bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: '<div id="vehicle-content"> <div id="vehicle-info"><div id="info">' + (station.name === "startMarker" ? "Start" : "End") + " Address: " + address.label + '</div><div id="info">Speed :' + station.spd + "</div></div></div>",
                });
                ui.addBubble(bubble);
            },
            false
        );

        return marker;
    };

    getVehicleRealTimeDetailedMap = (data) => {
        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        data.origin.name = "startMarker";
        data.destination.name = "endMarker";
        let startLocation = data.origin;
        let endLocation = data.destination;

        const map = new H.Map(this.mapRef.current, defaultLayers.vector.normal.map, {
            center: { lat: endLocation.lat, lng: endLocation.lng },
            zoom: 9,
            pixelRatio: window.devicePixelRatio || 1,
        });
        let wayPoints = [];
        if (data.wayPoints && data.wayPoints.length) {
            for (let i = 0; i < data.wayPoints.length; i++) {
                wayPoints.push(`via=${data.wayPoints[i].lat},${data.wayPoints[i].lng}`);
            }
            if (startLocation && (!startLocation.lat || !startLocation.lng)) {
                startLocation = data.wayPoints[0];
            }
            if (endLocation && (!endLocation.lat || !endLocation.lng)) {
                endLocation = data.wayPoints[data.wayPoints.length - 1];
            }
        }
        window.addEventListener("resize", () => map.getViewPort().resize());
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        const ui = H.ui.UI.createDefault(map, defaultLayers);
        let group = new H.map.Group();

        let markers = [];
        const startIcon = new H.map.Icon(map_start_icon, { size: { w: 32, h: 32 } });
        const endIcon = new H.map.Icon("/images/car.png", { size: { w: 32, h: 32 } });

        let startMarker = this.getStrengthMarker(H, map, startLocation, startIcon, ui, data.startAddress);
        markers.push(startMarker);

        let endMarker = this.getStrengthMarker(H, map, endLocation, endIcon, ui, data.endAddress);
        markers.push(endMarker);
        group.addObjects(markers);
        this.addGeoFencesToMap(H, group);
        let distance = 0;
        this.vehicleService
            .getRoute(`${startLocation.lat},${startLocation.lng}`, `${endLocation.lat},${endLocation.lng}`, wayPoints.length ? wayPoints.join("&") : null)
            .then((response) => {
                if (response.routes[0] && response.routes[0].sections && response.routes[0].sections.length) {
                    response.routes[0].sections.forEach((s) => {
                        distance = distance + s.summary.length;
                    });
                }
                this.addRouteShapeToMap(response.routes[0], H, map, group);
            })
            .catch((reason) => {
                console.error(reason);
            });
    };

    addRouteShapeToMap(route, H, map, group) {
        route.sections.forEach((section, i) => {
            let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
            let polyline = new H.map.Polyline(linestring, {
                style: {
                    lineWidth: 5,
                    fillColor: "white",
                    strokeColor: "rgba(10, 3, 241, 1)",
                    lineDash: [0, 2],
                    lineTailCap: "arrow-tail",
                    lineHeadCap: "arrow-head",
                },
            });
            group.addObject(polyline);
        });
        map.addObject(group);
        map.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
        });

        this.setState({ H, map, group });
    }

    addManueversToMap = (route, H, map) => {
        var svgMarkup = '<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="5" fill="#1b468d" stroke="white" stroke-width="1" /></svg>',
            dotIcon = new H.map.Icon(svgMarkup, { anchor: { x: 6, y: 6 } }),
            group = new H.map.Group(),
            i;

        route.sections.forEach((section) => {
            let poly = H.geo.LineString.fromFlexiblePolyline(section.polyline).getLatLngAltArray();

            let actions = section.actions;
            // Add a marker for each maneuver
            for (i = 0; i < actions.length; i += 1) {
                let action = actions[i];
                var marker = new H.map.Marker(
                    {
                        lat: poly[action.offset * 3],
                        lng: poly[action.offset * 3 + 1],
                    },
                    { icon: dotIcon }
                );
                group.addObject(marker);
            }

            group.addEventListener(
                "tap",
                function (evt) {
                    this.map.setCenter(evt.target.getGeometry());
                    this.openBubble(evt.target.getGeometry(), evt.target.instruction);
                },
                false
            );

            // Add the maneuvers group to the map
            map.addObject(group);
        });
    };

    addGeoFencesToMap = (H, group) => {
        if (this.props.geofences && this.props.geofences.length) {
            this.props.geofences.forEach((g) => {
                let polygon = H.util.wkt.toGeometry(g.data);
                group.addObject(new H.map.Polygon(polygon, { style: { strokeColor: "rgba(128, 0, 0, 0.6)" }, data: g.name }));
            });
        }
    };
    processGeofenceData = (events) => {
        let stateObj = { geoEvents: events };
        if (events && events.length) {
            events = events.sort((a, b) => {
                return a.ts - b.ts;
            });
            let entryCnt = events.filter((e) => e.state === "InsideGF");
            let exitCnt = events.filter((e) => e.state === "OutsideGF");
            stateObj.entryCnt = entryCnt.length;
            stateObj.exitCnt = exitCnt.length;
        }
        this.setState(stateObj);
    };
    marker = (item) => {
        return (
            <span className="custom-marker shadow-2 p-2" style={{ backgroundColor: item.state === "OutsideGF" ? "#628c3f" : "#0c6291" }}>
                <i className={classNames("marker-icon", "fa-solid fa-right-" + (item.state === "OutsideGF" ? "from" : "to") + "-bracket")} style={{ transform: item.state === "OutsideGF" ? "rotate(180deg)" : "none" }}></i>
            </span>
        );
    };

    content = (item) => {
        return (
            <div onClick={() => this.geoEventClick(item)} style={{ cursor: "pointer" }}>
                <Card className="mb-3">
                    <div style={{ fontSize: "1.3rem", fontWeight: "bold", padding: "0" }}>
                        {(item.state === "OutsideGF" ? "Exit " : "Entered ") + item.name}
                        {item.ts ? <span style={{ paddingLeft: "2vh", fontSize: "1rem", color: "#00000099" }}>{item.ts}</span> : null}
                    </div>
                </Card>
            </div>
        );
    };

    geoEventClick = (event) => {
        let objects = this.state.group.getObjects();
        if (event.name !== this.state.selectedGeoName) {
            let objectIndex = objects.findIndex((o) => o.data === event.name);
            let prevObjIndex = objects.findIndex((o) => o.data === this.state.selectedGeoName);

            if (objectIndex > -1) {
                objects[objectIndex].setStyle({ fillColor: "rgba(226, 245, 39, 0.48)", strokeColor: "rgba(128, 0, 0, 0.6)" });
                objects[prevObjIndex].setStyle({ fillColor: "rgba(0, 85, 170, 0.4)", strokeColor: "rgba(128, 0, 0, 0.6)" });

                this.setState({
                    selectedGeoName: event.name,
                });
            }
        }
    };

    fuelingDefuelingEvents = (item) => {
        return (
            <div className={`lg:col-4 col-12`} style={{ height: '100%' }}>
                <div
                    className="grid card vehicleStatusCard w-full"
                    style={{
                        border: "1px solid #E5E6EB",
                        display: "flex",
                        flexDirection: "column",
                        height: '100%',
                    }}
                >
                    <div
                        className="col-12 flex flex-wrap justify-content-between align-items-center"
                        style={{ flex: 1 }}
                    >
                        <h5
                            className="col-12 py-0 mt-0 inline-flex chartHeading justify-content-between m-0 p-0"
                            style={{
                                lineHeight: "normal", // Set to normal
                                marginBottom: "0.5rem",
                                fontSize: "1rem" // Adjust margin for spacing
                            }}
                        >
                            {item.type.toUpperCase()}
                            <span className="m-0 p-0 subText">
                                {moment(item.date).format("MMM Do YY")}
                            </span>
                        </h5>
                        <p
                            style={{
                                fontSize: "2.25rem",
                                fontWeight: "600",
                                color: `${item.type === "fuelling" ? "#8CC63F" : "#F67E6D"}`,
                                lineHeight: "normal", // Set to normal
                            }}
                            className="m-0 p-0"
                        >
                            {item.amount.toFixed(2)} Ltr
                        </p>
                        <p
                            className="col-12"
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                color: `#0C6291`,
                                lineHeight: "normal", // Set to normal // Reduce spacing
                            }}
                        >
                            {item.type === "fuelling"
                                ? `₹${(item.amount * item.fuelCost).toFixed(2)}`
                                : ""}
                        </p>
                        <div
                            className="m-0 p-0 col-12"
                            style={{
                                display: "flex",
                                justifyContent: "around",
                                flexWrap: "wrap",
                            }}
                        >
                            <p
                                className="grid m-0 p-0 subText lg:col-10 col-12"
                                style={{
                                    lineHeight: "normal", // Set to normal

                                }}
                            >
                                {item.city + ", " + item.state + ", " + item.zipcode}
                            </p>
                            <i
                                className="pi pi-car lg:col-2 col-12 m-0 p-1 inline-flex justify-content-center align-items-center"
                                style={{
                                    color: `${item.type === "fuelling" ? "#8CC63F" : "#F67E6D"
                                        }`,
                                    backgroundColor: `${item.type === "fuelling"
                                        ? "rgba(140, 198, 63, 0.20)"
                                        : "rgba(246, 126, 109, 0.20)"
                                        }`,
                                    borderRadius: "50%",
                                    fontSize:
                                        window.innerWidth < 1024
                                            ? "0.8rem"
                                            : "1.2rem", // Adjust font size for small screens
                                    width:
                                        window.innerWidth < 1024
                                            ? "2rem"
                                            : "3rem", // Adjust width for small screens
                                    height:
                                        window.innerWidth < 1024
                                            ? "2rem"
                                            : "3rem", // Adjust height for small screens
                                }}
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    location = (value) => {
        return (
            <>
                <p>
                    {value.latitude.toFixed(5)}/{value.longitude.toFixed(5)}
                </p>
            </>
        );
    };

    timeFormat = (value) => {
        return <>{moment(value.ts).format("Do MMM, YY HH:mm")}</>;
    };

    handleTabOpen = async (e) => {
        if (e.index === 1) {
            //Fuellling/Defuleing event click
            let query = {
                thingName: this.state.thingName,
                date: moment(this.state.date4).format("YYYY-MM-DD"),
            };
            let res = await this.fuelService.getFuelingEvents(this.serviceID, { filter: query });
            if (res && res.length) {
                this.setState({ fuellingEvents: res });
            } else {
                this.setState({ fuellingEvents: [], fuellingMsg: "Events not available for this day" });
            }
        }
    };
    render = () => {
        const rightContents = (
            <React.Fragment>
                <div className="grid align-items-center">
                    {/* Back Button */}
                    <div className="lg:col-2 flex justify-content-center">
                        <Button
                            icon="pi pi-chevron-left"
                            onClick={this.props.reportBack}
                            style={{ backgroundColor: "#B6D475",color:"black" }}
                        />
                    </div>
                    <div className="drivingScoreCard lg:col-10 col-10 flex align-items-center justify-content-between" style={{ border: "1px solid white" }}>
                        <div
                            className="m-0 p-0 flex-shrink-0"
                            style={{
                                borderRadius: "8px",
                                border: "1px solid rgba(116, 120, 141, 0.20)",
                                height: "4rem",
                                width: "4rem",
                                overflow: "hidden",
                            }}
                        >
                            <img
                                src={`${process.env.REACT_APP_IMAGE_STATIC_URL}/${localStorage.getItem("serviceId")}/${this.props?.thingName}.jpg`}
                                onError={e=>{e.target.src="/images/car-solid.svg" ;e.onerror=null}}
                                alt="Vehicle"
                                style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                        </div>

                        {/* Details Section */}
                        <div className="flex flex-column ml-4" style={{ flexGrow: 1 }}>
                            {/* License Plate */}
                            <p style={{ margin: 0, color: "#5E6771", fontWeight: 500 }}>
                                <strong>License Plate No:</strong>&nbsp;
                                <span style={{ color: "#1D2129", fontWeight: "bold" }}>
                                    {this.props.vehicleNumber.toUpperCase()}
                                </span>
                            </p>
                            {/* Make */}
                            <p style={{ margin: 0, color: "#5E6771", fontWeight: 500 }}>
                                <strong>Make:</strong>&nbsp;
                                <span style={{ color: "#1D2129", fontWeight: "bold" }}>
                                    {this.state.make.toUpperCase()}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
        return (
            <div className="grid">
                <Toast ref={(el) => (this.toast = el)} />
                <div className="col-12 md:col-12 lg:col-12">
                    {this.state.vehicleDetails ? (
                        <>
                            <div className="grid">
                                <div className="col-12">
                                    <Toolbar left={rightContents} />
                                </div>
                            </div>
                            <div className="grid mb-3">
                                <div className="col-12 lg:col-4">
                                    {this.state.currentWhether ? (
                                        <div className="card mb-3">
                                            <div className="flex justify-content-between align-items-center">
                                                <div className="text-blue-500 wi-ioc">
                                                    {this.state.currentWhether.weather[0].main === "Thunderstorm" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/storm.png" />
                                                    ) : this.state.currentWhether.weather[0].main === "Drizzle" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/drizzle.png" />
                                                    ) : this.state.currentWhether.weather[0].main === "Rain" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/heavy-rain.png" />
                                                    ) : this.state.currentWhether.weather[0].main === "Snow" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/snowflake.png" />
                                                    ) : this.state.currentWhether.weather[0].main === "Clouds" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/cloudy.png" />
                                                    ) : this.state.currentWhether.weather[0].main === "Clear" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/sun.png" />
                                                    ) : this.state.currentWhether.weather[0].main === "Mist" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/mist.png" />
                                                    ) : this.state.currentWhether.weather[0].main === "Mist" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/smoke.png" />
                                                    ) : this.state.currentWhether.weather[0].main === "Haze" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/haze.png" />
                                                    ) : this.state.currentWhether.weather[0].main === "Dust" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/sand.png" />
                                                    ) : this.state.currentWhether.weather[0].main === "Fog" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/haze.png" />
                                                    ) : this.state.currentWhether.weather[0].main === "Sand" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/sand.png" />
                                                    ) : this.state.currentWhether.weather[0].main === "Tornado" ? (
                                                        <img style={{ width: "50px" }} alt="" src="images/hurricane.png" />
                                                    ) : (
                                                        <img style={{ width: "50px" }} alt="" src="images/haze.png" />
                                                    )}
                                                    <span className="block text-sm text-700">
                                                        {this.state.currentWhether.weather[0].main}, {this.state.currentWhether.weather[0].description}
                                                    </span>
                                                </div>
                                                <div className="text-right">
                                                    <span className="text-lg text-700 block">
                                                        <svg viewBox="0 0 320 512" width="12" height="12">
                                                            <path d="M160 48c-35.3 0-64 28.7-64 64V273.9c0 14.5-5.7 27.1-12.8 36.6C71.1 326.5 64 346.4 64 368c0 53 43 96 96 96s96-43 96-96c0-21.6-7.1-41.5-19.2-57.5c-7.1-9.5-12.8-22.1-12.8-36.6V112c0-35.3-28.7-64-64-64zM48 112C48 50.2 98.1 0 160 0s112 50.1 112 112V273.9c0 1.7 .7 4.4 3.2 7.8c18.1 24.1 28.8 54 28.8 86.4c0 79.5-64.5 144-144 144S16 447.5 16 368c0-32.4 10.7-62.3 28.8-86.4c2.5-3.4 3.2-6.1 3.2-7.8V112zM208 368c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-20.9 13.4-38.7 32-45.3V200c0-8.8 7.2-16 16-16s16 7.2 16 16V322.7c18.6 6.6 32 24.4 32 45.3z" />
                                                        </svg>{" "}
                                                        {this.state.currentWhether.main.humidity}% &nbsp; &nbsp;
                                                        <svg viewBox="0 0 512 512" width="12" height="12">
                                                            <path d="M288 24c0 13.3 10.7 24 24 24h44c24.3 0 44 19.7 44 44s-19.7 44-44 44H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H356c50.8 0 92-41.2 92-92s-41.2-92-92-92H312c-13.3 0-24 10.7-24 24zm64 368c0 13.3 10.7 24 24 24h44c50.8 0 92-41.2 92-92s-41.2-92-92-92H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H420c24.3 0 44 19.7 44 44s-19.7 44-44 44H376c-13.3 0-24 10.7-24 24zM120 512h44c50.8 0 92-41.2 92-92s-41.2-92-92-92H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H164c24.3 0 44 19.7 44 44s-19.7 44-44 44H120c-13.3 0-24 10.7-24 24s10.7 24 24 24z" />
                                                        </svg>{" "}
                                                        {convertValue(this.state.currentWhether.wind.speed, "speed", this.props.unit, "mph")}
                                                    </span>
                                                    <span className="text-4xl font-bold block">{this.state.currentWhether.main.temp + (this.props.unit === "MKS" ? " \xB0C." : " \xB0F")}</span>
                                                    <span className="text-sm text-700 block">Feels like {this.state.currentWhether.main.feels_like + (this.props.unit === "MKS" ? " \xB0C." : " \xB0F")}</span>
                                                </div>
                                            </div>
                                            {/* <p className="mt-2">As on {moment(this.props.currentTime).tz('America/Los_Angeles').format('MMM DD, HH:mm A')}</p> */}
                                            {/* <p className="mt-2">As on {moment(this.props.currentTime).format("MMM DD, HH:mm A")}</p> */}
                                        </div>
                                    ) : null}
                                    <div className="card vehicle_info lightColor" style={{ height: "76%", color: "#74788D !important" }}>
                                        <div className="font-semibold  lightColor" style={{ marginBottom: 0, padding: "10px 0", borderBottom: "1px solid var(--surface-300)", color: "#74788D" }}>
                                            Last Location
                                            <br />
                                            <div style={{ color: "var(--blue-600)" }}> {this.state.vehicleDetails.endAddress.label}</div>
                                        </div>
                                        <p className="font-semibold lightColor" style={{ color: "#74788D" }}>
                                            Ignition Status
                                            {this.state.vehicleDetails && this.state.vehicleDetails.destination ? <span className="text-green-600">{this.state.vehicleDetails.destination.ign === 1 ? "ON" : "OFF"}</span> : "--"}
                                        </p>
                                        <p className="font-semibold lightColor " style={{ color: "#74788D" }}>
                                            Total Distance Travelled
                                            <span>{convertValue(this.state.vehicleDetails.totalDistanceTravelled / 1000, "distance", this.props.unit)}</span>
                                        </p>
                                        <p className="font-semibold lightColor" style={{ color: "#74788D" }}>
                                            Total Time travelled
                                            <span> {this.state.vehicleDetails.totalTimeTravelled ? secondsToHm(this.state.vehicleDetails.totalTimeTravelled) : "--"}</span>
                                        </p>
                                        <p className="font-semibold lightColor" style={{ color: "#74788D" }}>
                                            Max Speed
                                            <span>{convertValue(this.state.vehicleDetails.maxSpeed, "speed", this.props.unit)}</span>
                                        </p>
                                        <p className="font-semibold lightColor" style={{ color: "#74788D" }}>
                                            Avg Speed
                                            <span>{this.state.vehicleDetails.avgSpeed ? convertValue(this.state.vehicleDetails.avgSpeed, "speed", this.props.unit) : "--"}</span>
                                        </p>
                                        {this.props.fuel && this.state.vehicleDetails.fuelConsumed ? (
                                            <p className="font-bold lightColor" style={{ color: "#74788D" }}>
                                                Fuel Consumed
                                                <span> {this.state.vehicleDetails.fuelConsumed.toFixed(2) + " L"}</span>
                                            </p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-12 lg:col-8">
                                    <div className="card p-0">
                                        <div
                                            ref={this.mapRef}
                                            style={{
                                                width: "100%",
                                                height: "465px",
                                                overflow: "hidden",
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <Accordion onTabOpen={this.handleTabOpen}>
                                {this.props.geofences && this.props.geofences.length ? (
                                    this.state.geoEvents && this.state.geoEvents.length ? (
                                        <AccordionTab
                                            header={
                                                <div>
                                                    <span className="geofenceTitle">Geo-fence Events</span>
                                                    <span style={{ float: "right", fontSize: "1.2rem" }}>
                                                        <span>
                                                            {" "}
                                                            {"Total "} <Badge value={this.state.geoEvents.length} style={{ fontSize: "1rem", background: "#57624e" }} className="mr-2"></Badge>
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            {"Entry "} <Badge value={this.state.entryCnt || "0"} style={{ fontSize: "1rem", background: "#0c6291" }} className="mr-2"></Badge>
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            {"Exit "} <Badge value={this.state.exitCnt || "0"} style={{ fontSize: "1rem", background: "#628c3f" }} className="mr-2"></Badge>
                                                        </span>
                                                    </span>
                                                </div>
                                            }
                                            style={{ maxHeight: "30vh", overflowY: "auto" }}
                                        >
                                            <div className="widget-timeline">
                                                <Timeline value={this.state.geoEvents} className="customized-timeline" marker={this.marker} content={this.content} />
                                            </div>
                                        </AccordionTab>
                                    ) : null
                                ) : (
                                    <AccordionTab
                                        disabled={true}
                                        header={
                                            <div>
                                                <div className="grid">
                                                    <span className="geofenceTitle">Geo-fence Events</span>
                                                    <span style={{ fontSize: "1.2rem" }}>
                                                        <span>
                                                            {" "}
                                                            {"Total "} <Badge value={"0"} style={{ fontSize: "1rem", background: "#57624e" }} className="mr-2"></Badge>
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            {"Entry "} <Badge value={"0"} style={{ fontSize: "1rem", background: "#0c6291" }} className="mr-2"></Badge>
                                                        </span>
                                                        <span>
                                                            {" "}
                                                            {"Exit "} <Badge value={"0"} style={{ fontSize: "1rem", background: "#628c3f" }} className="mr-2"></Badge>
                                                        </span>
                                                    </span>
                                                    <span style={{ float: "right", color: "orange" }}>
                                                        Not Configured
                                                        <object data={"/images/no-configuration-setting-icon.svg"} width="16" height="16">
                                                            {" "}
                                                        </object>
                                                    </span>
                                                </div>
                                                <div style={{ marginTop: "2vh" }}>
                                                    <div className="widget-timeline">
                                                        <Timeline value={this.sampleGeoEvents} className="customized-timeline" marker={this.marker} content={this.content} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        style={{ overflowY: "auto" }}
                                    ></AccordionTab>
                                )}
                                {/* <AccordionTab
                                    disabled={this.state.aceleration === && this.state.braking === 0 && this.state.cornering === 0 && this.state.speeding === 0 ? true : false}
                                    header={
                                        this.state.aceleration === 0 && this.state.braking === 0 && this.state.cornering === 0 && this.state.speeding === 0 ? (
                                            <div className="grid">
                                                <span className="geofenceTitle">Driving Behaviour</span>
                                                <span style={{ float: "right", color: "orange" }}>
                                                    No Data / Not Configured
                                                    <object data={"/images/no-configuration-setting-icon.svg"} width="16" height="16">
                                                        {" "}
                                                    </object>
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="w-100">
                                                <div className="grid">
                                                    <span className="geofenceTitle">Driving Behaviour</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                    style={{ overflowY: "auto" }}
                                >
                                    <div className="grid">
                                        {this.state.greenDrivingScore === undefined || this.state.aceleration === null ? (
                                            <div className="col-12 lg:col-12">
                                                <Card className="card customCard" style={{ background: "#eee" }}>
                                                    <div className="greenDriving">
                                                        <h4>0</h4>
                                                        <p>Driving Score</p>
                                                    </div>
                                                </Card>
                                            </div>
                                        ) : (
                                            <div className="col-12 lg:col-12">
                                                <Card className="card customCard newcustomcard" style={{ background: "#eee" }}>
                                                    <div className="greenDriving">
                                                        <p>Driving Score : </p>
                                                        {this.state.greenDrivingScore < 50 ? (
                                                            <>
                                                                <h4 className="five">{parseFloat(this.state.greenDrivingScore).toFixed(0)}</h4>
                                                                <Rating value={1} readOnly stars={5} cancel={false} />
                                                            </>
                                                        ) : this.state.greenDrivingScore >= 50 && this.state.greenDrivingScore < 60 ? (
                                                            <>
                                                                <h4 className="four">{parseFloat(this.state.greenDrivingScore).toFixed(0)}</h4>
                                                                <Rating value={2} readOnly stars={5} cancel={false} />
                                                            </>
                                                        ) : this.state.greenDrivingScore >= 60 && this.state.greenDrivingScore < 75 ? (
                                                            <>
                                                                <h4 className="three">{parseFloat(this.state.greenDrivingScore).toFixed(0)}</h4>
                                                                <Rating value={3} readOnly stars={5} cancel={false} />
                                                            </>
                                                        ) : this.state.greenDrivingScore >= 75 && this.state.greenDrivingScore < 90 ? (
                                                            <>
                                                                <h4 className="two">{parseFloat(this.state.greenDrivingScore).toFixed(0)}</h4>
                                                                <Rating value={4} readOnly stars={5} cancel={false} />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <h4 className="one">{parseFloat(this.state.greenDrivingScore).toFixed(0)}</h4>
                                                                <Rating value={5} readOnly stars={5} cancel={false} />
                                                            </>
                                                        )}

                                                        <p className="tooltipParent">
                                                            <i className="pi pi-info-circle"></i>
                                                        </p>
                                                        <Tooltip target=".tooltipParent" tooltipOptions={{ className: "cyan-tooltip", position: "top" }}>
                                                            <ul className="customTooltip">
                                                                <li>
                                                                    <p>90-100</p>
                                                                    <Rating value={5} readOnly stars={5} cancel={false} />
                                                                </li>
                                                                <li>
                                                                    <p>75-90</p>
                                                                    <Rating value={4} readOnly stars={5} cancel={false} />
                                                                </li>
                                                                <li>
                                                                    <p>60-75</p>
                                                                    <Rating value={3} readOnly stars={5} cancel={false} />
                                                                </li>
                                                                <li>
                                                                    <p>50-60</p>
                                                                    <Rating value={2} readOnly stars={5} cancel={false} />
                                                                </li>
                                                                <li>
                                                                    <p>{"<50"}</p>
                                                                    <Rating value={1} readOnly stars={5} cancel={false} />
                                                                </li>
                                                            </ul>
                                                        </Tooltip>
                                                    </div>
                                                </Card>
                                            </div>
                                        )}
                                        <div className="col-12">
                                            <h2>Aggressive Driving Events</h2>
                                        </div>
                                    </div>

                                    <div className="grid">
                                        {this.state.aceleration === undefined || this.state.aceleration === null ? (
                                            <div className="col-12 lg:col-3">
                                                <Card className="card customCard">
                                                    <div className="greenDriving">
                                                        <h4>0</h4>
                                                        <p>Harsh Acceleration</p>
                                                    </div>
                                                </Card>
                                            </div>
                                        ) : (
                                            <div className="col-12 lg:col-3">
                                                <Card className="card customCard">
                                                    <div className="greenDriving" onClick={() => this.onClick("displayBasic")}>
                                                        <h4>{this.state.aceleration.length}</h4>
                                                        <p>Harsh Acceleration</p>
                                                    </div>
                                                </Card>
                                            </div>
                                        )}
                                        <Dialog header="Harsh Acceleration" visible={this.state.displayBasic} style={{ width: "50vw" }} onHide={() => this.onHide("displayBasic")}>
                                            <Acceleration value={this.state.aceleration} />
                                        </Dialog>
                                        {this.state.braking === undefined || this.state.braking === null ? (
                                            <div className="col-12 lg:col-3">
                                                <Card className="card customCard">
                                                    <div className="greenDriving">
                                                        <h4>0</h4>
                                                        <p>Harsh Braking</p>
                                                    </div>
                                                </Card>
                                            </div>
                                        ) : (
                                            <div className="col-12 lg:col-3">
                                                <Card className="card customCard">
                                                    <div className="greenDriving" onClick={() => this.onClick("displayBasic1")}>
                                                        <h4>{this.state.braking.length}</h4>
                                                        <p>Harsh Braking</p>
                                                    </div>
                                                </Card>
                                            </div>
                                        )}
                                        <Dialog header="Harsh Braking" visible={this.state.displayBasic1} style={{ width: "50vw" }} onHide={() => this.onHide("displayBasic1")}>
                                            <Braking value={this.state.braking} />
                                        </Dialog>
                                        {this.state.cornering === undefined || this.state.cornering === null ? (
                                            <div className="col-12 lg:col-3">
                                                <Card className="card customCard">
                                                    <div className="greenDriving">
                                                        <h4>0</h4>
                                                        <p>Harsh Cornering</p>
                                                    </div>
                                                </Card>
                                            </div>
                                        ) : (
                                            <div className="col-12 lg:col-3">
                                                <Card className="card customCard">
                                                    <div className="greenDriving" onClick={() => this.onClick("displayBasic2")}>
                                                        <h4>{this.state.cornering.length}</h4>
                                                        <p>Harsh Cornering</p>
                                                    </div>
                                                </Card>
                                            </div>
                                        )}
                                        <Dialog header="Harsh Cornering" visible={this.state.displayBasic2} style={{ width: "50vw" }} onHide={() => this.onHide("displayBasic2")}>
                                            <Cornering value={this.state.cornering} />
                                        </Dialog>
                                        {this.state.speeding === undefined || this.state.speeding === null ? (
                                            <div className="col-12 lg:col-3">
                                                <Card className="card customCard">
                                                    <div className="greenDriving">
                                                        <h4>0</h4>
                                                        <p>Speeding</p>
                                                    </div>
                                                </Card>
                                            </div>
                                        ) : (
                                            <div className="col-12 lg:col-3">
                                                <Card className="card customCard">
                                                    <div className="greenDriving" onClick={() => this.onClick("displayBasic3")}>
                                                        <h4>{this.state.speeding.length}</h4>
                                                        <p>Speeding</p>
                                                    </div>
                                                </Card>
                                            </div>
                                        )}
                                        <Dialog header="Speeding" visible={this.state.displayBasic3} style={{ width: "50vw" }} onHide={() => this.onHide("displayBasic3")}>
                                            <Speeding value={this.state.speeding} />
                                        </Dialog>
                                    </div>
                                </AccordionTab> */}
                                {this.props.fuel ? (
                                    <AccordionTab id="fuel" header="Fuelling/Defuelling Events" className="timeline-demo">
                                        {this.state && this.state.fuellingEvents && this.state.fuellingEvents.length ? (
                                            <div className="generalCard col-12 grid">
                                                {this.state?.fuellingEvents.map((item) => {
                                                    return this.fuelingDefuelingEvents(item)
                                                })}
                                            </div>
                                        ) : this.state.fuellingMsg ? (
                                            <div className="col-12">
                                                <div className="generalCard flex flex-column align-items-center justify-content-center" style={{ height: "40vh" }}>
                                                    <span className="pi pi-info-circle" style={{ fontSize: "3rem" }}></span>
                                                    <h3>{this.state.fuellingMsg}</h3>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>{loader(80, 80, "#264653")}</div>
                                        )}
                                    </AccordionTab>
                                ) : null}
                            </Accordion>
                        </>
                    ) : (
                        <div className="grid">
                            <div className="col-12">
                                <Skeleton height="50px" className="mb-2"></Skeleton>
                            </div>
                            <div className="col-12 lg:col-4">
                                <Skeleton height="322px" className="mb-2"></Skeleton>
                            </div>
                            <div className="col-12 lg:col-8">
                                <Skeleton height="322px" className="mb-2"></Skeleton>
                            </div>
                            <div className="col-12">
                                <Skeleton height="50px" className="mb-2"></Skeleton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return { unit: state.changeUnit };
};
export default connect(mapStateToProps, { dispatchAction })(VehicleOBDLive);
