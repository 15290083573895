import React, { useState, useRef } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import { Button } from 'primereact/button';
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from "react";
import { Toast } from "primereact/toast";
import moment from "moment";
import { RadioButton } from 'primereact/radiobutton';
import configService from "../service/configService"

export default function App() {
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [takePicture, setTakePicture] = useState(false);
  const [latLong, setLatLong] = useState({})
  const [openSites, setOpenSites] = useState(null)
  const [selectedSite, setSelectedSite] = useState(null)
  const [imageTime, setimageTime] = useState(null);
  const [isLoading, setisLoading] = useState(true)
  const [description, setDescription] = useState("")
  const [disableBtn, setBtnDisabled] = useState(false);
  const [facingMode, setFacingMode] = useState("environment")
  const [errMsg, setErrMsg] = useState("")
  const op = useRef(null);
  const toast = useRef();
  const empId = useRef(null)
  const geoFenceRes = useRef(null)
  useEffect(() => {
    navigator.permissions
            .query({ name: "geolocation" })
            .then((permissionStatus) => {
              if(permissionStatus.state ==  "denied"){setErrMsg("Location Permission denied")}
              else{fetchOpenTickets()}
              permissionStatus.onchange = () => {
                if(permissionStatus.state === "denied"){
                  setErrMsg("Location Permission denied")
                }else{
                  setErrMsg("")
                  fetchOpenTickets()
                }
              };
            });
  }, [])
  
  useEffect(() => {
    setImage(null)
  }, [selectedSite])

  const fetchOpenTickets=()=> {
    configService.getEmpMetaData(localStorage.getItem("serviceId")).then((res) => {
      navigator.geolocation.getCurrentPosition((pos) => {
        pos = pos.coords
        setLatLong(() => ({ latitude: pos.latitude, longitude: pos.longitude }))
        if (res && res.responseData.length) {
          empId.current = res.responseData[0].empId
          axios.post(process.env.REACT_APP_API_PARSER_URL+"/getactualgeofences", { "serviceId": localStorage.getItem("serviceId"), "lat": pos.latitude, "lon": pos.longitude, "empId": empId.current }).then((res) => {
            if(res.data && res.data.result && res.data.openTickets){
              geoFenceRes.current = res.data.openTickets
              let ticket_Site = Object.entries(res.data.openTickets)
              ticket_Site.length === 1 && setSelectedSite({"TicketNo":ticket_Site[0][0]})
              let openSites = []
              ticket_Site.map((item)=>{
                let ticket = {"TicketNo": item[0]}
                openSites.push(ticket)
              })
              setOpenSites(openSites)
            }
            setisLoading(false)

          })
        }
      }, (err) => {
        console.log(err)
        setErrMsg("Location Permission denied")
      })
    }).catch((err)=>{
      setErrMsg("Error getting Employee Id.")
    })
  }

  const toggle = (event) => {
    op.current.toggle(event);
  };
  const handleTakePhoto = () => {
    setImage(camera.current.getScreenshot());
    setTakePicture(false)
    setimageTime(null)

  };
  const validateInput = () => {
    if (!image) return "Site Image not found"
    if (!latLong.latitude && !latLong.longitude) return "Location not found"
    if (!imageTime) return "Please select site status"
  }
  const handleUpload = () => {
    let validateRes = validateInput()
    setBtnDisabled(true)
    if (typeof (validateRes) === "string") {
      toast.current.show({ severity: 'error', summary: 'Error', detail: validateRes });
      setBtnDisabled(false)
      return
    }
    var arr = image.split(",");
    var mime = arr[0].match(/:(.*?);/)[1]; //arr[0].split(":")[1].split(";")[0];
    var byteString = atob(arr[1]);
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }
    let fileBlob = new Blob([byteArray], { type: mime });
    let file = new File([fileBlob], `${empId.current}.jpeg`, {
      type: mime
    });
    let ts = new Date().getTime()
    let formData = new FormData();
    formData.append("siteImage", file);
    formData.append("username", localStorage.getItem("email"))
    formData.append("serviceId", localStorage.getItem("serviceId"))
    formData.append("latitude", latLong.latitude)
    formData.append("longitude", latLong.longitude)
    formData.append("empId", empId.current)
    formData.append("ts", ts)
    formData.append("date", moment(ts).format("YYYY-MM-DD HH:mm:ss"))
    formData.append("imageTaken", imageTime)
    formData.append("ticketNo", selectedSite.TicketNo)
    formData.append("siteId", geoFenceRes.current[selectedSite.TicketNo])

    configService.addSiteImageInfo(formData).then(() => {
      setImage(null)
      setimageTime(null)
      setDescription(null)
      toast.current.show({ severity: 'success', summary: 'Success', detail: "Site Image upload success" });
      setBtnDisabled(false)
    })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDiscard = () => {
    setImage(null)
    setTakePicture(false)
    setDescription(null)
  }

  if (errMsg) return <div className="w-full" style={{ color: "red",marginTop:"40vh"}}><h5 className="text-center">{errMsg}</h5></div>
  if (!isLoading) {
    return (
      <>
        <div >
          <Toast ref={toast} />
          {!image && <div className="col-12 m-0 grid">
            <div className="col-12 lg:col-4">
              <div className="displayCard bg-white flex flex-column border-radius-12px">
                <div className="">
                  <h6 className="text-lg">Employee ID </h6>
                  <h5 className="text-2xl	mt-2 mb-0" style={{wordWrap:"break-word", color: "#0C6291"}} type="text">{empId.current}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 lg:col-4">
              <div className="displayCard bg-white flex flex-column border-radius-12px">
                <div className="">
                  <h6 className="text-lg">Ticket Number </h6>
                  {!takePicture && openSites && openSites.length >1 ? <Dropdown className="w-full" value={selectedSite} onChange={(e) => { setSelectedSite(e.value)}} options={openSites} disabled={takePicture} optionLabel="TicketNo" placeholder="Select" />
                    : <h5 className="text-2xl mt-2 mb-0" style={{wordWrap:"break-word", color: "#0C6291"}} type="text">{selectedSite ? selectedSite.TicketNo : "No Open Tickets Found"}</h5>}
                </div>
              </div>
            </div>
            <div style={{ display: takePicture ? "none" : "block" }} className="mt-2 col-12 lg:col-4">
              <Button className="border-radius-12px" style={{backgroundColor: "#0C6291"}} onClick={() => setTakePicture(!takePicture)} disabled={!selectedSite} label="Open Camera"></Button>
            </div>
          </div>}
        </div>
        <div className="grid col-12 m-0">
          {takePicture && !image ? (
            <div className="lg:col-6 lg:col-offset-3 lg:mt-2 col-12">
              <div className="container">
                <div className="camera-container">
                  <Webcam style={{width: "100%", height: "100%", objectFit: "cover"}}
                  aspectRatio = {4/3}
                  disablePictureInPicture={true} 
                  ref={camera}
                  mirrored={facingMode === "user" ? true : false}
                  videoConstraints={{facingMode: facingMode}} />
                  <img style={{left:"43%", bottom: "1rem"}} className="absolute cursor-pointer" height={60} width={60} src="./images/cameraCapture.svg" onClick={handleTakePhoto} />
                  <img style={{right:"10%", bottom: "1.5rem"}} className="absolute cursor-pointer" height={40} width={40} src="./images/flipcamera.svg" onClick={() => setFacingMode((prevMode) =>prevMode === "user" ? "environment" : "user")} />
                </div>
              </div>
            </div>
          ) : null}

          {image && <div className=" lg:col-8 lg:col-offset-2 col-12">
            <div className="card lg:flex border-radius-12px">
              <div className="lg:w-6">
                <img className="w-full h-full" onClick={toggle} src={image} />
              </div>
              <div className="lg:col-6 lg:pl-4 col-12">
                <div className="col-12 p-0 mt-3">
                  <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <tbody>
                      <tr>
                        <td className="td font-bold white-space-nowrap">Employee Id:</td>
                        <td className="td">{empId.current}</td>
                      </tr>
                      <tr>
                        <td className="td font-bold white-space-nowrap">Site Id:</td>
                        <td className="td">{geoFenceRes.current[selectedSite.TicketNo]}</td>
                      </tr>
                      <tr>
                        <td className="td font-bold white-space-nowrap">Ticket No:</td>
                        <td className="td" style={{wordBreak:"break-all"}}>{selectedSite.TicketNo}</td>
                      </tr>
                      <tr>
                        <td className="td font-bold">Latitude:</td>
                        <td className="td">{latLong.latitude}</td>
                      </tr>
                      <tr>
                        <td className="td font-bold">Longitude:</td>
                        <td className="td">{latLong.longitude}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="field col-12 m-0" style={{paddingLeft: "0.625rem"}}>
                  <div className="">
                    <h6 className="mb-2">Image Time</h6>
                    <div className="grid">
                    <div className="col-6 md:col-4">
                      <div className="field-radiobutton mb-0">
                        <RadioButton inputId="option1" name="option" value="before" checked={imageTime === "before"} onChange={(e) => setimageTime(e.value)} />
                        <label htmlFor="option1">Before</label>
                      </div>
                    </div>
                    <div className="col-6 md:col-4">
                      <div className="field-radiobutton mb-0">
                        <RadioButton inputId="option2"  name="option" value="after" checked={imageTime === "after"} onChange={(e) => setimageTime(e.value)} />
                        <label htmlFor="option2">After</label>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
                <div className="field col-12">
                  <h6>Image Description</h6>
                  <InputTextarea id="address" className="w-full border-radius-12px outline-none" style={{borderRadius: "1.25rem",border: "1px solid rgba(116, 120, 141, 0.20)",background: "#FAFAFA"}}  value={description} onChange={(e)=>setDescription(e.target.value)} rows="4" />
                </div>
                <div className="flex justify-content-between">
                  <Button className="mt-2"  style={{backgroundColor: "#0C6291"}} disabled={disableBtn} onClick={handleUpload} label="Upload" />
                  <Button className="p-button-outlined p-button-danger mt-2" onClick={handleDiscard} label="Discard" />
                </div>
              </div>
            </div>
          </div>}
        </div>
      </>
    );
  }
  else {
    return <div style={{ width: "100%", textAlign: "center", height: "80vh", verticalAlign: "center", position: "relative" }}><i className="pi pi-spin pi-spinner" style={{ fontSize: '4rem', verticalAlign: "middle",position:"absolute", top: "45%" , left: "45%" }}></i></div>

  }
}
