import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Dialog } from "primereact/dialog";
import moment from "moment/moment";
import { colorPalettes } from "../../../utilities/constant";
import { AssetService } from "../../../service/AssetService";
import { VehicleSrvice } from "../../../service/VehicleService";
import PieChart from "../../ChartsComponents/ItemCurrentStatus";

const vehicleService = new VehicleSrvice();

class GetwayWiseReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            state: null,
            selectedState: null,
            getwayWiseData: null,
            sensorData: null,
            getwayPie: null,
            tableData: null,
            selectedProduct1: null,
            sensors: null,
            sensoreWiseChartData: null,
            popupTable: null,
            displayBasic: false,
            sensorDataPopup: false,
            sensorTableData: null,
            customColors: [ "#81BE56", "#6877A4","#FFBA47",]
        };
        this.serviceData = JSON.parse(localStorage.getItem("service"));
        this.pieColors = [colorPalettes.color2, colorPalettes.color1, colorPalettes.color5, colorPalettes.color3];
        this.headerGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Gateway" className="bg-primary" />
                    <Column header="Site Name" className="bg-primary" />
                    <Column header="Status" className="bg-primary" />
                    <Column header="Last Data Received" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );
        this.popupTableheaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Thing Name" className="bg-primary" />
                    <Column header="Sensor" className="bg-primary" />
                    <Column header="Status" className="bg-primary" />
                    <Column header="Address" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );
        this.sensorTablHeader = (
            <ColumnGroup>
                <Row>
                    <Column header="Thing Name" className="bg-primary" />
                    {this.serviceData && this.serviceData.serviceBlock !== "fleet" && <Column header="Site ID" className="bg-primary" />}
                    <Column header="Sensor" className="bg-primary" />
                    <Column header="City" className="bg-primary" />
                    <Column header="State" className="bg-primary" />
                    <Column header="Address" className="bg-primary" />
                </Row>
            </ColumnGroup>
        );
        this.serviceID = localStorage.getItem("serviceId");
        this.assetService = new AssetService();
        this.onChartClick = this.onChartClick.bind(this);
        this.onSensorChartClick = this.onSensorChartClick.bind(this);
        this.OnTableClick = this.OnTableClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.getStatusField = this.getStatusField.bind(this);
        this.fleetMap = {};
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false,
        });
    }

    componentDidMount = () => {
        let allFuncs = [this.assetService.getServiceObserGetway(this.serviceID, this.serviceData.serviceBlock), this.assetService.getServiceObserSen(this.serviceID, this.serviceData.serviceBlock)];
        if (this.serviceData && this.serviceData.serviceBlock === "fleet") allFuncs.push(vehicleService.getVehicleNumber(this.serviceID));
        Promise.all(allFuncs).then((res) => {
            const getwayWiseData = res[0];
            const sensorWiseData = res[1];
            if (res.length > 2) {
                res[2].forEach((f) => {
                    this.fleetMap[f.fleetName] = f;
                });
            }
            let state = [],
                sensors = [];
            getwayWiseData.forEach((item) => {
                state.push(item._id.fsmstate);
            });
            let newSensorData = [];
            sensorWiseData.forEach((item) => {
                let tempArr = [];
                item.ids.forEach((elm) => {
                    let temp = elm.id.split(":");
                    let e = temp[2].toUpperCase();
                    if (e !== "TOTALRECORDS") {
                        let found = sensors.includes(e);
                        if (!found) sensors.push(e);
                    }

                    tempArr.push({ ...elm, serviceId: temp[0], thingName: temp[1], sensor: e });
                });
                let tempData = {
                    ...item,
                };
                delete tempData.ids;
                tempData.ids = tempArr;
                newSensorData.push(tempData);
            });

            this.setState({ getwayWiseData, state, selectedState: "OFFLINE", sensorData: newSensorData, sensors }, () => {
                this.initReport(this.state.getwayWiseData, this.state.selectedState);
            });
        });
    };


    initReport = (data, state) => {
        let tableData = [],
            displayData = [];

        if (state === "ONLINE") {
            let dgs = [];
            data.map((item, index) => {
                item.ids.forEach((elm) => {
                    let temp = elm.id.split(":");
                    let dgName;
                    if (this.serviceData.serviceTypeName === "Asset Monitoring") {
                        dgName = temp[1].replace(/^.{2}/g, "dg");
                    } else {
                        dgName = temp[1].replace(/^.{2}/g, "");
                    }
                    let date = moment(elm.ls * 1000).toDate();
                    let time = moment(date).fromNow();
                    let tempObj = {
                        thingName: temp[1],
                        siteName: elm.siteid,
                        status: item._id.fsmstate,
                        on: time,
                    };
                    let thingName = temp[1].slice(2);
                    if (this.fleetMap && this.fleetMap[thingName]) {
                        tempObj.vehicleNumber = this.fleetMap[thingName].vehicle.vehicleNumber.toUpperCase();
                        tempObj.vehMake = this.fleetMap[thingName].vehicle.make.toUpperCase();
                        tempObj.vehModel = this.fleetMap[thingName].vehicle.model.toUpperCase();
                    }
                    if (item._id.fsmstate === "ONLINE") {
                        if (!dgs.includes(dgName)) dgs.push(dgName);
                    }
                    tableData.push(tempObj);
                });
                displayData = tableData.filter((el) => el.status === state);
            });
            let dgSortData = this.state.sensorData.map((item) => {
                let ids = [];
                item.ids.forEach((elm) => {
                    if (dgs.includes(elm.thingName)) {
                        ids.push(elm);
                    }
                });
                delete item.ids;
                item.ids = ids;
                return item;
            });

            let sensorWiseData = this.state.sensors.map((type) => {
                let status = "";
                let dgs = [];
                let items = [];
                dgSortData.forEach((elm) => {
                    status = elm._id.fsmstate;
                    dgs = elm.ids.filter((el) => el.sensor === type);
                    items.push({ status: status, count: dgs.length, dgs: dgs });
                });
                return {
                    sensorType: type,
                    item: items,
                };
            });

            let sensoreWiseChartData = sensorWiseData.map((elm) => {
                let chartTitle = elm.sensorType + " Sensor Status";
                let chartData = [];
                elm.item.forEach((el) => {
                    chartData.push({
                        name: el.status,
                        y: el.count,
                        color: el.status === "OFFLINE" ? colorPalettes.color5 : el.status === "ONLINE" ? colorPalettes.color1 : el.status === "OUTOFRANGE" ? colorPalettes.color2 : colorPalettes.color3,
                        sensor: elm.sensorType,
                    });
                });
                let chartOption = this.getPieOption2(chartTitle, chartData);
                return chartOption;
            });

            let stateObj = { tableData: displayData };
            // if (this.serviceData && (!this.serviceData.serviceBlock || this.serviceData.serviceBlock !== "fleet")) stateObj.sensoreWiseChartData = sensoreWiseChartData;
            stateObj.sensoreWiseChartData = sensoreWiseChartData;
            this.setState(stateObj);
        } else {
            let getwayDataSet = data.map((item, index) => {
                item.ids.forEach((elm) => {
                    let temp = elm.id.split(":");
                    let date = moment(elm.ls * 1000).toDate();
                    let time = moment(date).fromNow();
                    let tempObj = {
                        thingName: temp[1],
                        siteName: elm.siteid,
                        status: item._id.fsmstate,
                        on: time === "53 years ago" ? "--" : time,
                    };
                    let thingName = temp[1].slice(2); // remove gw from gateway name to form thing name
                    if (this.fleetMap && this.fleetMap[thingName]) {
                        tempObj.vehicleNumber = this.fleetMap[thingName].vehicle.vehicleNumber.toUpperCase();
                        tempObj.vehMake = this.fleetMap[thingName].vehicle.make.toUpperCase();
                        tempObj.vehModel = this.fleetMap[thingName].vehicle.model.toUpperCase();
                    }
                    tableData.push(tempObj);
                });
                displayData = tableData.filter((el) => el.status === state);
                if (state === "OFFLINE" && !(displayData && displayData.length)) displayData = tableData.filter((el) => el.status === "ONLINE");
                return {
                    name: item._id.fsmstate,
                    y: item.count,
                    color: item._id.fsmstate === "OFFLINE" ? colorPalettes.color5 : item._id.fsmstate === "ONLINE" ? colorPalettes.color1 : colorPalettes.color3,
                    sliced: item._id.fsmstate === "OFFLINE" ? true : false,
                    selected: item._id.fsmstate === "OFFLINE" ? true : false,
                };
            });
            let getwayPie;
            if (getwayDataSet && getwayDataSet.length) getwayPie = this.getPieOption("Gateway Status", getwayDataSet);
            this.setState({ getwayPie, tableData: displayData, sensoreWiseChartData: null });
        }
    };

    onChartClick = (state) => {
        this.setState({ selectedState: state.point.name }, () => {
            this.initReport(this.state.getwayWiseData, this.state.selectedState);
        });
    };

    getPieOption = (title, data) => {

        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            colors: [ ""," ", " #6877A4", ],
            title: {
                text:"",
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                    size: '80%',
                    innerSize: '50%',
                },
                series: {
                    point: {
                        events: {
                            click: this.onChartClick.bind(this),
                        },
                    },
                },
            },
            series: [
                {
                    name: "Total",
                    colorByPoint: true,
                    data:  data.map((point, index) => ({
                        ...point,
                        color: this.state.customColors[ index], // Assign a color from the customColors array
                    })),
                },
            ],
        };
    };
    getPieOption2 = (title, data) => {
        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: title,
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                    size: '80%',
                    innerSize: '50%',
                },
                series: {
                    point: {
                        events: {
                            click: this.onSensorChartClick.bind(this),
                        },
                    },
                },
            },
            series: [
                {
                    name: "Total",
                    colorByPoint: true,
                    data:data.map((point, index) => ({
                        ...point,
                        color: this.state.customColors[this.state.customColors.length % index], // Assign a color from the customColors array
                    })),
                },
            ],
        };
    };

    onSensorChartClick = (type) => {
        let sensor = type.point.options.sensor;
        let status = type.point.options.name;

        let tempArr = this.state.sensorData.filter((item) => item._id.fsmstate === status);
        let sensorTableData = tempArr[0].ids.filter((item) => item.sensor === sensor);

        this.setState({ sensorDataPopup: true, sensorTableData });
    };

    OnTableClick = (event) => {
        let dg = event.value.thingName.replace(/^.{2}/g, "dg");
        let popupTable = [];
        this.state.sensorData.forEach((item) => {
            let status = item._id.fsmstate;
            let filterData = item.ids.filter((elm) => elm.thingName === dg);
            if (filterData.length > 0) {
                filterData.forEach((el) => {
                    let tempObj = {
                        thingName: el.thingName,
                        status: status,
                        sensor: el.sensor,
                        state: el.state,
                        city: el.city,
                        address: el.address,
                    };
                    popupTable.push(tempObj);
                });
            }
        });
        this.setState({ popupTable, displayBasic: true });
    };

    getStatusField = (item) => {
        if (item.status === "ONLINE") {
            return <span style={{ backgroundColor: colorPalettes.color1, width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.status}</span>;
        } else if (item.status === "OFFLINE") {
            return <span style={{ backgroundColor: colorPalettes.color5, width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.status}</span>;
        } else if (item.status === "OUTOFRANGE") {
            return <span style={{ backgroundColor: colorPalettes.color2, width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.status}</span>;
        } else {
            return <span style={{ backgroundColor: colorPalettes.color3, width: "100%", color: "#fff", display: "inline-block", lineHeight: "3" }}>{item.status}</span>;
        }
    };

    render = () => {
        return (
            <div className="grid">
                <Dialog header="Sensor Status" visible={this.state.displayBasic} breakpoints={{ "960px": "95vw" }} style={{ width: "70vw" }} onHide={() => this.onHide("displayBasic")}>
                    <DataTable value={this.state.popupTable} headerColumnGroup={this.popupTableheaderGroup} responsiveLayout="scroll">
                        <Column field="thingName" className="font-semibold"></Column>
                        <Column field="sensor" className="white-space-nowrap"></Column>
                        <Column field="status" body={this.getStatusField} className="white-space-nowrap p-0 text-center"></Column>
                        <Column field="address"></Column>
                    </DataTable>
                </Dialog>
                <Dialog header={this.serviceData && this.serviceData.serviceBlock === "fleet" ? 6 : 10 ? `Vehicle List` : `DGs List`} visible={this.state.sensorDataPopup} breakpoints={{ "960px": "95vw" }} style={{ width: "70vw" }} onHide={() => this.onHide("sensorDataPopup")}>
                    <DataTable value={this.state.sensorTableData} headerColumnGroup={this.sensorTablHeader} paginator responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10}>
                        <Column field="thingName" className="font-semibold"></Column>
                        {this.serviceData && this.serviceData.serviceBlock !== "fleet" && <Column field="siteid" className="white-space-nowrap"></Column>}

                        <Column field="sensor" className="white-space-nowrap"></Column>
                        <Column field="city" className="white-space-nowrap"></Column>
                        <Column field="state" className="white-space-nowrap"></Column>
                        <Column field="address"></Column>
                    </DataTable>
                </Dialog>
                <div className="col-12 lg:col-6">
                    {this.state.getwayPie ? (
                        <div className="generalCard card">
                            <div className='col-12 flex justify-content-between align-items-center m-0 p-0'>
                                <p className='chartHeading mb-1' style={{ fontSize: '16px', lineHeight: '1.5' }}>
                                    Gateway Status
                                </p>
                            </div>
                            <HighchartsReact highcharts={Highcharts} options={this.state.getwayPie} />
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-6">
                    {this.state.tableData && this.state.tableData.length && this.state.sensoreWiseChartData ? (
                        <div className="card">
                            <DataTable value={this.state.tableData} paginator responsiveLayout="scroll" selectionMode="single" rows={this.serviceData.serviceBlock === "fleet" ? 6 : 10} onSelectionChange={this.OnTableClick} dataKey="id">
                                <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" header="Gateway" field="thingName"></Column>
                                {this.serviceData && this.serviceData.serviceBlock === "fleet" ? (
                                    <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" header="Vehicle Number" field="vehicleNumber"></Column>
                                ) : (
                                    <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" header="Site Name" field="siteName"></Column>
                                )}
                                {this.serviceData && this.serviceData.serviceBlock === "fleet" ? <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" header="Vehicle Make" field="vehMake"></Column> : null}
                                {this.serviceData && this.serviceData.serviceBlock === "fleet" ? <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" header="Vehicle Model" field="vehModel"></Column> : null}
                                <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" header="Status" field="status"></Column>
                                <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" header="Last Data Received" field="on"></Column>
                            </DataTable>
                        </div>
                    ) : this.state.tableData && this.state.tableData.length ? (
                        <div className="card generalCard">
                            <div className='col-12 flex justify-content-between align-items-center m-0 p-0'>
                                <p className='chartHeading mb-1' style={{ fontSize: '16px', lineHeight: '1.5' }}>
                                    Gateway Details
                                </p>
                            </div>
                            <DataTable value={this.state.tableData} paginator responsiveLayout="scroll" selectionMode="single" rows={this.serviceData.serviceBlock === "fleet" ? 6 : 10} dataKey="id">
                                <Column headerClassName="dtHeaderStyle firstHead" bodyClassName="" header="Gateway" field="thingName"></Column>
                                {this.serviceData && this.serviceData.serviceBlock === "fleet" ? (
                                    <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" header="Vehicle Number" field="vehicleNumber"></Column>
                                ) : (
                                    <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" header="Site Name" field="siteName"></Column>
                                )}
                                {this.serviceData && this.serviceData.serviceBlock === "fleet" ? <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" header="Vehicle Make" field="vehMake"></Column> : null}
                                {this.serviceData && this.serviceData.serviceBlock === "fleet" ? <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" header="Vehicle Model" field="vehModel"></Column> : null}
                                <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" header="Status" field="status"></Column>
                                <Column headerClassName="dtHeaderStyle lastHead" bodyClassName="bodyStyle" header="Last Data Received" field="on"></Column>
                            </DataTable>
                        </div>
                    ) : null}
                </div>
                {this.state.sensoreWiseChartData && this.state.sensoreWiseChartData.length ? (
                    <div className="col-12 lg:col-12">
                        <div className="grid">
                            {this.state.sensoreWiseChartData.map((item, index) => (
                                <div className="col-12 lg:col-6" key={"sensor-chart-" + index}>
                                    <div className="card">
                                        <HighchartsReact highcharts={Highcharts} options={item} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };
}

export default GetwayWiseReport;
