import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { poolData } from '../lib/constants';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ShowLogo } from './newVehTrackingWithFuel/Components';
import { style } from './newVehTrackingWithFuel/Components';
const userPool = new CognitoUserPool(poolData)

const ChangePassword = () => {
    const [email, setEmail] = useState(null);
    const [errMsg, setErrMsg] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const [oldPasswordType, setOldPasswordType] = useState("password");
    const [newPasswordType, setNewPasswordType] = useState("password");
    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);

    const history = useHistory();
    const toast = useRef();

    const validateInputs = () => {
        if (!email) return "Please enter the email Id";
        if (!oldPassword) return "Please enter the old password";
        if (!newPassword) return "Please enter the new password";
    }

    const onChangePassword = async (e) => {
        e.preventDefault();
        let validationRes = validateInputs();

        if (typeof validationRes === "string") {
            setErrMsg(validationRes);
        }
        else {
            setDisableBtn(true);
            let authenticationDetails = new AuthenticationDetails({
                Username: email,
                Password: oldPassword,
            });

            let userData = {
                Username: email,
                Pool: userPool
            };

            let cognitoUser = new CognitoUser(userData);
            try {
                await new Promise((resolve, reject) => {
                    cognitoUser.authenticateUser(authenticationDetails, {
                        onSuccess: function (result) {
                            cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
                                if (err) {
                                    toast.current.show({ severity: 'error', content: "Error while updating the password"})
                                    reject(err.message);
                                    setDisableBtn(false);
                                } else {
                                    toast.current.show({ severity: 'success', content: "Password Updated Successfully"})
                                    logout()
                                    resolve(result);
                                }
                            });
                        },
                        onFailure: function (err) {
                            console.log(err)
                            setDisableBtn(false);
                            reject(err);
                        },
                        newPasswordRequired: (userAttributes, requiredAttributes) => {
                            // User was signed up by an admin and must provide new
                            // password and required attributes, if any, to complete
                            // authentication.
                            
                            // the api doesn't accept this field back
                            delete userAttributes.phone_number_verified;
                            delete userAttributes.phone_number
                            // unsure about this field, but I don't send this back
                            //userAttributes.name = email
                            delete userAttributes.email_verified
                            delete userAttributes.email
                            // Get these details and call
                            cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
                                onSuccess: (user) => {
                                    toast.current.show({ severity: 'success', content: "Password Change Successful"})
                                    resolve(user);
                                    setTimeout(() => {
                                        history.push("/login");
                                    }, 2000);
                                },
                                onFailure: (error) => {
                                    toast.current.show({ severity: 'error', content: "Password not changed"})
                                    reject(error);
                                    setDisableBtn(false);
                                }
                            });
                        }
                    });
                });
            } catch (err) {
                setErrMsg(err.message);
                setDisableBtn(false);
            }
        }
    }

    const toggleOldPassword = (e) => {
        if (oldPasswordType === "password") {
            setOldPasswordType("text")
            return;
        }
        setOldPasswordType("password")
    }

    const toggleNewPassword = (e) => {
        if (newPasswordType === "password") {
            setNewPasswordType("text")
            return;
        }
        setNewPasswordType("password")
    }

    const logout = () => {
        localStorage.clear();
        setTimeout(() => {
            history.push("/login");
        }, 2000);
    }

    return (
        <div className="pages-body flex flex-column login-img">
            <Toast ref={toast} />
            <div className=" align-self-center  mt-auto mb-auto lg:col-3 col-12">
                <div className=" card flex flex-column col-12 align-items-center p-2" style={{ color: "#616161" }}>
                    <ShowLogo name={"Change Password"}/>
                    <form className='mt-2 col-12'>
                        <div className="mb-3 px-3 p-float-label">
                            <InputText className='col-12' type="text" style={{ padding: "0.75rem 0.75rem",...style}} id="inputId1" onChange={(e) => setEmail(e.target.value)} />
                            <label className='ml-3'>Email Id</label>
                        </div>
                        <div className="p-inputgroup mb-3 mt-3 px-3">
                            <span className="p-float-label">
                                <InputText style={{ ...style,borderTopRightRadius: 0, borderBottomRightRadius: 0,borderRight:"0px" }} type={oldPasswordType} id="oldpassword" onChange={(e) => setOldPassword(e.target.value)} />
                                <label>Old Password</label>
                                <div className="p-inputgroup-addon" onClick={toggleOldPassword} style={{ borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem", border: "1px solid rgba(12, 98, 145, 0.60)", borderLeft: "0px" }}>
                                    {oldPasswordType === "password" ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
                                </div>
                            </span>
                        </div>
                        <div className="p-inputgroup mb-3 px-3">
                            <span className="p-float-label">
                                <InputText style={{ ...style,borderTopRightRadius: 0, borderBottomRightRadius: 0,borderRight:"0px" }} type={newPasswordType} id="newpassword" onChange={(e) => setNewPassword(e.target.value)} />
                                <label>New Password</label>
                                <div className="p-inputgroup-addon" onClick={toggleNewPassword} style={{ borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem", border: "1px solid rgba(12, 98, 145, 0.60)", borderLeft: "0px" }}>
                                    {newPasswordType === "password" ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
                                </div>
                            </span>
                        </div>
                        <div className="mb-3 px-3" style={{ color: "red" }}>{errMsg}</div>
                        <div className="mb-3 px-3">
                            <Button  style={{ borderRadius: "12px", backgroundColor: `${email && oldPassword && newPassword ? "#0C6291" : "#6DA1BD"}`,padding:"12px" }}className="login-button mb-3 px-3 col-12" label="Change Password" disabled={disableBtn} onClick={onChangePassword}></Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;
