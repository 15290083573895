import React, { useEffect, useState } from "react";
import { plazaServices } from "./plazaReportService";
import moment from "moment";
import * as XLSX from "xlsx";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
const TollRevenueReport = React.memo(({ type = "Revenue", count = "revenue", collection = "nq-traffic-revenue-mtd", heading, plazaCode,selectedPlaza,subHeader }) => {
    const [masterData, setMasterData] = useState({})
    const [plazaDetails, setPlazaDetails] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date(moment().subtract(1, "days")))
    const [totals, setTotals] = useState()
    const [vrnExtra, setVrnExtra] = useState([])
    const [remainTotals, setRemainTotal] = useState([])
    const serviceId = localStorage.getItem("serviceId")
    const handleDateChange = async (e) => {
        setSelectedDate(e.target.value)
    };
    let keys = ["yesterdayRevenue", "mtdRevenue", "ytdRevenue", "preyesterdayRevenue", "premtdRevenue", "preytdRevenue"]
    const rowOrders = {
        0: "zone",
        1: "plaza",
        2: "yesterdayRevenue",
        3: "mtdRevenue",
        4: "ytdRevenue",
        5: "preyesterdayRevenue",
        6: "varYesterday",
        7: "premtdRevenue",
        8: "varMtd",
        9: "preytdRevenue",
        10: "varYtd",
    }
    if (type !== "Traffic") rowOrders[11] = "fastTag"
    function calculateTotalsAndVariations(data) {
        let totals = {
            yesterdayRevenue: 0,
            mtdRevenue: 0,
            ytdRevenue: 0,
            preyesterdayRevenue: 0,
            premtdRevenue: 0,
            preytdRevenue: 0,
            fastTag: 0
        };

        let variations = {
            varYesterday: 0,
            varMtd: 0,
            varYtd: 0
        };

        let countVarMtd = 0;
        let countVarYtd = 0;

        for (const key in data) {
            const plaza = data[key];
            totals.yesterdayRevenue += plaza.yesterdayRevenue || 0;
            totals.mtdRevenue += plaza.mtdRevenue || 0;
            totals.ytdRevenue += plaza.ytdRevenue || 0;
            totals.preyesterdayRevenue += plaza.preyesterdayRevenue || 0;
            totals.premtdRevenue += plaza.premtdRevenue || 0;
            totals.preytdRevenue += plaza.preytdRevenue || 0;
            if (plaza.varYesterday != null) {
                variations.varYesterday += plaza.varYesterday;
            }
            if (plaza.varMtd != null) {
                variations.varMtd += plaza.varMtd;
                countVarMtd++;
            }
            if (plaza.varYtd != null) {
                variations.varYtd += plaza.varYtd;
                countVarYtd++;
            }
        }
        variations.varYesterday /= Object.keys(data).length;
        variations.varMtd = countVarMtd ? variations.varMtd / countVarMtd : null;
        variations.varYtd = countVarYtd ? variations.varYtd / countVarYtd : null;

        return { Totals: totals, vari: variations };
    }

    function getStartOfFinancialYear() {
        const today = moment(selectedDate);
        const currentYear = today.year();
        const startOfFinancialYear = today.isBefore(moment(`${currentYear}-04-01`, 'YYYY-MM-DD'))
            ? moment(`${currentYear - 1}-04-01`, 'YYYY-MM-DD')
            : moment(`${currentYear}-04-01`, 'YYYY-MM-DD');

        return startOfFinancialYear.format('YYYY-MM-DD');
    }

    const getData = async ( plazaCodes,SelectedDate) => {
        if (type !== "Traffic") rowOrders[11] = "fastTag"
        setTotals([])
        setVrnExtra([])
        setRemainTotal([])
        const getPreviousYearDates = (start, end, yearsAgo) => {
            return {
                startDate: moment(start).subtract(yearsAgo, 'years').format('YYYY-MM-DD'),
                endDate: moment(end).subtract(yearsAgo, 'years').format('YYYY-MM-DD'),
            };
        };
        const makePayload = (startDate, endDate, yearsAgo = 0, dataType) => {
            const { startDate: adjustedStartDate, endDate: adjustedEndDate } = getPreviousYearDates(startDate, endDate, yearsAgo);
            const yesTerdayMap = {
                "Traffic": "count",
                "Revenue": "totalAmount"
            }
            let group = {};
            let project = {};
            if (dataType === "yesterday") {
                let project1 = yearsAgo ? "preyesterdayRevenue" : "yesterdayRevenue";
                group = {
                    [project1]: { $sum: `$${yesTerdayMap[type]}` },
                }
                project = {
                    [project1]: 1,
                }
            }
            else if (dataType === "month") {
                let project1 = yearsAgo ? "premtdRevenue" : "mtdRevenue";
                group = {
                    [project1]: { $first: `$${count}` }
                }
                project = {
                    [project1]: 1,
                }
            }
            else {
                let project1 = yearsAgo ? "preytdRevenue" : "ytdRevenue";
                group = {
                    [project1]: { $sum: `$${count}` },
                }
                project = {
                    [project1]: 1
                }
            }

            let payload = {
                operation: "aggregate",
                aggregate: [
                    {
                        $group: {
                            _id: "$plazacode",
                            ...group
                        },
                    },
                    {
                        $project: {
                            ...project,
                            _id: 1,
                        },
                    },
                    {
                        $sort: {
                            "plazaCode": -1,
                        }
                    }
                ],
            };
            let matchStage = {
                $match: {
                    date: {
                        $gte: adjustedStartDate,
                        $lte: adjustedEndDate,
                    },
                    plazacode: selectedPlaza ?plazaCodes:{ $in: plazaCodes }
                },
            };
            if (dataType === "year") {
                matchStage.$match["last_day_of_mon"] = true;
            }
            payload.aggregate = [matchStage, ...payload.aggregate];
            return payload;

        }
        const yesterdayRevenue = makePayload(moment(SelectedDate).format("YYYY-MM-DD"), moment(SelectedDate).format("YYYY-MM-DD"), 0, "yesterday")
        const mtdRevenue = makePayload(moment(SelectedDate).format("YYYY-MM-DD"), moment(SelectedDate).format("YYYY-MM-DD"), 0, "month")
        const ytdRevenue = makePayload(getStartOfFinancialYear(), moment(SelectedDate).format("YYYY-MM-DD"), 0, "year")
        const preyesterdayRevenue = makePayload(moment(SelectedDate).format("YYYY-MM-DD"), moment(SelectedDate).format("YYYY-MM-DD"), 1, "yesterday")
        const premtdRevenue = makePayload(moment(SelectedDate).format("YYYY-MM-DD"), moment(SelectedDate).format("YYYY-MM-DD"), 1, "month")
        const preytdRevenue = makePayload(getStartOfFinancialYear(), moment(SelectedDate).format("YYYY-MM-DD"), 1, "year")
        const fastTag = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        plazacode: selectedPlaza ?plazaCodes:{ $in: plazaCodes },
                        date: {
                            "$gte": moment(SelectedDate).format("YYYY-MM-DD"),
                            "$lte": moment(SelectedDate).format("YYYY-MM-DD"),
                        },

                    },
                },
                {
                    $group: {
                        _id: {
                            plaza: "$plazacode",
                            traffic: "$trafficType"
                        },
                        "traffic": { $sum: `$count` },

                    },
                },
                {
                    $project: {
                        "traffic": 1,
                        _id: 1,
                    },
                },
                {
                    $sort: {
                        "plazaCode": -1,
                    }
                }
            ],
        }
        let fastTagTraffic = {}
        if (type !== "Traffic") {
            let res = await plazaServices.general(serviceId, fastTag, "nq-total_traffic_daily")
            res.map((item) => {
                fastTagTraffic[item._id.plaza] = {
                    ...fastTagTraffic[item._id.plaza],
                    [item._id?.traffic]: item.traffic,
                }
                if (!fastTagTraffic[item._id.plaza]["total"]) fastTagTraffic[item._id.plaza]["total"] = item.traffic
                else fastTagTraffic[item._id.plaza]["total"] += item.traffic
            })
        }
        const getPlazaDetailsPayload = {
            filter: {PlazaCode:Array.isArray(plazaCodes)?{ $in: plazaCodes }:plazaCodes},
            projection: {
                "Zone": 1,
                "SPV": 1,
                "PlazaCode": 1,
                "PlazaName": 1,
            }
        }
        const collectionMap = {
            "Traffic": "nq-total_traffic_daily",
            "Revenue": "nq-totalRevenue_by_plaza_date_daily"
        }
        let promiseArray = [yesterdayRevenue, mtdRevenue, ytdRevenue, preyesterdayRevenue, premtdRevenue, preytdRevenue].map((item, index) => {
            if (index === 0 || index === 3) return plazaServices.general(serviceId, item, collectionMap[type])
            return plazaServices.general(serviceId, item, collection)
        })
        promiseArray.push(plazaServices.general(serviceId, getPlazaDetailsPayload, "nq-plaza-master"))

        Promise.all(promiseArray).then((res) => {
            let tempMaster = {};
            let spvPlazaMap = {};
            let spvZonePlazaMap = {};
            res[res.length - 1].map((item) => {
                spvPlazaMap[item.PlazaCode] = item.SPV;
                spvZonePlazaMap[item.PlazaCode] = item.Zone
            })
            for (let i = 0; i < res.length - 1; i++) {
                for (let j = 0; j < res[i].length; j++) {
                    if (tempMaster[res[i][j]._id]) tempMaster[res[i][j]._id][keys[i]] = type !== "Traffic" ? res[i][j][keys[i]] * 0.00001 : res[i][j][keys[i]] * 0.001
                    else tempMaster[res[i][j]._id] = { [keys[i]]: type !== "Traffic" ? res[i][j][keys[i]] * 0.00001 : res[i][j][keys[i]] * 0.001 }
                    if (type !== "Traffic") {
                        for (const key in fastTagTraffic[res[i][j]._id]) {
                            tempMaster[res[i][j]._id][`traffic-${key}`] = fastTagTraffic[res[i][j]._id][key]
                        }
                    }
                }
            }
            let spvMaster = {}
            let master = []
            for (const key in tempMaster) {
                if (!spvMaster[spvPlazaMap[key]]) {
                    spvMaster[spvPlazaMap[key]] = {
                        ...tempMaster[key],
                        "zone": spvZonePlazaMap[key]
                    }
                }
                else {
                    for (const item in tempMaster[key]) {
                        if (item !== "plaza") spvMaster[spvPlazaMap[key]][item] += tempMaster[key][item]
                    }

                }
            }

            for (const key in spvMaster) {
                const plaza = spvMaster[key];
                if (!spvMaster[key]["yesterdayRevenue"]) spvMaster[key]["yesterdayRevenue"] = 0;
                if (plaza.preyesterdayRevenue && plaza.preyesterdayRevenue !== 0) {
                    plaza["varYesterday"] = ((plaza["yesterdayRevenue"] - plaza["preyesterdayRevenue"]) / plaza["preyesterdayRevenue"]) * 100;
                } else {
                    plaza["varYesterday"] = null;
                }

                if (plaza.premtdRevenue && plaza.premtdRevenue !== 0) {
                    plaza["varMtd"] = ((plaza["mtdRevenue"] - plaza["premtdRevenue"]) / plaza["premtdRevenue"]) * 100;
                } else {
                    plaza["varMtd"] = null;
                }
                if (plaza.preytdRevenue && plaza.preytdRevenue !== 0) {
                    plaza["varYtd"] = ((plaza["ytdRevenue"] - plaza["preytdRevenue"]) / plaza["preytdRevenue"]) * 100;
                } else {
                    plaza["varYtd"] = null;
                }
                spvMaster[key]["plaza"] = key;
                if (type !== "Traffic") {
                    spvMaster[key]["fastTag"] =
                        spvMaster[key] &&
                            spvMaster[key]["traffic-total"] &&
                            spvMaster[key]["traffic-cch"]
                            ? (spvMaster[key]["traffic-cch"] / spvMaster[key]["traffic-total"]) * 100
                            : 0;
                }
                let data = []
                for (const counter in rowOrders) {
                    if (type === "traffic") {
                        if (rowOrders[counter] !== "fastTag") data.push(spvMaster[key][rowOrders[counter]] || "")
                    }
                    else data.push(spvMaster[key][rowOrders[counter]] || "")


                }
                master.push(data)
            }
            const { Totals, vari } = calculateTotalsAndVariations(spvMaster)
            let arr = [Totals.yesterdayRevenue, Totals.mtdRevenue, Totals.ytdRevenue, Totals.preytdRevenue, vari.varYesterday, Totals.premtdRevenue, vari.varMtd, Totals.preytdRevenue, vari.varYtd]
            if (type !== "Traffic") {
                let res = 0;
                let tfastTag = 0;
                for (const key in spvMaster) {
                    tfastTag += spvMaster[key]["fastTag"];
                    res++;
                }
                tfastTag = tfastTag / res;
                arr.push(tfastTag)
            }
            if (type !== "Traffic") {
                let countType = "additional_vrn_revenue"
                const makeVrnPayload = (type, project, match, startDate, endDate, yearsAgo = 0) => {
                    const { startDate: adjustedStartDate, endDate: adjustedEndDate } = getPreviousYearDates(startDate, endDate, yearsAgo);
                    return {
                        operation: "aggregate",
                        aggregate: [
                            {
                                $match: {
                                    plazacode: selectedPlaza ?plazaCodes:{ $in: plazaCodes },
                                    date: {
                                        "$gte": adjustedStartDate,
                                        "$lte": adjustedEndDate,
                                    },
                                },
                            },
                            {
                                $group: {
                                    _id: null,
                                    [project]: { $sum: `$${type}` },
                                },
                            },
                            {
                                $project: {
                                    [project]: 1,
                                    _id: 1,
                                },
                            },
                        ],
                    };
                }
                const extraYesterdayRevenue = makeVrnPayload(countType, "revenue", {}, moment(SelectedDate).format("YYYY-MM-DD"), moment(SelectedDate).format("YYYY-MM-DD"))
                const extramtdRevenue = makeVrnPayload(countType, "revenue", {}, moment(SelectedDate).startOf("month").format('YYYY-MM-DD'), moment(SelectedDate).format("YYYY-MM-DD"))
                const extraytdRevenue = makeVrnPayload(countType, "revenue", {}, getStartOfFinancialYear(), moment(SelectedDate).format("YYYY-MM-DD"), moment(SelectedDate).format("YYYY-MM-DD"))
                const extrapreyesterdayRevenue = makeVrnPayload(countType, "revenue", {}, getStartOfFinancialYear(), moment(SelectedDate).format("YYYY-MM-DD"), 1)
                const extrapremtdRevenue = makeVrnPayload(countType, "revenue", {}, moment(SelectedDate).startOf("month").format('YYYY-MM-DD'), moment(SelectedDate).format("YYYY-MM-DD"), 1)
                const extrapreytdRevenue = makeVrnPayload(countType, "revenue", {}, getStartOfFinancialYear(), moment(SelectedDate).format("YYYY-MM-DD"), 1)
                let newPromiseArray = [extraYesterdayRevenue, extramtdRevenue, extraytdRevenue, extrapreyesterdayRevenue, extrapremtdRevenue, extrapreytdRevenue].map((item) => {
                    return plazaServices.general(serviceId, item, "add-non-fastag-revenue-plazawise")
                })
                Promise.all(newPromiseArray).then((res) => {
                    let vrnArray = [];
                    vrnArray = [
                        res[0][0]?.revenue ? res[0][0].revenue * 0.00001 : "",
                        res[1]?.[0]?.revenue ? res[1][0].revenue * 0.00001 : "",
                        res[2]?.[0]?.revenue ? res[2][0].revenue * 0.00001 : "",
                        res[3]?.[0]?.revenue ? res[3][0].revenue * 0.00001 : "",
                        "",
                        res[4]?.[0]?.revenue ? res[4][0].revenue * 0.00001 : "",
                        "",
                        res[5]?.[0]?.revenue ? res[5][0].revenue * 0.00001 : "",
                        "",
                        ""
                    ];
                    let remainTotal = [];
                    for (let i = 0; i < vrnArray.length; i++) {
                        if (vrnArray[i] !== "") {
                            remainTotal.push(Math.round(arr[i]) - Math.round(vrnArray[i]))
                        }
                        else remainTotal.push(arr[i])
                    }
                    setVrnExtra(vrnArray);
                    setRemainTotal(remainTotal)
                })
            }
            setTotals(arr);
            setMasterData(master)
            setPlazaDetails(res[res.length - 1])
        })

    }
    useEffect(() => {
        if(selectedPlaza)getData( selectedPlaza,selectedDate)
        else getData( plazaCode,selectedDate)
    }, [plazaCode,selectedPlaza,selectedDate])
    const rowStyle = { border: "1px solid black", padding: "10px" }
    const yearFinder = (prev) => {
        const currentYear = moment(selectedDate).format("YY");
        const currentMonth = moment(selectedDate).month() + 1;
        const currentFYEnd = currentMonth >= 4 ? currentYear + 1 : currentYear;
        const previousFYEnd = currentFYEnd - 1;
        if (prev) return previousFYEnd;
        return currentFYEnd

    }
    function downloadTableAsCSV() {
        if (!masterData || !Array.isArray(masterData)) {
            console.error("masterData is invalid or not an array:", masterData);
            return;
        }

        let headers = [
            ["Zone", "Project", `FY ${yearFinder()}`, "", "", `FY ${yearFinder(true)}`, "", "", "", "", ""],
            ["", "", "Today", "MTD", "YTD", "Daily", "Variation", "MTD", "Variation", "YTD", "Variation"],
            [
                "",
                "",
                moment(selectedDate).format("MMMM Do YY"),
                moment(selectedDate).format("MMMM"),
                `April-${moment(selectedDate).format("MMMM YY")}`,
                yearFinder(),
                "Variation",
                yearFinder(true),
                "Variation",
                `April-${yearFinder(true)}`, // Last year April
                "Variation",
            ]
        ];
        const roundOff = (arr) => {
            arr = arr.map((item) => {
                return typeof (item) !== "string" ? Math.round(item) : item
            })
            return arr;
        }
        let footerRows = [
            ["Total", "", ...roundOff(totals)],
        ];
        if (type !== "Traffic") {
            headers[1] = [...headers[1], "FastTag"]
            headers[0] = [...headers[0], moment(selectedDate).format("MMMM Do YY")]
            footerRows = [...footerRows, ["Additional Fee from Non FastTag", "", ...roundOff(vrnExtra)],
            ["Revenue Excluding Additional Fee", "", ...roundOff(remainTotals)]]
        }
        let data = masterData.map((item) => {
            return roundOff(item)
        })
        const rows = [...headers, ...data, ...footerRows];
        const ws = XLSX.utils.aoa_to_sheet(rows);
        const headerFooterStyle = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "006FC0" },
            },
            font: {
                bold: true,
                color: { rgb: "#06c219" },
            },
        };
        headers.forEach((_, index) => {
            for (let col = 0; col < 12; col++) {
                const cellAddress = String.fromCharCode(65 + col) + (index + 1);
                if (!ws[cellAddress]) ws[cellAddress] = {};
                ws[cellAddress].s = headerFooterStyle;
            }
        });

        const footerStart = headers.length + masterData.length + 1;
        for (let i = footerStart; i < footerStart + footerRows.length; i++) {
            for (let col = 0; col < 12; col++) {
                const cellAddress = String.fromCharCode(65 + col) + i;
                if (!ws[cellAddress]) ws[cellAddress] = {};
                ws[cellAddress].s = headerFooterStyle;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Table Data");
        XLSX.writeFile(wb, "table_data.xlsx");
    }
    return (
        <div style={{ overflowX: "auto" }} className="card generalCard">
            <div className=" col-12">
                <div className=' col-12   m-0' style={{ width: "100%" }}>
                    <div className='pt-2 bg-white  grid col-9 align-items-center' >
                        <h3 className="chartHeading">{heading} <span className="m-0 p-0 text-sm">({subHeader})</span></h3>
                    </div>
                    <div className="grid align-items-center p-2">
                        <label className=" relabel">Timeline:</label>
                        <Calendar
                            id="range"
                            value={selectedDate}
                            maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                            placeholder="Select Date"
                            onChange={handleDateChange}
                            showIcon
                            readOnlyInput
                            className="p-calendar flex align-items-center bg-white"
                            style={{
                                borderRadius: '17px',
                                border: '2px solid #0C6291',

                            }}
                            dateFormat="dd-mm-yy"
                        />
                        <Button style={{ backgroundColor: "white", color: "#2A9D8F", border: "1px solid #2A9D8F", fontStyle: "Inter" }} className="ml-3 lg:mt-0 mt-2" onClick={downloadTableAsCSV}>Download</Button>
                    </div>
                </div>
            </div>
            <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "center" }} className="m-0">
                <thead style={{ backgroundColor: "rgb(0,111,192)", color: "white" }}>
                    <tr style={{ fontWeight: "bold" }}>
                        <th rowSpan="3" style={rowStyle}>Zone</th>
                        <th rowSpan="3" style={rowStyle}>Project</th>
                        <th colSpan="3" style={rowStyle}>FY {yearFinder()}</th>
                        <th colSpan="6" style={rowStyle}>FY {yearFinder(true)}</th>
                        {type !== "Traffic" && <th colSpan="1" rowSpan="1" style={rowStyle}>{moment(selectedDate).format('MMMM Do YY')}</th>}
                    </tr>
                    <tr style={{ fontWeight: "bold" }}>
                        <th colSpan="1" style={rowStyle}>Today</th>
                        <th colSpan="1" style={rowStyle}>MTD</th>
                        <th colSpan="1" style={rowStyle}>YTD</th>
                        <th colSpan="2" style={rowStyle}>Daily</th>
                        <th colSpan="2" style={rowStyle}>MTD</th>
                        <th colSpan="2" style={rowStyle}>YTD</th>
                        {type !== "Traffic" && <th rowSpan="2" style={rowStyle}>FastTag</th>}
                    </tr>
                    <tr style={{ fontWeight: "bold" }}>
                        <th style={rowStyle}>{moment(selectedDate).format('MMMM Do YY')}</th>
                        <th style={rowStyle}>{moment(selectedDate).format("MMMM")}</th>
                        <th style={rowStyle}>April-{moment(selectedDate).format("MMMM YY")}</th>
                        <th style={rowStyle}>{moment(moment(selectedDate).format('MMMM DD YYYY')).subtract(1, 'years').format('MMMM Do YY')}</th>
                        <th style={rowStyle}>Variation(%)</th>
                        <th style={rowStyle}>{moment(moment(selectedDate).format('MMMM DD YYYY')).subtract(1, 'years').format('MMMM  YY')}{ }</th>
                        <th style={rowStyle}>Variation(%)</th>
                        <th style={rowStyle}>April-{moment(moment(selectedDate).format('MMMM DD YYYY')).subtract(1, 'years').format('MMMM  YY')}</th>
                        <th style={rowStyle}>Variation(%)</th>
                    </tr>

                </thead>
                <tbody>
                    {masterData && Array.isArray(masterData) && masterData.map((Item, index) => (
                        <tr>
                            {
                                Item.map((item) => {
                                    return <td style={{ ...rowStyle, textAlign: `${typeof (item) !== "string" ? "right" : "left"}`, fontWeight: `${typeof (item) !== "string" ? 500 : 800}`, fontStyle: "Inter" }}>{Number.isInteger(item) ? `${item}` : typeof (item) !== "string" ? `${Math.round(item)}` : item}</td>
                                })
                            }
                        </tr>
                    ))}
                    <tr style={{ backgroundColor: "rgb(0,111,192)", color: "white" }}>
                        <td colSpan={2} style={rowStyle}>Total</td>
                        {
                            totals && Array.isArray(totals) && totals.map((item, index) => {
                                return <td style={{ ...rowStyle, textAlign: "right" }} >{item !== null && item !== undefined ? Math.round(item) : ''}</td>
                            })
                        }
                    </tr>
                    {type !== "Traffic" && <tr style={{ backgroundColor: "rgb(0,111,192)", color: "white" }}>
                        <td colSpan={2} style={rowStyle}>Additional Fee from Non FastTag</td>
                        {
                            vrnExtra && Array.isArray(vrnExtra) && vrnExtra.map((item, index) => {
                                return <td style={{ ...rowStyle, textAlign: "right" }} >{item ? Math.round(item) : ''}</td>
                            })
                        }
                    </tr>}
                    {type !== "Traffic" && <tr style={{ backgroundColor: "rgb(0,111,192)", color: "white" }}>
                        <td colSpan={2} style={rowStyle}>Revenue Excluding Additional Fee</td>
                        {
                            remainTotals && Array.isArray(remainTotals) && remainTotals.map((item, index) => {
                                return <td style={{ ...rowStyle, textAlign: "right" }} >{item !== null && item !== undefined ? Math.round(item) : ''}</td>
                            })
                        }
                    </tr>}
                </tbody>
            </table>
        </div>
    );
});

export default TollRevenueReport;