import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const PieChart = ({ chartData, legend, radius ,height,pie,onClickFunction}) => {
    if (chartData && Array.isArray(chartData.data)) {
        const updatedData = chartData.data.map((item) => {
            return {
                category: item.name,
                name: item.name + " : " + item.y ,
                y: item.y,
                color: item.color
            }
        });
        chartData = {
            ...chartData,
            data: updatedData 
        };
    }

    let chartOptions = {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: 210
    }
    if (radius) chartOptions["height"] = height?? 180
    else chartOptions["height"] = ""

    const option = {
        chart: chartOptions,
        title: {
            text: "",
            align: "left",
            style: {
                fontSize: '15px',
                color: '#333333',
                fontWeight: 'bold',
                fontFamily: 'Arial, sans-serif',
            },
        },
        tooltip: {
            pointFormat: "{series.category}</b>",
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
                size: '80%',       
                innerSize: !pie?'50%':"",
            },
            series: {
                point: {
                    events: {
                        click: onClickFunction,
                    },
                },
            },
        },
        legend: {
            align: 'right', 
            verticalAlign: 'middle',
            layout: 'vertical',
            width: '40%',
            itemStyle: {
                fontSize: '12px',
                marginTop: "12px",
            },
            itemMarginTop: 12,
            symbolPadding: 10,
        },
        series: [
            {
                name: "Total",
                colorByPoint: true,
                data: chartData.data || [],
            },
        ],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 600 
                },
                chartOptions: {

                    dataLabels: {
                        enabled: true

                    }
                }
            }]
        }
    };

    option.series[0].data = chartData.data;
    option.title.text = "";
    if (!legend) delete option.legend
    return <HighchartsReact highcharts={Highcharts} options={option} />
};


export default PieChart;
