import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import moment from "moment";
import { mapKey } from "../../utilities/constant";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import { Tooltip } from "primereact/tooltip";
import { secondsToHm, secondsToHms } from "../../utilities/utillFunction";
import { convertValue } from "../../utilities/unitConversion";
import { VehicleSrvice } from "../../service/VehicleService";
import { AccordionTab,Accordion } from 'primereact/accordion';
import { Badge } from "primereact/badge";
import { classNames } from "primereact/utils";

const map_start_icon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#00A42F; stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>';

const map_end_icon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path style="fill:#01010100; stroke:none;" d="M0 0L0 64L64 64L64 0L0 0z"/><path style="fill:#F14003; stroke:none;" d="M31 60L33 60C38.9485 49.275 51.7089 35.8509 51.8164 23C51.9872 2.56881 22.1996 -3.31481 13.9699 15C7.39209 29.6386 23.7505 48.1572 31 60z"/><path style="fill:#f73a3a; stroke:none;" d="M21 6L22 7L21 6M42 6L43 7L42 6z"/><path style="fill:#FFFFFF; stroke:none;" d="M28.1088 16.0278C20.0658 20.5369 26.7819 33.892 34.956 29.8056C44.4446 25.0619 37.288 10.8817 28.1088 16.0278z"/><path style="fill:#ff0101; stroke:none;" d="M24 25L25 26L24 25M39 25L40 26L39 25M49 31L50 32L49 31M48 33L49 34L48 33M47 35L48 36L47 35z"/><path style="fill:#f73a3a; stroke:none;" d="M19 40L20 41L19 40z"/><path style="fill:#ff0101; stroke:none;" d="M43 42L44 43L43 42z"/><path style="fill:#e3641d; stroke:none;" d="M22 45L23 46L22 45z"/><path style="fill:#ff0101; stroke:none;" d="M38 50L39 51L38 50z"/><path style="fill:#e3641d; stroke:none;" d="M33 58L34 59L33 58z"/></svg>';

const street_icon = `<svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path style="fill:#00A42F; stroke:none;" d="M192 64a64 64 0 1 1 128 0A64 64 0 1 1 192 64zm32 96h64c35.3 0 64 28.7 64 64v80H318.2L304 432H208L193.8 304H160V224c0-35.3 28.7-64 64-64zM55.3 419.2c-3.6 2.4-3.6 7.2 0 9.6c8.8 5.7 23.1 11.8 43 17.3C137.7 457 193.5 464 256 464s118.3-7 157.7-17.9c19.9-5.5 34.2-11.6 43-17.3c3.6-2.4 3.6-7.2 0-9.6c-2.1-1.4-4.8-2.9-7.9-4.5c-15.3-7.7-38.8-14.9-69-20.5l8.6-47.2c32.4 5.9 60.9 14.2 82 24.8c10.5 5.3 20.3 11.7 27.8 19.6c7.6 8.1 14 19.1 14 32.6c0 21.4-15.5 36.1-29.1 45c-14.7 9.6-34.3 17.3-56.4 23.4C381.8 504.7 321.6 512 256 512s-125.8-7.3-170.4-19.6c-22.1-6.1-41.8-13.8-56.4-23.4C15.5 460.1 0 445.4 0 424c0-13.5 6.4-24.5 14-32.6c7.5-7.9 17.3-14.3 27.8-19.6c21-10.6 49.5-18.9 82-24.8l8.6 47.2c-30.2 5.5-53.7 12.8-69 20.5c-3.2 1.6-5.8 3.1-7.9 4.5z"/></svg>`

const EmployeeReport = ({ data, back, geoFence, NotRecived, reachedTickets=[],geoFenceData }) => {
    const [degStop, setdegStop] = useState(null);
    const [undegStop, setundegStop] = useState(null);
    const [timeLineData, setTimeLineData] = useState(null);
    const [isRoad, setIsRoad] = useState(true)
    const [mapState, setMap] = useState()
    const [groupState, setGroup] = useState()
    const [empData , setEmpData] = useState({})
    const [reachedMapData, setReachedMapData] = useState(null)
    const serviceDetails = JSON.parse(localStorage.getItem("service"));
    const vehicleService = new VehicleSrvice();
    const polylineRef = useRef(null)
    useEffect(() => {
        let degStop = data.stoppagePoints.filter((item) => item.type === "designated" || item.type === "Designated");
        let undegStop = data.stoppagePoints.filter((item) => item.type === "Undesignated" || item.type === "undesignated");
        const startPoint = {
            type: "Start Point",
            address: data.startLocation.address,
            time: data.startLocation.ts,
            duration: "",
            icon: "pi pi-map-marker",
            color: "#9C27B0",
        };
        const endPoint = {
            type: "End Point",
            address: data.endLocation.address,
            time: data.endLocation.ts,
            duration: "",
            icon: "pi pi-map-marker",
            color: "#9C27B0",
        };
        let wayPoints = [],
            allPoints = [];

        let geoMapArray = [
            {
                lat: data.startLocation.lat,
                lng: data.startLocation.lng,
                type: "waypoint",
                address: data.startLocation.address,
                time: data.startLocation.ts,
            },
            {
                lat: data.endLocation.lat,
                lng: data.endLocation.lng,
                type: "waypoint",
                address: data.endLocation.address,
                time: data.endLocation.ts,
            },
        ];

        let timeLineData = [{ ...startPoint }, { ...endPoint }];

        if (data.stoppagePoints && data.stoppagePoints.length) {
            data.stoppagePoints.forEach((item) => {
                timeLineData.push({
                    type: "Stoppage Point",
                    address: item.address,
                    time: item.start_time,
                    duration: secondsToHm(item.stoppageDuration),
                    icon: "pi pi-map-marker",
                    color: "#9C27B0",
                });
                geoMapArray.push({
                    lat: item.lat,
                    lng: item.lng,
                    type: item.type.toLowerCase(),
                    address: item.address,
                    time: item.start_time,
                });
            });
            allPoints = JSON.parse(JSON.stringify(data.stoppagePoints));
        }

        if (NotRecived.length > 0) {
            NotRecived.map((item) => {
                if (item !== undefined ) {
                    timeLineData.push(
                        {
                            type: "Data Not Recived",
                            timeRange: [item.start_ts, item.end_ts],
                            duration: (item.session_sec),
                            icon: "pi pi-map-marker",
                            reasons: item.reasons,
                            color: "#9C27B0",
                        }
                    )

                }
            })
        }

        if (data.wayPoints && data.wayPoints.length) {
            allPoints = allPoints.concat(...data.wayPoints);
        }

        if (allPoints.length) {
            allPoints = allPoints.sort((a, b) => {
                const date1 = new Date(a.ts || a.start_time);
                const date2 = new Date(b.ts || b.start_time);
                return date1 - date2;
            });
            for (let i = 0; i < allPoints.length; i++) {
                wayPoints.push(`via=${allPoints[i].lat},${allPoints[i].lng}`);
            }
        }

        let maparr = geoMapArray.sort((a, b) => {
            return new Date(a.time).getTime() - new Date(b.time).getTime();
        });
        timeLineData = timeLineData.sort((a, b) => {
            const date1 = new Date(a.time || a.timeRange[0]);
            const date2 = new Date(b.time || b.timeRange[0]);
            return date1 - date2;
        });

        setTimeLineData(timeLineData);
        setdegStop(degStop.length);
        setundegStop(undegStop.length);
        setEmpData({data, wayPoints, allPoints})
        getEmployeeMap(maparr, data, wayPoints, allPoints);
    }, []);

    const content = (item) => {
        return item ? (
            <div style={{ cursor: "pointer" }}>
                <Card className="mb-3">
                    <div style={{ fontSize: "1.3rem", fontWeight: "bold", padding: "0" }}>
                        {(item.state === "OutsideGF" ? "Exit " : "Entered ") + item.name}
                        {item.ts && (
                            <span style={{ paddingLeft: "2vh", fontSize: "1rem", color: "#00000099" }}>
                                {item.ts}
                            </span>
                        )}
                    </div>
                </Card>
            </div>
        ) : null;
    };

    // Timeline marker
    const marker = (item) => {
        return item ? (
            <span
                className="custom-marker shadow-2 p-2"
                style={{
                    backgroundColor: item.state === "OutsideGF" ? "#628c3f" : "#0c6291",
                }}
            >
                <i
                    className={`marker-icon fa-solid fa-right-${item.state === "OutsideGF" ? "from" : "to"}-bracket`}
                    style={{
                        transform: item.state === "OutsideGF" ? "rotate(180deg)" : "none",
                    }}
                ></i>
            </span>
        ) : null;
    };

    const getEmployeeMap = (data, resp, wayPoints, allPoints) => {
        if (!data || !data.length) {
            return;
        }
        const H = window.H;
        let scheduledIcon = new H.map.Icon("/images/Scheduled.svg", {
            size: { w: 15, h: 15 },
        });
        let unScheduledIcon = new H.map.Icon("/images/Unscheduled.svg", {
            size: { w: 15, h: 15 },
        });
        let startIcon = new H.map.Icon(map_start_icon, { size: { w: 32, h: 32 } });
        let endIcon = new H.map.Icon(map_end_icon, { size: { w: 32, h: 32 } });

        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(document.getElementById("mapContainer"), defaultLayers.vector.normal.map, {
            center: { lat: data[0].lat, lng: data[0].lng },
            zoom: 10,
            pixelRatio: window.devicePixelRatio || 1,
        });
        window.addEventListener("resize", () => map.getViewPort().resize());

        let ui = H.ui.UI.createDefault(map, defaultLayers);
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        let group = new H.map.Group();

        let markerLabel = 1;
        let markers = [];
        for (let i = 0; i < data.length; i++) {
            let mark;
            let contentString =
                '<div id="vehicle-content">' +
                '<div id="vehicle-info">' +
                `<div id=${data[i].type === "undesignated" ? "fc-red" : data[i].type === "waypoint" ? "waypoint" : "fc-green"} >` +
                `${i === 0 ? "Start" : i === data.length - 1 ? "End" : data[i].type}` +
                "</div>" +
                '<div id="info">' +
                `${i === 0 ? "Start Time" : i === data.length - 1 ? "End Time" : "Time"} : ` +
                `${data[i].time ? moment(data[i].time, "YYYY-MM-DD HH:mm:ss").format("h:mm A") : ""}` +
                "</div>" +
                `${!(i === 0 || i === data.length - 1) ? `<div id="info">Stop Point Number : ${markerLabel}</div>` : ""}` +
                "</div>" +
                "</div>";

            if (i === 0) {
                mark = getStrengthMarker(H, map, data[i], contentString, defaultLayers, { icon: startIcon }, ui, { h: 10, w: 10 }, group);
            } else if (i === data.length - 1) {
                mark = getStrengthMarker(H, map, data[i], contentString, defaultLayers, { icon: endIcon }, ui, { h: 10, w: 10 }, group);
            } else if ((i !== data.length - 1 || 0) && data[i].type === "undesignated") {
                mark = getStrengthMarker(H, map, data[i], contentString, defaultLayers, { icon: unScheduledIcon }, ui, { h: 10, w: 10 }, group);
                markerLabel += 1;
            } else if ((i !== data.length - 1 || 0) && data[i].type === "designated") {
                mark = getStrengthMarker(H, map, data[i], contentString, defaultLayers, { icon: scheduledIcon }, ui, { h: 10, w: 10 }, group);
                markerLabel += 1;
            }
            markers.push(mark);
        }
        setMap(map)
        setGroup(group)
        if(isRoad){
            getCarPolyline(resp, wayPoints, map, group, H)
        }
        else{
            getDefaultPolyline(allPoints)
        }
    };

    const getStrengthMarker = (H, map, station, contentString, defaultLayers, icon, ui, size, group) => {
        let marker = new H.map.Marker(station, icon, size);
        let bubble;

        marker.addEventListener(
            "tap",
            function (evt) {
                bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: evt.target.getData(),
                });
                ui.addBubble(bubble);
            },
            false
        );
        marker.setData(contentString);

        group.addObject(marker);
        return marker;
    };

    const addRouteShapeToMap = (route,H, map, group) => {
        if(map && group){
            if(polylineRef.current){
                group.removeObject(polylineRef.current)
            }
        }
        let polyLineTempArr = []
        route.sections.forEach((section) => {
            let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
            let polyline = new H.map.Polyline(linestring, {
                style: {
                    lineWidth: 5,
                    fillColor: "white",
                    strokeColor: "rgba(10, 3, 241, 1)",
                    lineDash: [0, 0],
                    //lineTailCap: "arrow-tail",
                    //lineHeadCap: "arrow-head",
                },
            });
            polyLineTempArr.push(polyline)
            group.addObject(polyline);
        });
        polylineRef.current = polyLineTempArr
        map.addObject(group);
        map.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
        });
        // this.setState({ map, group });
    };

    const customizedContent = (item) => {
        return (
            <Card title={item.type} subTitle={item.type === "Data Not Recived" ? moment(item.timeRange[0]).format("MMM DD, HH:mm") + "-" + moment(item.timeRange[1]).format("MMM DD, HH:mm") : moment(item.time).format("MMM DD, HH:mm")}>
                {item.duration !== null && item.duration !== "" ? (
                    <p>
                        <i className="pi pi-clock mr-2" style={{ color: "rgb(156, 39, 176)", fontSize: "16px" }}></i> {item.duration}
                    </p>
                ) : null}

                {item.address ? <p>
                    <i className="pi pi-map-marker mr-2" style={{ color: "rgb(156, 39, 176)", fontSize: "16px" }} />
                    {item.address}
                </p> : null}
                {item.reasons&&item.reasons.length>0 ? <p>
                    <i className="pi pi-info-circle mr-2" style={{ color: "rgb(156, 39, 176)", fontSize: "16px" }} />
                    {item.reasons.join(" , ")}
                </p> : null}
            </Card>
        );
    };

    const tableHeader = (
        <ColumnGroup>
            <Row>
                <Column header="Location" className="blue-bg white-space-nowrap"></Column>
                <Column header="Geofence Alias" className="blue-bg white-space-nowrap"></Column>
                <Column header="Stoppage Time" className="blue-bg white-space-nowrap"></Column>
                <Column header="Stoppage Duration" className="blue-bg white-space-nowrap"></Column>
                <Column header="Geofence Type" className="blue-bg white-space-nowrap"></Column>
            </Row>
        </ColumnGroup>
    );

    const timeConvert = (value) => {
        return (
            <>
                <p>{secondsToHm(value.stoppageDuration)}</p>
            </>
        );
    };

    const dateFormat = (value) => {
        return <>{serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US" ? moment(value.start_time).format("HH:mm") : moment(value.start_time).format("HH:mm")}</>;
    };

    const customizedMarker = (item) => {
        return (
            <span className="custom-marker shadow-1" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const getCarPolyline = (resp, wayPoints, map, group, H) => {
        vehicleService
        .getRoute(`${resp.startLocation.lat},${resp.startLocation.lng}`, `${resp.endLocation.lat},${resp.endLocation.lng}`, wayPoints.length ? wayPoints.join("&") : null)
        .then((response) => {
            addRouteShapeToMap(response.routes[0],H, map, group);
        })
        .catch((reason) => {
            console.error(reason);
        });
    }

    const getDefaultPolyline = (allPoints=[]) =>{
        if (mapState) {
            if(polylineRef.current.length){
                polylineRef && polylineRef.current.forEach(item=>{
                    groupState.removeObject(item)
                })
            }
        }
        let lineString = new window.H.geo.LineString();
        allPoints.forEach(function (point) {
            lineString.pushPoint(point);
        });
        let polyline = new window.H.map.Polyline(lineString, {
            style: {
                lineWidth: 5,
                fillColor: "white",
                strokeColor: "rgba(10, 3, 241, 1)",
                lineDash: [0, 2],
                lineTailCap: "arrow-tail",
                lineHeadCap: "arrow-head",
            }
        });
        groupState.addObject(polyline);
        polylineRef.current = polyline
        mapState.addObject(groupState);
        mapState.getViewModel().setLookAtData({
            bounds: groupState.getBoundingBox()
        });
    } 
    useEffect(()=>{
        if(reachedTickets && reachedTickets.length) setReachedMapData(reachedTickets[0])
        else setReachedMapData(null)
    },[reachedTickets])

    const updatePlot =(e, isRoad) =>{
        if(!isRoad) getDefaultPolyline(empData?.allPoints)
        else getCarPolyline(empData?.data,empData?.wayPoints, mapState, groupState, window.H)
        setIsRoad(isRoad)
    }
    const addressTemplate = (address,city, state, country) =>{
        return `${address}, ${city}, ${state}`
    }

    const onReachedTicketsClick = (e) => {
        // add check to set map data to null if time greater that 1hr
        setReachedMapData(e.value)
    }

    const GeoFenceEvents = () => {
        // Validate geoFenceData
        if (!geoFenceData || !Array.isArray(geoFenceData)) {
            console.error("Invalid geoFenceData:", geoFenceData);
            return null;
        }
    
        // Calculate entry and exit events
        const entryEvents = geoFenceData.filter(item => item.prevstate === "OutsideGF" && item.state === "InsideGF").length;
        const exitEvents = geoFenceData.filter(item => item.prevstate === "InsideGF" && item.state === "OutsideGF").length;
    
        // Timeline content
    
        return (
            <div>
                <span className="geofenceTitle">Geo-fence Events</span>
                <span style={{ float: "right", fontSize: "1.2rem" }}>
                    <span>
                        {" "}
                        {"Total "} <Badge value={entryEvents+exitEvents || "0"} style={{ fontSize: "1rem", background: "#57624e" }} className="mr-2"></Badge>
                    </span>
                    <span>
                        {" "}
                        {"Entry "} <Badge value={entryEvents || "0"} style={{ fontSize: "1rem", background: "#0c6291" }} className="mr-2"></Badge>
                    </span>
                    <span>
                        {" "}
                        {"Exit "} <Badge value={exitEvents || "0"} style={{ fontSize: "1rem", background: "#628c3f" }} className="mr-2"></Badge>
                    </span>
                </span>
            </div>
        );
    };
    return (
        <div className="grid">
            <div className="col-12">
                <div className="flex flex-wrap justify-content-between align-items-center">
                    <div className="flex align-items-center">
                        <Button icon="pi pi-angle-left text-xl" style={{backgroundColor: "#B6D475", color: "#1D2129"}} onClick={back} />
                        <h5 className="pl-3 m-0" style={{ lineHeight: 1 }}>
                            {data.name}
                        </h5>
                    </div>
                    <h5 className="m-0 flex align-items-center">
                        <i className="pi pi-calendar"></i> {serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US" ? moment(data.date).format("MMM DD,YYYY") : moment(data.date).format("Do MMM, YYYY")}
                    </h5>
                </div>
            </div>
            <div className="col-12 md:col-5 lg:col-5">
                <div className="flex mb-3">
                    <div className="flex-1 pr-2">
                        <div className="info-card border-radius-12px">
                            <p>Total Designated Stoppages</p>
                            <h3 className="text-primary">{degStop}</h3>
                        </div>
                    </div>
                    <div className="flex-1 pr-2">
                        <div className="info-card border-radius-12px">
                            <p>Total Undesignated Stoppages</p>
                            <h3 className="text-primary">{undegStop}</h3>
                        </div>
                    </div>
                </div>
                <div className="datatable-selection-demo mt-4">
                    <div className="card border-radius-12px">
                        <DataTable value={data.stoppagePoints} dataKey="employeeID" responsiveLayout="scroll" headerColumnGroup={tableHeader} rows={3} paginator>
                            <Column field="address"></Column>
                            <Column field="alias"></Column>
                            <Column body={dateFormat}></Column>
                            <Column body={timeConvert}></Column>
                            <Column field="type"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-7 lg:col-7">
                <div style={{ position: "relative" }}>
                    <div
                        id="mapContainer"
                        style={{
                            width: "100%",
                            height: "482px",
                            overflow: "hidden",
                        }}
                        className="border-radius-12px"
                    ></div>
                    <div className="snap_road" onClick={(e)=>updatePlot(e, !isRoad)}>
                        {!isRoad ? <>
                            <Tooltip target={".raw"}/>
                        <i className="raw pi pi-car" data-pr-tooltip="Switch to road view" data-pr-position="left" style={{ fontSize: '1.7em' }}></i></> :
                        <>
                        <Tooltip target={".road"}/>
                        <img src="/images/Route.svg" className="road" height="25px" data-pr-tooltip="Switch to raw view" data-pr-position="left"/></> }
                        </div>
                    <div className="map_detail_wrapper border-radius-12px">
                        <div className="info_grid ">
                            <p>Start Time</p>
                            <h3>{moment(data.startTime).format("HH:mm")}</h3>
                        </div>
                        <div className="info_grid">
                            <p>End Time</p>
                            <h3>{moment(data.endTime).format("HH:mm")}</h3>
                        </div>
                        <div className="info_grid">
                            <p>Total Travel Time</p>
                            <h3>{secondsToHm(data.totalTravelTime)}</h3>
                        </div>
                        <div className="info_grid">
                            <p>Total Distance</p>
                            <h3>{serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US" ? convertValue(data.totalDistanceTravelled / 1000, "distance", "FPS") : convertValue(data.totalDistanceTravelled / 1000, "distance", "MKS")}</h3>
                        </div>
                        <div className="info_grid">
                            <p>Stoppage Point</p>
                            <h3>{data.stoppagePoints.length}</h3>
                        </div>
                        <div className="info_grid">
                            <p>Total Stoppage Period</p>
                            <h3>{secondsToHm(data.totalStoppageDuration)}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="timeline-demo col-12">
                <Accordion>
                    <AccordionTab header="Timeline Info">
                        {timeLineData && <Timeline value={timeLineData} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />}
                    </AccordionTab>
                </Accordion>
            </div>
            <div className="timeline-demo col-12">
                <Accordion>
                    <AccordionTab header={GeoFenceEvents()} style={{ maxHeight: "30vh", overflowY: "auto" }}
                    >
                        <div className="widget-timeline">
                            <Timeline
                            value={geoFenceData}
                            className="customized-timeline"
                            marker={marker}
                            content={content}
                        /></div>
                    </AccordionTab>
                </Accordion>
            </div>
            <div className="col-12">
                <div className="card flex">
                    <div className="col-7">
                    <DataTable emptyMessage="No Reached Status Data" value={reachedTickets ?? []} selectionMode="single" showGridlines responsiveLayout="scroll" onSelectionChange={(e) => onReachedTicketsClick(e)} rows={4} paginator>
                        <Column header="Ticket No" headerClassName="blue-bg white-space-nowrap" field="TicketNo" bodyClassName="white-space-nowrap"></Column>
                        <Column header="Site Name" headerClassName="blue-bg white-space-nowrap" field="SiteName" bodyClassName="white-space-nowrap"></Column>
                        <Column header="Site Address" headerClassName="blue-bg white-space-nowrap" body={(row)=>addressTemplate(JSON.parse(row.SiteAddress), row.SiteCity, row.State )} ></Column>
                        <Column header="Reached Location" headerClassName="blue-bg white-space-nowrap" body={(row)=>row.reached_loc.add} ></Column>
                        <Column header="Reached Time" headerClassName="blue-bg white-space-nowrap" bodyClassName="white-space-nowrap" body={(row)=>row.reached_loc.ts}></Column>
                    </DataTable>
                    </div>
                    <div className="col-5">
                   { reachedMapData && <PlotSiteEmpLoc data={reachedMapData}/>}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EmployeeReport;

const PlotSiteEmpLoc = ({data}) => {
    const mapRef = useRef(null);

    useEffect(() => {
        if(!data) return
        const H = window.H;

        let startIcon = new H.map.Icon(map_start_icon, { size: { w: 32, h: 32 } });
        let userIcon = new H.map.Icon(street_icon,{
            size: { w: 32, h: 32 },
        })

        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
            zoom: 10,
            pixelRatio: window.devicePixelRatio || 1,
            ...(data.siteLatLong && {center: data.siteLatLong})
        });
        window.addEventListener("resize", () => map.getViewPort().resize());

        let ui = H.ui.UI.createDefault(map, defaultLayers);
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        let group = new H.map.Group();

        if(data.siteLatLong){
        let siteMarker = new H.map.Marker(data.siteLatLong,{icon: startIcon});
        data.siteLatLong && group.addObject(siteMarker)
        }

        let empMarker = new H.map.Marker({lat:data.reached_loc.lat, lng: data.reached_loc.lon}, {icon: userIcon});
        group.addObject(empMarker)

        map.addObject(group);
        map.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
            zoom : 7
        });

        return () => {
            map.dispose();
          };
    }, [data])

    return <div ref={mapRef} style={{ width: '100%', height: '400px' }} />;
}
