import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import { TabView, TabPanel } from "primereact/tabview";
import service from '../../service/apiService';
import moment from "moment";
import { dateFormatter } from "../../utilities/utillFunction";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { chartHeadingStyle, newChartColors } from "../../utilities/constant";
const ContentIngestion = () => {
    const serviceId = localStorage.getItem('serviceId')
    const [forPeriod, setForPeriod] = useState([])
    const [today, setToday] = useState([])
    const [yesterday, setYesterday] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)
    const [lineChart, setLineChart] = useState(null)
    const [historicalIngData, setHistoricalIngData] = useState([])
    const [forToday, setForToday] = useState({})

    const todayDt = moment().format("YYYY-MM-DD")

    useEffect(() => {
        //getChartData()
        getData(todayDt, "forPeriod")
        getData(todayDt, 'today')
        fetchHistIngestionData()
    }, [])
    const getCollection = (type) => {
        let collections = {}
        if (type === "forPeriod") {
            collections = {
                'ingestion': 'nq-dashboard-b2c-content-ingestion-by-partner-period-meta',
                'failureReason': 'nq-dashboard-b2c-content-ingestion-FailReason-period-meta'
            }
        }
        else if (type === "today" || type === "yesterday") {
            collections = {
                'ingestion': 'nq-dashboard-b2c-content-ingestion-by-partner',
                'failureReason': 'nq-dashboard-b2c-content-ingestion-FailReason'
            }
        }
        return collections
    }
    const getData = (date, type) => {
        const collections = getCollection(type)
        let query = { filter: { date: date }, sort: [["fdPartnerName", 1]] }
        let fquery = { filter: { date: date }, sort: [['FailureCount', -1]], limit: 5 }
        const data = {}
        Promise.all([service.httpDBService(query, serviceId, collections.ingestion),
        service.httpDBService(fquery, serviceId, collections.failureReason)
        ]).then((res) => {
            if (res[0]) {
                let contIngestion = []
                if (res[0].length) {
                    const domestic = {
                        fdPartnerName : "Domestic",
                        ReceivedCount : 0,
                        VF_MetadataCount: 0,
                        VF_SwitchCount: 0,
                        SI_AlfrescoCount: 0,
                        SI_RWCount : 0
                    },
                    total = JSON.parse(JSON.stringify(domestic))
                    total["fdPartnerName"] = "Total"

                    let condition = ["springer", "taylor", "notion"]
                    res[0].forEach(item => {
                        if (condition.some((co)=> item.fdPartnerName.toLowerCase().includes(co))) contIngestion.push(item)
                        else {
                            domestic["ReceivedCount"] = domestic["ReceivedCount"] + item.ReceivedCount
                            domestic["VF_MetadataCount"] = domestic["VF_MetadataCount"] + item.VF_MetadataCount
                            domestic["VF_SwitchCount"] = domestic["VF_SwitchCount"] + item.VF_SwitchCount
                            domestic["SI_AlfrescoCount"] = domestic["SI_AlfrescoCount"] + item.SI_AlfrescoCount
                            domestic["SI_RWCount"] = domestic["SI_RWCount"] + item.SI_RWCount
                        }
                        total["ReceivedCount"] = total["ReceivedCount"] + item.ReceivedCount
                        total["VF_MetadataCount"] = total["VF_MetadataCount"] + item.VF_MetadataCount
                        total["VF_SwitchCount"] = total["VF_SwitchCount"] + item.VF_SwitchCount
                        total["SI_AlfrescoCount"] = total["SI_AlfrescoCount"] + item.SI_AlfrescoCount
                        total["SI_RWCount"] = total["SI_RWCount"] + item.SI_RWCount
                    })
                    contIngestion.push(...[domestic, total])
                }
                data["contIng"] = contIngestion
            }
            if (res[1]) {
                data["failReason"] = res[1]
            }

            if (type === 'forPeriod') setForPeriod(data)
            else if (type === 'today') setToday(data)
            else if (type === 'yesterday') setYesterday(data)
        }).catch(e => {
            console.error('Error while getting data', e)
        })
    }
    const getChartData = async (chartData = []) => {

        if (!chartData) return {}

        const categories = []
        const ingested = []
        const received = []
        chartData.forEach(item => {

            let date = dateFormatter(item._id, "DD-MMM-YY", "YYYY-MM-DD")
            categories.push(date)
            ingested.push(item.SI_RWCount)
            received.push(item.ReceivedCount)
        })
        let lineChartData = {
            chart: {
                type: "spline",
            },
            title: {
                text: "Content Ingestion Trend",
                style: chartHeadingStyle,
            },

            xAxis: {
                categories: categories,
            },
            yAxis: {
                title: {
                    text: "Count",
                },
                labels: {
                    formatter: function () {
                        return this.value + "";
                    },
                },
            },
            tooltip: {
                crosshairs: true,
                shared: true,
            },
            plotOptions: {
                spline: {
                    dataLabels: {
                        enabled: true
                    },
                },
            },
            series: [{
                name: "Ingested",
                data: ingested,
                color: newChartColors[6]
            },
            {
                name: "Received",
                data: received,
                color: newChartColors[5]
            }]
        }

        setLineChart(lineChartData)

    }

    const fetchHistIngestionData = () => {
        const startDate = moment().subtract(10, 'days').format("YYYY-MM-DD")
        const endDate = moment().format("YYYY-MM-DD")

        const forTodaypayload = {
            operation: "aggregate",
            aggregate: [{
                "$match": {
                    "date": endDate,
                }
            }, {
                "$group": {
                    "_id": null,
                    "received": { "$sum": "$ReceivedCount" },
                    "ingested": { "$sum": "$SI_RWCount" },
                    "validationfailure": { "$sum": { "$add": ["$VF_MetadataCount", "$VF_SwitchCount"] } }

                }
            }]
        }

        const payload = {
            operation: "aggregate",
            aggregate: [{
                "$match": {
                    "date": { "$gte": startDate, "$lte": endDate },
                }
            }, {
                "$group": {
                    "_id": "$date",
                    "ReceivedCount": { "$sum": "$ReceivedCount" },
                    "SI_RWCount": { "$sum": "$SI_RWCount" },
                    "SI_AlfrescoCount": { "$sum": "$SI_AlfrescoCount" }

                }
            }, {
                "$sort": {
                    "_id": 1
                }
            }]
        }

        const collection = "nq-dashboard-b2c-content-ingestion-by-partner"
        Promise.all([service.httpDBService(forTodaypayload, serviceId, collection), service.httpDBService(payload, serviceId, collection)]).then((res) => {
            if (res[0] && res[0].length) {
                setForToday(res[0][0])
            }
            if (res[1]) {
                getChartData(res[1])
                setHistoricalIngData(res[1])
            }
        })
    }
    const customHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="no-grid-lines bg-transparent" header="" colSpan={2} className="text-center"></Column>
                <Column headerClassName="blue-bg" header="Validation failure" colSpan={2} className="text-center"></Column>
                <Column headerClassName="blue-bg" header="Successfully Ingested" colSpan={2} className="text-center"></Column>
            </Row>
            <Row>
                <Column headerClassName="blue-bg" header="" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Received" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Metadata" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Switch" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="RW" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Alfresco" className="text-center" ></Column>
                <Column style={{backgroundColor: "#80808059"}} header=" % Success(8hrs)" className="text-center" ></Column>
            </Row>
        </ColumnGroup>
    )
    const TableView = (data = [], time) => {
        if (!data.length) {
            return <div className="my-info-message">{showNoDataMsg(`Data is not available for ${time}`)}</div>
        }
        return (
            <div>
                <DataTable value={data} responsiveLayout="scroll" paginator rows={10} showGridlines headerColumnGroup={customHeader}>
                    <Column className="text-left" field='fdPartnerName'></Column>
                    <Column className="text-center" field='ReceivedCount'></Column>
                    <Column className="text-center" field='VF_MetadataCount'></Column>
                    <Column className="text-center" field='VF_SwitchCount'></Column>
                    <Column className="text-center" field='SI_RWCount'></Column>
                    <Column className="text-center" field='SI_AlfrescoCount'></Column>
                    <Column  bodyStyle={{backgroundColor: "#80808059"}} className="text-center" field='success_8_hr'></Column>
                </DataTable>
            </div>
        )
    }
    const FRTable = (data = [], time) => {
        if (!data.length) {
            return <div className="my-info-message">{showNoDataMsg(`Failure reason data not available for ${time}`)}</div>
        }
        return (
            <div>
                <DataTable value={data} responsiveLayout="scroll" showGridlines>
                    <Column header="Failure Reason" headerClassName="blue-bg" className="white-space-nowrap text-left" field='fdStatusDesc'></Column>
                    <Column header="Count" headerClassName="blue-bg" className="text-center" field='FailureCount'></Column>
                </DataTable>
            </div>
        )
    }
    const onTabChange = (e) => {
        if (e.index === 1 || e.index === 2) {
            let dt = todayDt
            let type = 'today'
            if (e.index === 2) {
                dt = moment().subtract(1, "days").format("YYYY-MM-DD")
                type = 'yesterday'
            }
            if ((e.index === 1 && !Object.keys(today).length) || (e.index === 2 && !Object.keys(yesterday).length)) getData(dt, type)
        }
        setActiveIndex(e.index)
    }
    const showNoDataMsg = (Message = "") => {
        return <div className="my-info-message">{Message}</div>
    }
    const histHeadergroup = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="no-grid-lines bg-transparent" header="" colSpan={2} className="text-center"></Column>
                <Column headerClassName="blue-bg" header="Successfully Ingested" colSpan={2} className="text-center"></Column>
                <Column style={{backgroundColor: "#80808059"}} header="Ingestion Time" colSpan={3} className="text-center"></Column>
            </Row>
            <Row>
                <Column headerClassName="blue-bg" header="Date" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Received" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="RW" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Alfresco" className="text-center" ></Column>
                <Column style={{backgroundColor: "#80808059"}} header="Min" className="text-center" ></Column>
                <Column style={{backgroundColor: "#80808059"}} header="Max" className="text-center" ></Column>
                <Column style={{backgroundColor: "#80808059"}} header="Avg" className="text-center" ></Column>
            </Row>
        </ColumnGroup>
    )
    return (
        <>
            <div className="col-12" style={{ overflow: "auto" }}>
                <div className="card mb-2 border-radius-12px">
                    <h4 className="text-2xl m-0 mb-1 font-bold">For Today</h4>
                    <div class="flex flex-wrap justify-content-between  font-medium mb-2">
                        <span class="col-12 text-5xl lg:col-4 md:col-4 flex align-items-center font-semibold " style={{ color: newChartColors[5] }}>{forToday?.received || 0} <span class="ml-2 text-xl " style={{ color: "#5E6771" }}>Received</span>
                        </span>
                        <span class="col-12 text-5xl lg:col-4 md:col-4 flex align-items-center font-semibold color1">{(forToday?.validationfailure || 0)}<span class=" text-xl  ml-2" style={{ color: "#5E6771" }}>Validation Failure</span>
                        </span>
                        <span class="col-12 text-5xl lg:col-4 md:col-4 flex align-items-center font-semibold" style={{ color: newChartColors[6] }}>{(forToday?.ingested || 0)}<span class=" text-xl  ml-2" style={{ color: "#5E6771" }}>Ingested</span></span>
                    </div>
                </div>
            </div>
            <div className="col-12 pt-0">
                <div className="card border-radius-12px">
                    <HighchartsReact highcharts={Highcharts} options={lineChart} />
                </div>
            </div>

            <div className="col-12">
                <div className="">
                    <h4 className="mb-1">Content Ingestion</h4>
                </div>
                <TabView activeIndex={activeIndex} onTabChange={onTabChange}>
                    <TabPanel header="For The Period" index={0}>
                        {
                            forPeriod && Object.keys(forPeriod).length ?
                                <>
                                    {forPeriod.contIng && <div className="col-12">{TableView(forPeriod.contIng, "the period")}</div>}
                                    <div className="mt-3 ml-2 mb-1">
                                        <h4 className="mb-1">Failure Reason</h4>
                                    </div>
                                    {forPeriod.failReason && <div className="lg:col-6 col-12">{FRTable(forPeriod.failReason, "the period")}</div>}
                                </> : null
                        }
                    </TabPanel>
                    <TabPanel header="For The Day" index={1}>
                        {
                            today && Object.keys(today).length ?
                                <>
                                    {today.contIng && <div className="col-12">{TableView(today.contIng, "the day")}</div>}
                                    <div className="mt-3 ml-2 mb-1">
                                        <h4 className="mb-1">Failure Reason</h4>
                                    </div>
                                    {today.failReason && <div className="lg:col-6 col-12">{FRTable(today.failReason, "the day")}</div>}
                                </> : null
                        }
                    </TabPanel>
                    <TabPanel header="Previous Day" index={2}>
                        {
                            yesterday && Object.keys(yesterday).length ?
                                <>
                                    {yesterday.contIng && <div className="col-12">{TableView(yesterday.contIng, "yesterday")}</div>}
                                    <div className="mt-3 ml-2 mb-1">
                                        <h4 className="mb-1">Failure Reason</h4>
                                    </div>
                                    {yesterday.failReason && <div className="lg:col-6 col-12">{FRTable(yesterday.failReason, "yesterday")}</div>}
                                </> : null
                        }
                    </TabPanel>
                </TabView>

            </div>

            <div className="col-12">
                <div>
                    <h4 className="mb-1">Content Ingestion - Last 10 days</h4>
                </div>
                <DataTable value={historicalIngData} responsiveLayout="scroll" showGridlines headerColumnGroup={histHeadergroup}>
                    <Column header="Date" headerClassName="blue-bg" className="white-space-nowrap text-left" field='_id'></Column>
                    <Column header="Received" headerClassName="blue-bg" className="text-center" field='ReceivedCount'></Column>
                    <Column header="RW" headerClassName="blue-bg" className="text-center" field='SI_RWCount'></Column>
                    <Column header="Alfresco" headerClassName="blue-bg" className="text-center" field='SI_AlfrescoCount'></Column>
                    <Column bodyStyle={{backgroundColor: "#80808059"}} className="text-center" field='min'></Column>
                    <Column bodyStyle={{backgroundColor: "#80808059"}} className="text-center" field='max'></Column>
                    <Column bodyStyle={{backgroundColor: "#80808059"}} className="text-center" field='avg'></Column>
                </DataTable>
            </div>

        </>
    )
}
export default ContentIngestion;