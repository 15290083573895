import React, { useEffect, useState } from "react"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { ColumnGroup } from "primereact/columngroup"
import { Row } from "primereact/row"
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { chartHeadingStyle } from "../../utilities/constant";
import { colorPalettes } from "../../lib/constants";
import apiService from '../../service/apiService'
import moment from "moment"
import { ecomm_channels, channels } from './ReproService'
import { dateFormatter } from "../../utilities/utillFunction";

const ListingContentIngestion = () => {
    const [ingestionStatus, setIngestionStatus] = useState([])
    const [reproCategory, setReproCategory] = useState([])
    const [ageingLineChart, setAgeingLineChart] = useState(null)
    const [listingTotal, setListingTotal] = useState([])
    const [agingErrorTotal, setAgingErrorTotal] = useState([])
    const [agingErrorData, setAgingErrorData] = useState([])
    const [channelAEData, setchannelAEData] = useState([])

    const todayDt = moment().format("YYYY-MM-DD")
    const serviceId = localStorage.getItem('serviceId')
    const time_range = [{
        title: '< 24 Hrs', key: "less_than_24hrs"
    }, { title: "24-48 Hrs", key: "24_to_48hrs" }, { title: "> 48 Hrs", key: "more_than_48hrs" }]
    useEffect(async () => {
        let last24_hr_con_list_query = {
            operation: "aggregate",
            aggregate: [
                { $match: { date: todayDt } },
                {
                    $project: {
                        created_at: 1, hr: 1,
                        reproCategory: 1,
                        ingestionStatus: 1,
                        totalReproCategoryCount: {
                            $sum: "$reproCategory.count"
                        },
                        totalIngestionStatusCount: {
                            $sum: "$ingestionStatus.count"
                        }
                    }
                }
            ]
        }

        let listing_query = {
            filter: { date: todayDt },
            projection: { _id: 0 }
        }

        let aging_error_query = {
            filter: { date: todayDt },
            projection: { _id: 0 }
        }

        let aging_error_total_query = {
            operation: "aggregate",
            aggregate: [
                { $match: { date: todayDt } },
                {
                    $project: {
                        date: 1, hr: 1,
                        less_than_24_hrs: {
                            $sum: "$error.less_than_24_hrs"
                        },
                        '24_to_48_hrs': {
                            $sum: "$error.24_to_48_hrs"
                        },
                        'greater_than_48_hrs': {
                            $sum: "$error.greater_than_48_hrs"
                        }
                    }
                }
            ]
        }

        let channel_aging_q = {
            filter: { date: todayDt },
            projection: { _id: 0 }
        }

        Promise.all([
            apiService.httpDBService(last24_hr_con_list_query, serviceId, 'nq-dashboard-b2c-ContentIngestion_hourly_24hr-meta'),
            apiService.httpDBService(listing_query, serviceId, "nq-dashboard-b2c-Listing_hourly_24hr-meta"),
            apiService.httpDBService(aging_error_query, serviceId, "nq-dashboard-b2c-Aging_ErrorBucket_hourly_24h-meta"),
            apiService.httpDBService(aging_error_total_query, serviceId, "nq-dashboard-b2c-Aging_ErrorBucket_hourly_24h-data"),
            apiService.httpDBService(channel_aging_q, serviceId, "nq-dashboard-b2c-Age_Channel_ErrBkt_hourly_24h-meta")

        ])
            .then((res) => {
                let ing_repro_category = res[0]
                if (ing_repro_category && ing_repro_category.length) {
                    ing_repro_category = ing_repro_category[0]
                    if (ing_repro_category.ingestionStatus) {
                        ing_repro_category.ingestionStatus.push({ ingestionStatus: "Total", count: ing_repro_category.totalIngestionStatusCount })
                        setIngestionStatus(ing_repro_category.ingestionStatus)
                    }
                    if (ing_repro_category.reproCategory) {
                        ing_repro_category.reproCategory.push({ reproCategory: "Total", count: ing_repro_category.totalReproCategoryCount })
                        setReproCategory(ing_repro_category.reproCategory)
                    }
                }

                if (res[1] && res[1].length) {
                    const channelWiseMapData = {}

                    res[1].forEach((item) => {
                        delete item.date
                        delete item.created_at
                        delete item.hr
                        let keys = Object.keys(item)
                        keys.forEach(key => {
                            let fidx = channels.findIndex((ch) => key.toLocaleLowerCase().includes(ch.toLowerCase()))
                            if (fidx > -1) {
                                let channel = channels[fidx]
                                let newkey = key.toLowerCase().replace((channel.toLowerCase() + "_"), "")
                                let mapKey = channel
                                if (mapKey in channelWiseMapData) channelWiseMapData[mapKey][newkey] = item[key]
                                else channelWiseMapData[mapKey] = { [newkey]: item[key], "ingestion_total": item["ingestion_total"], "channel": channel }
                            }
                        })
                    })
                    const listingTotalData = Object.values(channelWiseMapData)
                    setListingTotal(listingTotalData)

                    if (res[2] && res[2].length) {
                        res[2][0].error.forEach(e => {
                            if (e.mspErrorCategory === "") e.mspErrorCategory = '""'
                            if (e.mspErrorCategory === null) e.mspErrorCategory = 'null'

                        })
                        setAgingErrorTotal(res[2][0].error)
                    }

                    if (res[3] && res[3].length) {
                        getlineChart(res[3])
                        setAgingErrorData(res[3])
                    }

                    if (res[4] && res[4].length) {
                        res[4][0].error.forEach(e => {
                            if (e.ErrorCategory === "") e.ErrorCategory = '""'
                            if (e.ErrorCategory === null) e.ErrorCategory = 'null'

                        })
                        setchannelAEData(res[4][0].error)
                    }
                }

            })
            .catch(e => {
                console.error(e)
            })
    }, [])
    const tileCountTable = (data, firstColKey, firstColumnNm, paginate) => {
        return (
            <DataTable value={data} style={{ marginTop: '0' }} paginator={paginate} rows={4} className='mt-1 inter-font' paginatorTemplate="PrevPageLink NextPageLink">
                <Column
                    headerClassName='text-blue-700 font-bold'
                    header={firstColumnNm}
                    bodyClassName='text-style'
                    body={(e) => { return <span className='p-2'>{e[firstColKey]}</span> }}>
                </Column>
                <Column
                    headerClassName="headStyle text-blue-700"
                    header="Count of Tiles"
                    body={(e) => (
                        <span style={{ color: colorPalettes.color2 }}>
                            {e.count}
                        </span>
                    )}
                    bodyClassName="bodyStyle2"
                />

            </DataTable>
        )
    }

    const TableView = (data) => {
        return (
            <div>
                <DataTable value={data} responsiveLayout="scroll" className="inter-font" rowGroupMode="rowspan" showGridlines headerColumnGroup={customHeader}>
                    <Column headerClassName="blue-bg" className="text-center" field='channel'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='eligible_succ'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='eligible_fail'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='listing_succ'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='listing_fail'></Column>
                    <Column bodyStyle={{ backgroundColor: "#80808059" }} className="text-center" field='success_%'></Column>
                </DataTable>
            </div>
        )
    }

    const customHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="blue-bg" header="" colSpan={1} className="text-center"></Column>
                <Column headerClassName="blue-bg" header="Eligibilty" colSpan={2} className="text-center"></Column>
                <Column headerClassName="blue-bg" header="Listing" colSpan={2} className="text-center"></Column>
                <Column style={{ backgroundColor: "#80808059" }} header="" className="text-center"></Column>
            </Row>
            <Row>
                <Column headerClassName="blue-bg" header="" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Success" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Failure" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Success" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Failure" className="text-center" ></Column>
                <Column style={{ backgroundColor: "#80808059" }} header="Success % " className="text-center" ></Column>
            </Row>
        </ColumnGroup>
    )

    const getlineChart = (data) => {
        let lessThan24 = []
        let between24_48 = []
        let greaterThan48 = []
        let dates = []
        data.forEach(r => {
            let dt = r.date + " " + r.hr + ":00:00"
            dt = dateFormatter(dt, 'hh A', 'YYYY-MM-DD HH:mm:ss')

            lessThan24.push(r.less_than_24_hrs)
            between24_48.push(r['24_to_48_hrs'])
            greaterThan48.push(r.greater_than_48_hrs)
            dates.push(dt)
        })
        let lineChartData = {
            chart: {
                type: "spline",
            },
            title: {
                text: "Hourly Aging Errors",
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: dates,
            },
            yAxis: {
                title: {
                    text: "Error Count",
                },
                labels: {
                    formatter: function () {
                        return this.value + "";
                    },
                },
            },
            tooltip: {
                crosshairs: true,
                shared: true,
            },
            plotOptions: {
                spline: {
                    dataLabels: {
                        enabled: true
                    },
                },
            },
            series:
                [
                    {
                        name: "< 24 hrs",
                        data: lessThan24,
                        color: colorPalettes.color1
                    },
                    {
                        name: "24 - 48 hrs",
                        data: between24_48,
                        color: colorPalettes.color2
                    },
                    {
                        name: "> 48 hrs",
                        data: greaterThan48,
                        color: colorPalettes.color4
                    }]
        }
        setAgeingLineChart(lineChartData)
    }

    const agingCustomHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="blue-bg" header="" colSpan={1} className="text-center"></Column>
                <Column headerClassName="blue-bg" header="Ageing" colSpan={3} className="text-center"></Column>
            </Row>
            <Row>
                <Column headerClassName="blue-bg" header="" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header=" < 24 Hrs" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header=" 24 - 48 Hrs " className="text-center" ></Column>
                <Column headerClassName="blue-bg" header=" >  48 Hrs " className="text-center" ></Column>
            </Row>
        </ColumnGroup>
    )

    const agingErrorTable = (data) => {
        return (
            <DataTable value={data} responsiveLayout="scroll" className="inter-font" rowGroupMode="rowspan" showGridlines headerColumnGroup={agingCustomHeader}>
                <Column headerClassName="blue-bg" className="text-center" field='mspErrorCategory'></Column>
                <Column headerClassName="blue-bg" className="text-center" field='less_than_24_hrs'></Column>
                <Column headerClassName="blue-bg" className="text-center" field='24_to_48_hrs'></Column>
                <Column headerClassName="blue-bg" className="text-center" field='greater_than_48_hrs'></Column>
            </DataTable>
        )
    }

    const channelAgingCustomHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="blue-bg" header="" colSpan={1} className="text-center"></Column>
                {
                    time_range.map(tr => {
                        return (<Column headerClassName="blue-bg" header={tr.title} colSpan={ecomm_channels.length} className="text-center"></Column>)
                    })
                }
            </Row>
            <Row>
                <Column headerClassName="blue-bg" header="Channel Error Buckets" className="text-center" ></Column>
                {
                    time_range.map(tr => {
                        return ecomm_channels.map(c => {
                            return (
                                <Column headerClassName="blue-bg" header={c} className="text-center" ></Column>
                            )
                        })
                    })
                }
            </Row>
        </ColumnGroup>
    )

    const channelAgingErrorTable = (data) => {
        return (
            <DataTable value={data} responsiveLayout="scroll" className="inter-font" rowGroupMode="rowspan" showGridlines headerColumnGroup={channelAgingCustomHeader}>
                <Column headerClassName="blue-bg" className="text-center" field='ErrorCategory'></Column>
                {
                    time_range.map(tr => {
                        return ecomm_channels.map(c => {
                            return (
                                <Column headerClassName="blue-bg" field={c.toLowerCase() + "_" + tr.key} className="text-center" ></Column>
                            )
                        })
                    })
                }
            </DataTable>
        )
    }

    return (
        <>
            <div className="grid card ">
                <div className="col-12" >
                    <span className="font-bold text-xl ">Content Ingestion - Last 24 Hrs</span>
                </div>

                {
                    ingestionStatus && ingestionStatus.length ?
                        <div className="col-6"  >
                            {/* <div className="card"> */}
                            {/* <h5>Statuswise</h5> */}
                            {tileCountTable(ingestionStatus, 'ingestionStatus', 'Status', false)}
                            {/* </div> */}
                        </div>
                        : null
                }
                <div className="mx-4" style={{ borderRight: "1px solid #e4e4e4" }}></div>
                {
                    reproCategory && reproCategory.length ?
                        <div className="col-5">
                            {/* <div className="card"> */}
                            {/* <h5>Repro category wise </h5> */}
                            {tileCountTable(reproCategory, 'reproCategory', "Repro Category", true)}
                            {/* </div> */}
                        </div>
                        : null
                }
            </div>

            <div className="card grid">
                <div className="col-12">
                    <span className="font-bold text-xl">Listing - Last 24 Hrs</span>

                    {listingTotal.length ? TableView(listingTotal) : null}
                </div>
            </div>

            <div className="card grid">
                <div className="col-12">
                    <span className="font-bold text-xl">Listing Error Aging</span>

                    {agingErrorTotal.length ? agingErrorTable(agingErrorTotal) : null}

                    {ageingLineChart ?
                        <div className="col-12">
                            <HighchartsReact highcharts={Highcharts} options={ageingLineChart} />
                        </div> : null
                    }
                    {channelAEData.length ?
                        <div className="col-12">
                            <span className="font-bold text-xl">Channelwise Aging Errors</span>
                            {channelAgingErrorTable(channelAEData)}
                        </div> : null}

                </div>
            </div>
        </>
    )
}

export default ListingContentIngestion