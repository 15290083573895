import React, { useEffect, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from "primereact/inputtextarea"
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import service from '../service/apiService'
import { httpConstants } from '../lib/constants'

const inputStyle = {border: "1px solid rgba(116, 120, 141, 0.20)", borderRadius: "6px",background: "#FAFAFA"}
const HelpAndSupport = () => {
    const user_name = localStorage.getItem("username")
    const emailId = localStorage.getItem("emailId")
    const [disableBtn, setDisableBtn] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [topics, setTopics] = useState([])
    const [selectedTopic, setSelectedTopic] = useState("")
    const [ticketInfo, setTicketInfo] = useState({})
    const serviceDetails = JSON.parse(localStorage.getItem("service"))

    useEffect(() => {
        const payload = {
            projection: {
                "_id": 0
            }
        }
        service.httpDBService(payload, "nextqore", "nq-topics").then((res) => {
            if (res && res.length) {
                setTopics(res)
            }

        }).catch(err => { console.log(err.response) })

    }, [])
    const onSubmit = (e) => {
        e.preventDefault()
        const validation = validate()

        if (typeof (validation) === "string") {
            setErrMsg(validation)
        }
        else {
            setDisableBtn(true)
            setErrMsg("")
            const payload = {
                "name": user_name,
                "email": emailId ? emailId : ticketInfo.email?.trim()?.toLowerCase(),
                "subject": `${ticketInfo.subject?.trim()}_nq_${serviceDetails.serviceId}-${serviceDetails.serviceTypeName}`,
                "message": ticketInfo.message?.trim(),
                "topicId": selectedTopic
            }

            const url = process.env.REACT_APP_API_PARSER_URL + "/osticket/create_ticket"
            service.httpService(httpConstants.METHOD_TYPE.POST, payload, url).then((res) => {
                if (res && res.status && res.status === "Ticket created successfully !!") {
                    setErrMsg("success")
                    setTimeout(showSuccessMsgAndClear, 2000)
                }
            }).catch(err => {
                if (err.response?.data) {
                    let response = ""
                    if (typeof (err.response.data) === "object") {
                        response = err.response.data.detail
                    }
                    else response = err.response.statusText
                    setErrMsg(response)
                }
                setDisableBtn(false)
            })
        }
    }
    const validate = () => {
        if (!emailId) {
            if (ticketInfo.email) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(ticketInfo.email)) {
                    return "Incorrect Email";
                }
            } else return "Email Id not found";
        }
        if (!selectedTopic) return "Please select Topic"
        if (!ticketInfo.subject) return "Please add Subject"
        if (!ticketInfo.message) return "Please add Description"
    }

    const handleChange = (e) => {
        let name = e.target.name
        let value = e.value || e.target.value || ""
        setTicketInfo({ ...ticketInfo, [name]: value })
    }
    const showSuccessMsgAndClear = () => {
        setErrMsg("")
        setTicketInfo({})
        setSelectedTopic("")
        setDisableBtn(false)
    }
    return (
        <div className="flex mt-5 flex-column">
            <div className="align-self-center mt-auto mb-auto">
                <div className="pages-panel card mx-auto flex flex-column border-radius-12px">
                    <div className="pages-detail text-xl font-semibold text-center mb-4">Raise a Ticket</div>
                    <form onSubmit={onSubmit}>
                        <div className="input-panel flex flex-column px-3">
                            <div className='grid formgrid'>
                                <div className="p-inputgroup lg:col-6 col-12">
                                    <InputText value={user_name} readOnly type="text" id="name" style={inputStyle} className='input-border' />
                                </div>
                                <div className="p-inputgroup mt-3 lg:m-0 lg:col-6 col-12">
                                    <InputText name='email' placeholder='email' readOnly={emailId ? true : false} value={emailId ? emailId : ticketInfo.email ?? ""} onChange={handleChange} style={inputStyle} className='input-border' type='email' id="email" />
                                </div>
                            </div>
                            <div className="p-inputgroup mt-3">
                                <span className="p-float-label">
                                    <Dropdown id='topic' style={inputStyle} className='input-border pt-1' options={topics} onChange={(e) => setSelectedTopic(e.value)} optionLabel='topic' optionValue='code' autoFocus={true} value={selectedTopic} required />
                                    <label htmlFor="topic">Topic</label>
                                </span>
                            </div>
                            <div className="p-inputgroup mt-3">
                                <span className="p-float-label">
                                    <InputText name="subject" type='text' value={ticketInfo.subject ?? ""} id="subject" required onChange={handleChange} style={inputStyle} className='input-border' />
                                    <label htmlFor="subject">Subject</label>
                                </span>
                            </div>
                            <div className="p-inputgroup mt-3 mb-3">
                                <span className="p-float-label">
                                    <InputTextarea name='message' id="description" value={ticketInfo.message ?? ""} required onChange={handleChange} autoResize rows="3" cols="30" style={inputStyle} className='input-border' />
                                    <label htmlFor="description">Description</label>
                                </span>
                            </div>
                            {errMsg ? errMsg !== "success" ? <div className="errorstyle mb-1" style={{ color: "red" }}>{errMsg}</div> :
                                <div className="mb-1 errorstyle" style={{ color: "green" }}>Ticket Raised Successfully</div> : null}
                            <Button className="px-3 border-radius-12px" style={{ float: "right" }} loading={disableBtn} label="Submit" disabled={disableBtn} onClick={onSubmit}></Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default HelpAndSupport