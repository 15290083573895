import React, { useEffect } from "react";
import ConfigService from "../../service/configService";
import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as XLSX from "xlsx";

const MobileUser = () => {
    const [data, setData] = useState(null);
    const serviceID = localStorage.getItem("serviceId");
    const [duplicate, setDuplicate] = useState([]);

    useEffect(() => {
        const payload = {
            filter: {
                serviceId: serviceID,
            },
        };
        ConfigService.getAllowedUser(payload).then((res) => {
            setData(res);
            setDuplicate(res);
        });
    }, []);

    const locationTrackingAllowed = (item) => {
        return item.locationTrackingAllowed ? (
            <span className="flex justify-content-center align-items-center" style={{ width: "35px", height: "35px" }}>
                <i className="pi pi-check"></i>
            </span>
        ) : (
            <span className="flex justify-content-center align-items-center" style={{ width: "35px", height: "35px" }}>
                <i className="pi pi-times"></i>
            </span>
        );
    };
    const notificationAllowed = (item) => {
        return item.notificationAllowed ? (
            <span className="flex justify-content-center align-items-center" style={{ width: "35px", height: "35px" }}>
                <i className="pi pi-check"></i>
            </span>
        ) : (
            <span className="flex justify-content-center align-items-center" style={{ width: "35px", height: "35px" }}>
                <i className="pi pi-times"></i>
            </span>
        );
    };
    const selfRegistrationAllowed = (item) => {
        return item.selfRegistrationAllowed ? (
            <span className="flex justify-content-center align-items-center" style={{ width: "35px", height: "35px" }}>
                <i className="pi pi-check"></i>
            </span>
        ) : (
            <span className="flex justify-content-center align-items-center" style={{ width: "35px", height: "35px" }}>
                <i className="pi pi-times"></i>
            </span>
        );
    };

    const handleSearch = (query) => {
        let filteredReport = data.filter((item) => {
            if (item.email && item.email.includes(query) || item.mobile && item.mobile.includes(query) || item.name && item.name.toLowerCase().includes(query.toLowerCase())) {
                return true;
            }
        })
        setDuplicate(filteredReport);
    }

    const onDownload = () => {
        let Data = [];
        duplicate.map((item) => {
            let temp = {}
            for (const key in item) {
                if (key === "email" || key === "mobile" || key === "domainName" || key === "locationTrackingAllowed" || key === "notificationAllowed" || key === "selfRegistrationAllowed") temp[key.toUpperCase()] = item[key];
            }
            Data.push(temp)
        })
        let filename = "Allowed Users.xlsx";
        let ws = XLSX.utils.json_to_sheet(Data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, filename);
    }
    return (
        <div className="grid">
            <div className="col-12 card">
                <div className="flex align-item-center mt-2">
                    <InputText className="h-3rem border-solid border-primary-500  bg-black" style={{
                        borderRadius: "1.25rem",
                        border: "1px solid rgba(116, 120, 141, 0.20)",
                        background: "#FAFAFA"
                    }} placeholder="Search" onChange={(e) => { handleSearch(e.target.value) }} />
                    <div className="col-3 field-radiobutton ">
                        <Button icon="pi pi-download" label="Download" className="p-button-rounded" aria-label="Submit" onClick={onDownload} style={{backgroundColor:"#0C6291"}}/>
                    </div>
                </div>
                {data && data.length ? (
                    <DataTable value={duplicate} paginator={true} rows={6} responsiveLayout="scroll" showGridlines={true}>
                        <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" style={{ width: "100px" }} header="Email" field="email"></Column>
                        <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" style={{ width: "100px" }} header="Mobile Number" field="mobile"></Column>
                        <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" style={{ width: "100px" }} header="Domain Name" field="domainName"></Column>
                        <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" style={{ width: "100px" }} header="Location Tracking Allowed" field="locationTrackingAllowed" body={locationTrackingAllowed}></Column>
                        <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" style={{ width: "100px" }} header="Notification Allowed" field="notificationAllowed" body={notificationAllowed}></Column>
                        <Column headerClassName="dtHeaderStyle" bodyClassName="bodyStyle" style={{ width: "100px" }} header="Self Registration Allowed" field="selfRegistrationAllowed" body={selfRegistrationAllowed}></Column>
                    </DataTable>
                ) : null}
            </div>
        </div>
    );
};

export default MobileUser;
