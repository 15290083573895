import React, { useState, useEffect, useRef, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber, getCountryCallingCode } from "react-phone-number-input";
import TenantService from "../service/tenant";
import { StateContext } from '../utilities/context/context';
import { StyleInput } from './newVehTrackingWithFuel/Components';

const domainsMap = {
	"in.nextqore.com": "IN",
	"us.nextqore.com": "US"
}

const Log = () => {
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [errMsg, setErrMsg] = useState(null);
	const [disableBtn, setBtnDisabled] = useState(false);
	const [passwordType, setPasswordType] = useState("password");
	const [showPhoneInput, setShowPhoneInput] = useState(false)
	const { countryCode, countryCodeVal, fetchUserCountryCode, setCountryCodeVal } = useContext(StateContext)
	const history = useHistory();
	const inputTypeNumber = useRef(false)
	useEffect(() => {
		if (localStorage.getItem('token')) history.push("/services");

		fetchUserCountryCode();
	}, [])

	const userLogin = async (e) => {
		e.preventDefault();
		let validationRes = validateInputs();
		if (typeof validationRes === "string") {
			setErrMsg(validationRes);
		}
		else {
			setBtnDisabled(true);
			let loginDetails = {
				username: isNumber(email) ? countryCodeVal + email.toLowerCase() : email.toLowerCase(),
				password: password,
			};
			let loginResponse = await TenantService.loginUser(loginDetails);
			if (!loginResponse.success || !loginResponse.responseData) {
				setBtnDisabled(false);
				if (loginResponse.message) {
					setErrMsg(loginResponse.message);
				}
				return
			}
			if (!loginResponse || !loginResponse.responseData || !loginResponse.responseData.idToken || !loginResponse.responseData.idToken.jwtToken || !loginResponse.responseData.idToken.payload.sub) return;
			else {
				localStorage.setItem("token", loginResponse.responseData.idToken.jwtToken);
				localStorage.setItem("username", loginResponse.responseData.idToken.payload.name);
				localStorage.setItem("exp", loginResponse.responseData.idToken.payload.exp);
				localStorage.setItem("email", (loginResponse.responseData.idToken.payload.phone_number ? loginResponse.responseData.idToken.payload.phone_number : loginResponse.responseData.idToken.payload.email || ""));
				localStorage.setItem("emailId", (loginResponse.responseData.idToken.payload.email || ""));

				if (loginResponse.responseData.idToken.payload.email && (loginResponse.responseData.idToken.payload.email == "support@nextqore.com")) {
					localStorage.setItem("isSupport", true)
				}
				history.push("/services");
			}
		}
	}

	const togglePassword = (e) => {
		if (passwordType === "password") {
			setPasswordType("text")
			return;
		}
		setPasswordType("password")
	}

	const onforgotPassword = () => {
		history.push("/forgot-password");
	}
	const validateInputs = () => {
		if (!email) return "Please enter email/mobile";
		if (!password) return "please enter password";
		if (isNumber(email) && !isValidPhoneNumber(countryCodeVal + email)) return "Enter valid mobile no"
		if (email && !isNumber(email)) {
			let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			let res = emailRegex.test(email);
			if (!res) return "enter valid email";
		}
	}

	function isNumber(n) {
		if (n == "" || n == null || n == undefined) return false
		return Number(n) == n
	}

	const handleChange = (value) => {
		setEmail(value)
		if (!value.trim()) {
			setShowPhoneInput(false)
			inputTypeNumber.current = false
			return
		}
		let valIsNo = isNumber(value)
		if (valIsNo && inputTypeNumber.current === false) {
			setShowPhoneInput(true)
			inputTypeNumber.current = valIsNo
		}
		else if (!valIsNo && inputTypeNumber.current === true) {
			setShowPhoneInput(false)
			inputTypeNumber.current = false
		}
	}
	return (
		<div className="pages-body flex flex-column login-img">
			<div className="align-self-center mt-auto mb-auto">
				<div className="pages-panel card flex flex-column">
					<div className="Row-fixing">
						<svg xmlns="http://www.w3.org/2000/svg" width="77" height="83" viewBox="0 0 77 83" fill="none">
							<path d="M42.6723 0V10.0232L67.3494 24.2148V57.9249L42.6723 72.111V82.1342L76.0803 62.975V19.1647L42.6723 0Z" fill="#8CC63F" />
							<path d="M59.5097 53.3944V28.7378L38.0387 16.4086L16.5696 28.7378V53.3944L38.0387 65.7181L59.5097 53.3944Z" fill="#C8C8C8" />
							<path d="M33.408 72.111L8.73091 57.9249V24.2148L33.408 10.0232V0L0 19.1592V62.9695L33.408 82.1324V72.111Z" fill="#0C6291" />
						</svg><br /><br />
					</div>
					<div className="subText lg:px-6" style={{ color: "#0C6291" }}>Login to Your Nextqore Account</div>
					<form onSubmit={userLogin}>
						<div style={{ padding: "20px" }}>
							<div style={{ width: "300px", margin: "50px auto" }}>
								<StyleInput
									label="Phone Number"
									value={email}
									setFunction={setEmail}
								/>
							</div>
							<div style={{ width: "300px", margin: "50px auto" }}>
								<StyleInput
									label="Phone Number"
									value={password}
									setFunction={setPassword}
								/>
							</div>
							<Button style={{ borderRadius: "12px", backgroundColor: `${disableBtn ? "#6DA1BD" : "#0C6291"}` }} className="mb-3 px-3 py-3 w-full" label="LOGIN" disabled={disableBtn} onClick={userLogin}></Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}





const Login = () => {
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [errMsg, setErrMsg] = useState(null);
	const [disableBtn, setBtnDisabled] = useState(false);
	const [passwordType, setPasswordType] = useState("password");
	const [showPhoneInput, setShowPhoneInput] = useState(false)
	const [placeHolder, setPlaceHolder] = useState({
		"email": "Enter UserName or PhoneNumber",
		"password": "Password"
	})
	const { countryCode, countryCodeVal, fetchUserCountryCode, setCountryCodeVal } = useContext(StateContext)
	const history = useHistory();
	const inputTypeNumber = useRef(false)
	useEffect(() => {
		if (localStorage.getItem('token')) history.push("/services");

		fetchUserCountryCode();
	}, [])

	const userLogin = async (e) => {
		e.preventDefault();
		let validationRes = validateInputs();
		if (typeof validationRes === "string") {
			setErrMsg(validationRes);
		}
		else {
			setBtnDisabled(true);
			let loginDetails = {
				username: isNumber(email) ? countryCodeVal + email.toLowerCase() : email.toLowerCase(),
				password: password,
			};
			let loginResponse = await TenantService.loginUser(loginDetails);
			if (!loginResponse.success || !loginResponse.responseData) {
				setBtnDisabled(false);
				if (loginResponse.message) {
					setErrMsg(loginResponse.message);
				}
				return
			}
			if (!loginResponse || !loginResponse.responseData || !loginResponse.responseData.idToken || !loginResponse.responseData.idToken.jwtToken || !loginResponse.responseData.idToken.payload.sub) return;
			else {
				localStorage.setItem("token", loginResponse.responseData.idToken.jwtToken);
				localStorage.setItem("username", loginResponse.responseData.idToken.payload.name);
				localStorage.setItem("exp", loginResponse.responseData.idToken.payload.exp);
				localStorage.setItem("email", (loginResponse.responseData.idToken.payload.phone_number ? loginResponse.responseData.idToken.payload.phone_number : loginResponse.responseData.idToken.payload.email || ""));
				localStorage.setItem("emailId", (loginResponse.responseData.idToken.payload.email || ""));

				if (loginResponse.responseData.idToken.payload.email && (loginResponse.responseData.idToken.payload.email == "support@nextqore.com")) {
					localStorage.setItem("isSupport", true)
				}
				history.push("/services");
			}
		}
	}

	const togglePassword = (e) => {
		if (passwordType === "password") {
			setPasswordType("text")
			return;
		}
		setPasswordType("password")
	}

	const onforgotPassword = () => {
		history.push("/forgot-password");
	}
	const validateInputs = () => {
		if (!email) return "Please enter email/mobile";
		if (!password) return "please enter password";
		if (isNumber(email) && !isValidPhoneNumber(countryCodeVal + email)) return "Enter valid mobile no"
		if (email && !isNumber(email)) {
			let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			let res = emailRegex.test(email);
			if (!res) return "enter valid email";
		}
	}

	function isNumber(n) {
		if (n == "" || n == null || n == undefined) return false
		return Number(n) == n
	}

	const handleChange = (value) => {
		setEmail(value)
		if (!value.trim()) {
			setShowPhoneInput(false)
			inputTypeNumber.current = false
			return
		}
		let valIsNo = isNumber(value)
		if (valIsNo && inputTypeNumber.current === false) {
			setShowPhoneInput(true)
			inputTypeNumber.current = valIsNo
		}
		else if (!valIsNo && inputTypeNumber.current === true) {
			setShowPhoneInput(false)
			inputTypeNumber.current = false
		}
	}
	const style = {
		display: "flex", // 358px / 16 = 22.375rem
		padding: "0.75rem 1.25rem", // 12px / 16 = 0.75rem, 20px / 16 = 1.25rem
		alignItems: "center",
		width: "22.75",
		gap: "0.625rem", // 10px / 16 = 0.625rem
		borderRadius: "0.75rem", // 12px / 16 = 0.75rem
		border: "1px solid rgba(12, 98, 145, 0.60)",
		background: "#FFF",
	};

	return (
		<div className="pages-body flex flex-column login-img ">
			<div className="align-self-center mt-auto mb-auto ">
				<div className="pages-panel card flex flex-column">
					<div className="Row-fixing">
						<svg xmlns="http://www.w3.org/2000/svg" width="77" height="83" viewBox="0 0 77 83" fill="none">
							<path d="M42.6723 0V10.0232L67.3494 24.2148V57.9249L42.6723 72.111V82.1342L76.0803 62.975V19.1647L42.6723 0Z" fill="#8CC63F" />
							<path d="M59.5097 53.3944V28.7378L38.0387 16.4086L16.5696 28.7378V53.3944L38.0387 65.7181L59.5097 53.3944Z" fill="#C8C8C8" />
							<path d="M33.408 72.111L8.73091 57.9249V24.2148L33.408 10.0232V0L0 19.1592V62.9695L33.408 82.1324V72.111Z" fill="#0C6291" />
						</svg><br /><br />
					</div>
					<div className="col-12 inline-flex justify-content-center" style={{
						color: "#0C6291",
						textAlign: "center",
						fontFamily: "Inter, sans-serif", // Adding a fallback font for better compatibility
						fontSize: "1rem", // 16px / 16 = 1rem
						fontStyle: "normal",
						fontWeight: 500,
						lineHeight: "1.25rem", // 20px / 16 = 1.25rem
					}}>Login to Your Nextqore Account</div>
					<form onSubmit={userLogin} autoComplete="off">
						<div className="input-panel flex flex-column px-3 mt-5" >
							<div className="p-inputgroup">
								{!showPhoneInput && <span className="p-inputgroup-addon" style={{ borderTopLeftRadius: "0.75rem", borderBottomLeftRadius: "0.75rem", border: "1px solid rgba(12, 98, 145, 0.60)", borderRight: "0px" }}>
									<i className="pi pi-envelope"></i>
								</span>}
								<span className="p-float-label">
									{showPhoneInput && <PhoneInput id="userPhoneInput" style={{ ...style, borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px", borderTopRightRadius: 0, borderBottomRightRadius: 0, maxWidth: "7em", borderRight: "0px", }} className="p-inputtext p-component p-filled w-full" value={countryCodeVal} onChange={setCountryCodeVal} international defaultCountry={countryCode} />}
									<InputText  style={{ ...style, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: "0px", }} type="text" id="inputgroup1" autoFocus={true} onChange={(e) => handleChange(e.target.value)} onFocus={() => setPlaceHolder({ ...placeHolder, email: "" })} placeholder={placeHolder["email"]} />
									<label>Email Or Phone Number</label>
								</span>
							</div>
							<div className="p-inputgroup mt-4 mb-3">
								<span className="p-inputgroup-addon" style={{ borderTopLeftRadius: "0.75rem", borderBottomLeftRadius: "0.75rem", border: "1px solid rgba(12, 98, 145, 0.60)", borderRight: "0px" }}>
									<i className="pi pi-lock"></i>
								</span>
								<span className="p-float-label">
									<InputText autoComplete="off" style={{ ...style, borderLeft: "0px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0", borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: "0px" }} type={passwordType} id="inputgroup2" onChange={(e) => setPassword(e.target.value)} onFocus={() => setPlaceHolder({ ...placeHolder, "password": "" })} />
									<label>Password</label>

									<div className="p-inputgroup-addon" onClick={togglePassword} style={{ borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem", border: "1px solid rgba(12, 98, 145, 0.60)", borderLeft: "0px" }}>
										{passwordType === "password" ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
									</div>
								</span>
							</div>
							<div>
								<div className='blue-text mb-2 mt-1' style={{
									color: "#0C6291",
									textAlign: "end",
									fontFamily: "Inter, sans-serif",
									fontSize: "1rem", 
									fontStyle: "normal",
									fontWeight: 400,
									cursor:"pointer",
									lineHeight: "1.25rem", // 20px / 16 = 1.25rem
								}} onClick={() => onforgotPassword()}>Forgot Password ?</div>
						</div>
						<div className="errorstyle mb-1 col-12" >{errMsg}</div>
						<Button style={{ borderRadius: "12px", backgroundColor: `${email && password ? "#0C6291" : "#6DA1BD"}` }} className="mb-3 px-3 py-3 w-full" label="LOGIN" disabled={disableBtn} onClick={userLogin}></Button>
				</div>

			</form>
		</div>
			</div >
		</div >
	)
}

export default Login;