import React, { useState, useEffect, useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { ProgressSpinner } from "primereact/progressspinner";

const GeneralBarChart = React.memo(({ chartData, selectedvalueType, legend, height, dontShow, noScroll }) => {
    const [state, setState] = useState(null);
    const barChartOptions = useMemo(() => {
        if (!chartData || !chartData.data || !chartData.data.length) {
            console.error("Invalid chartData format:", chartData);
            return {};
        }

        const dynamicYAxes = chartData && chartData.data ? chartData.data.slice(0, 2).map((item, index) => ({
            title: {
                text: item.yAxisTitle ? item.yAxisTitle : item["name"] ? item["name"] : "",
                style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                },
            },
            labels: {
                format: `{value}${item.unit || ""}`, // Add unit dynamically
            },
            opposite: index % 2 !== 0, // Alternate axes placement
        })) : [];

        return {
            chart: {
                type: "column",
                height: height ?? 180,
            },
            title: {
                text: "",
                align: "center",
                style: {
                    fontSize: "16px",
                    fontWeight: "bold",
                },
            },
            xAxis: {
                categories: chartData.category || [],
                title: {
                    text: chartData.text || "",
                },
            },
            yAxis: dynamicYAxes,
            plotOptions: {
                series: {
                    borderRadius: 5,
                    pointPadding: 0.1,
                    groupPadding: 0.2,
                    grouping: true,
                    pointWidth: 20,
                    dataLabels: {
                        enabled: true,
                        format: dontShow ? "" : `{y}${selectedvalueType?.["abbr"] || ""}`,
                        style: {
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#000",
                        },
                    },
                },
            },
            legend: {
                align: "center",
                verticalAlign: "top",
                layout: "horizontal",
                itemStyle: {
                    fontSize: "12px",
                },
            },
            series: chartData.data.map((item, index) => {
                return {
                    showInLegend: legend ?? false,
                    ...item,
                    yAxis: index < 2 ? index : 0,
                }
            })

        };
    }, [chartData, selectedvalueType]);

    useEffect(() => {
        if (!noScroll) barChartOptions["chart"]["scrollablePlotArea"] = {
            minWidth: 800,
            scrollPositionX: 1
        }
        setState(barChartOptions);
    }, [barChartOptions]);

    if (!state) {
        return <ProgressSpinner
            className="col-12 grid justify-content-center align-items-center mt-5 ml-5"
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
        />
    }

    return <HighchartsReact highcharts={Highcharts} options={state} />;
});

export default GeneralBarChart;
