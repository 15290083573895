import React, { useEffect, useState } from 'react'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from 'moment'
import service from '../../service/apiService'
import { channels } from './ReproService'

const ListingTotal = () => {
    const serviceId = localStorage.getItem("serviceId")
    const [listingTotal, setListingTotal] = useState([])

    useEffect(() => {
        const start_date = moment().subtract(10, "days").format("YYYY-MM-DD")
        const end_date = moment().format("YYYY-MM-DD")

        const payload = {
            filter: { "date": { "$gte": start_date, "$lte": end_date } },
            projection: { "_id": 0 },
            sort: [["date", -1]]

        }
        const channelWiseMapData = {}
        service.httpDBService(payload, serviceId, "nq-dashboard-b2c-listing-total").then((res) => {
            if (!res || (res && res.length)) {
                res.forEach((item) => {
                    let date_key = item.date
                    delete item.date
                    let keys = Object.keys(item)
                    keys.forEach(key => {
                        let fidx = channels.findIndex((ch) => key.toLocaleLowerCase().includes(ch.toLowerCase()))
                        if (fidx > -1) {
                            let channel = channels[fidx]
                            let newkey = key.toLowerCase().replace((channel.toLowerCase() + "_"), "")
                            let mapKey = date_key + "_" + channel
                            if (mapKey in channelWiseMapData) channelWiseMapData[mapKey][newkey] = item[key]
                            else channelWiseMapData[mapKey] = { "date": date_key, [newkey]: item[key], "ingestion_total": item["ingestion_total"], "channel": channel }
                        }
                    })
                })
                const listingTotalData = Object.values(channelWiseMapData)
                setListingTotal(listingTotalData)
            }
        })
    }, [])

    const listTotalHeaderTemplate = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="no-grid-lines bg-transparent" header="" colSpan={3} className="text-center" field=""></Column>
                <Column headerClassName="blue-bg" header="Eligibilty" colSpan={2} className="text-center" field=""></Column>
                <Column headerClassName="blue-bg" header="Listing" colSpan={2} className="text-center" field=""></Column>
                <Column style={{backgroundColor: "#80808059"}} header="Listing time (hrs)" colSpan={3} className="text-center" field=""></Column>
            </Row>
            <Row>
                <Column headerClassName="blue-bg" header="Date" className="text-center" field="" ></Column>
                <Column headerClassName="blue-bg" header="Channel" className="text-center" field="" ></Column>
                <Column headerClassName="blue-bg" header="Ingested" className="text-center" field="" ></Column>
                <Column headerClassName="blue-bg" header="Failure" className="text-center" field="" ></Column>
                <Column headerClassName="blue-bg" header="Success" className="text-center" field="" ></Column>
                <Column headerClassName="blue-bg" header="Failure" className="text-center" field="" ></Column>
                <Column headerClassName="blue-bg" header="Success" className="text-center" field="" ></Column>
                <Column style={{backgroundColor: "#80808059"}} header="Min" className="text-center" field="" ></Column>
                <Column style={{backgroundColor: "#80808059"}} header="Max" className="text-center" field="" ></Column>
                <Column style={{backgroundColor: "#80808059"}} header="Avg" className="text-center" field="" ></Column>
            </Row>
        </ColumnGroup>
    )

    return (
        <>
            <DataTable value={listingTotal} responsiveLayout="scroll" rowGroupMode="rowspan" groupRowsBy="date" showGridlines headerColumnGroup={listTotalHeaderTemplate}>
                <Column className="text-center" field='date'></Column>
                <Column className="text-center" field="channel"></Column>
                <Column className="text-center" field="ingestion_total"></Column>
                <Column className="text-center" field="eligible_fail"></Column>
                <Column className="text-center" field="eligible_succ" ></Column>
                <Column className="text-center" field="listing_fail" ></Column>
                <Column className="text-center" field="listing_succ" ></Column>
                <Column  bodyStyle={{backgroundColor: "#80808059"}} className="text-center" field="min" ></Column>
                <Column  bodyStyle={{backgroundColor: "#80808059"}} className="text-center" field="max" ></Column>
                <Column   bodyStyle={{backgroundColor: "#80808059"}} className="text-center" field="avg" ></Column>
            </DataTable>
        </>
    )
}

export default ListingTotal