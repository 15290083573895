import React, { useState, useEffect, useContext, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { StateContext } from '../utilities/context/context';
import TenantService from "../service/tenant";
import { Toast } from 'primereact/toast';
import { ShowLogo, style } from './newVehTrackingWithFuel/Components';

const ConfirmSignup = () => {
    const [mobile, setMobile] = useState("");
    const [code, setCode] = useState(null);
    const [errMsg, setErrMsg] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const history = useHistory();
    const { countryCode, countryCodeVal, fetchUserCountryCode, setCountryCodeVal } = useContext(StateContext)
    const toast = useRef();

    useEffect(() => {
        fetchUserCountryCode()
    }, [])
    const validateInputs = () => {
        if (!mobile) return "Please enter the phone number";
        if (!code) return "Please enter the verification code";
        if (mobile && !isValidPhoneNumber(mobile)) return "Invalid phone number"
    }

    const onConfirm = async () => {
        setErrMsg("")
        let validationRes = validateInputs();

        if (typeof validationRes === "string") {
            setErrMsg(validationRes);
        }
        else {
            setDisableBtn(true);
            let userData = {
                username: countryCodeVal+mobile,
                confirmationCode: code
            };

            let confirmSignupRes = await TenantService.confirmSignup(userData)

            if (confirmSignupRes && !Object.keys(confirmSignupRes.responseData).length && !confirmSignupRes.success) {
                setErrMsg(confirmSignupRes.message.message)
                setDisableBtn(false);
                return
            }
            toast.current.show({ severity: 'success', content: "User Confirmed" });
            setTimeout(() => {
                history.push("/login");
            }, 2000)

        }
    }

    return (
        <div className="pages-body flex flex-column login-img">
            <Toast ref={toast} />
            <div className=" align-self-center  mt-auto mb-auto lg:col-3 col-12">
                <div className=" card flex flex-column align-items-center col-12" style={{ color: "#616161" }}>
                    <ShowLogo name={"Enter Your Phone No and Verification Code"}/>

                    <div className="mb-3 px-3 p-inputgroup mt-3">

                        <span className='p-float-label '>
                        <PhoneInput id="userPhoneInput" style={{ ...style, borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px", borderTopRightRadius: 0, borderBottomRightRadius: 0, maxWidth: "7em", borderRight: "0px", }} className="p-inputtext p-component p-filled w-full" value={countryCodeVal} onChange={setCountryCodeVal} international defaultCountry={countryCode} />
                        <InputText style={{ ...style, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: "0px", }} type="text" id="inputgroup1" autoFocus={true} onChange={setMobile}  />
                        <label className='ml-8'>Phone</label>
                        </span>
                    </div>
                    <div className="mb-3  p-inputgroup col-12">
                        <span className='p-float-label '>
                            <InputText style={style} className="col-12" type="text" id="code" onChange={(e) => setCode(e.target.value)} />
                            <label className='inline-flex justify-content-center align-items-center'>Verification Code</label>
                        </span>
                    </div>
                    <div className="errorstyle px-3" >{errMsg}</div>
                    <div className="mb-3 col-12">
                        <Button className="login-button mb-3 px-3 col-12" style={{ borderRadius: "12px", backgroundColor: `${mobile  ? "#0C6291" : "#6DA1BD"}`,padding:"0.75rem" }} label="Verify and Create Password" disabled={disableBtn} onClick={onConfirm}></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmSignup;
