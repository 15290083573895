import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber, getCountryCallingCode } from "react-phone-number-input";
import { InputText } from "primereact/inputtext";
import HighchartsReact from "highcharts-react-official";
import { Highcharts } from "highcharts/modules/exporting";
import { chartHeadingStyle } from "../../utilities/constant";
import moment from "moment";

export const StyleInput = ({ label, value, setFunction, type, phoneInput, defaultCountry, changePhone, onCountryChange }) => {
    return (
        <span className="m-0 p-0">
            <div className="input-container ">
                {phoneInput && <PhoneInput
                    inputClass="phone-input-field"
                    style={{
                        width: "100%",
                        borderRadius: "12px",
                        border: "1px solid rgba(116, 120, 141, 0.20)",
                        background: "#FFF",
                        fontSize: "14px",
                    }}
                    onCountryChange={(e) => {
                        console.log()
                    }}
                    className={`input-field ${value ? "filled" : ""}`}
                    value={value}
                    onChange={(e) => { setFunction(e) }}
                    placeholder=""
                    defaultCountry={defaultCountry}
                />}
                {!phoneInput && <InputText
                    style={{
                        borderRadius: "12px",
                        border: "1px solid rgba(116, 120, 141, 0.20)",
                        background: "#FFF"
                    }}
                    type={type}
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    id={label.replace(/\s+/g, "-").toLowerCase()}
                    className={`input-field ${value ? "filled" : ""}`}
                    value={value}
                    onCountr
                    defaultCountry={defaultCountry}
                    onChange={(e) => {
                        console.log("====", e.target?.value)
                        setFunction(e.target?.value)
                    }}
                    required
                />}
                {!phoneInput && <label
                    htmlFor={label.replace(/\s+/g, "-").toLowerCase()} // Associate the label with the input
                    className="input-label"
                >
                    {label}
                </label>}
            </div>
        </span>
    );
};

export const style = {
    display: "flex", // 358px / 16 = 22.375rem
    padding: "0.75rem 1.25rem", // 12px / 16 = 0.75rem, 20px / 16 = 1.25rem
    alignItems: "center",
    width: "22.75",
    gap: "0.625rem", // 10px / 16 = 0.625rem
    borderRadius: "0.75rem", // 12px / 16 = 0.75rem
    border: "1px solid rgba(12, 98, 145, 0.60)",
    background: "#FFF",
};

export const ShowLogo = ({ name }) => {
    return <><div className="Row-fixing mt-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="77" height="83" viewBox="0 0 77 83" fill="none">
            <path d="M42.6723 0V10.0232L67.3494 24.2148V57.9249L42.6723 72.111V82.1342L76.0803 62.975V19.1647L42.6723 0Z" fill="#8CC63F" />
            <path d="M59.5097 53.3944V28.7378L38.0387 16.4086L16.5696 28.7378V53.3944L38.0387 65.7181L59.5097 53.3944Z" fill="#C8C8C8" />
            <path d="M33.408 72.111L8.73091 57.9249V24.2148L33.408 10.0232V0L0 19.1592V62.9695L33.408 82.1324V72.111Z" fill="#0C6291" />
        </svg><br /><br />
    </div>
        <div className="col-12 inline-flex justify-content-center m-0" style={{
            color: "#0C6291",
            textAlign: "center",
            fontFamily: "Inter, sans-serif", // Adding a fallback font for better compatibility
            fontSize: "1rem", // 16px / 16 = 1rem
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "1.25rem", // 20px / 16 = 1.25rem
        }}>{name}</div></>
}

export const GeneralPieChart = ({ chartData }) => {
    chartData.data = chartData.data.map((item) => {
        return {
            category: item.name,
            name: item.name + " : " + item.y + " vehicles",
            y: item.y
        }
    })

    const option = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
        },
        title: {
            text: chartData.title || "",
            style: chartHeadingStyle,
            align: "left",
        },
        tooltip: {
            pointFormat: "{series.category}</b>",
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: false, // Hide data labels
                },
                showInLegend: true, // Show legend initially
            },
        },
        legend: {
            align: 'right', // Align the legend to the right
            verticalAlign: 'middle', // Vertically center the legend
            layout: 'vertical', // Vertical legend layout
            width: '30%', // Take up 25% of the width
            itemStyle: {
                fontSize: '14px',
                marginTop: "12px"
            },
            itemMarginTop: 12, // Add space between legend items
            symbolPadding: 10,
        },
        series: [
            {
                name: "Total",
                colorByPoint: true,
                data: chartData.data || [], // Ensure data is defined
            },
        ],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 600 // Apply this rule if screen width is 600px or less
                },
                chartOptions: {
                    legend: {
                        enabled: false // Disable the legend on small screens
                    },
                    dataLabels: {
                        enabled: true

                    }
                }
            }]
        }
    };

    option.series[0].data = chartData.data;
    option.title.text = chartData.title;

    console.log("option", option);

    return (
        <div className="card grid align-items-center">
            <div className="col-12">
                <HighchartsReact highcharts={Highcharts} options={option} />
            </div>
        </div>
    );
};

export const drivingScoreCard = (item, onClickFunc, selectedItem, showSpeed, arr) => {
    const tyle = {
        cursor: "pointer",
        backgroundColor: `${item.thingName === selectedItem ? "#EDF5DD" : "var(---Fill-1-, #FFF)"}`,
        border: `${item.thingName === selectedItem ? "1px solid #8CC63F" : ""}`,
    };

    if (arr && arr.length < 4 && window.innerWidth > 761) tyle["maxHeight"] = "9rem";

    const src = `${process.env.REACT_APP_IMAGE_STATIC_URL}/${localStorage.getItem("serviceId")}/${item.thingName}.jpg`;

    const fuelLevel = (item && typeof item.fuellevel === "number")
        ? item.fuellevel.toFixed(2)
        : (item && typeof item.fuelLevelCalibrated === "number") ? item.fuelLevelCalibrated.toFixed(2) : "NA";

    return (
        <div
            key={item.thingName}
            className="drivingScoreCard col-12 grid justify-content-between mb-4 line-height-1"
            onClick={(e) => onClickFunc(e)}
            style={tyle}
        >
            <div
                className="lg:col-3 col-12 m-0 p-0"
                style={{
                    borderRadius: "8px",
                    border: "1px solid rgba(116, 120, 141, 0.20)",
                    height: "4.5rem",
                    width: "4.5rem",
                }}
            >
                <img
                    src={src}
                    className="col-12"
                    onError={e=>{e.target.src="/images/car-solid.svg" ;e.onerror=null}}
                    style={{
                        objectFit: "fit",
                        width: "100%",
                        height: "4.5rem",
                        width: "4.5rem",
                    }}
                />
            </div>
            <div
                className="flex flex-column lg:col-10 col-12"
                style={{ color: "#5E6771", fontWeight: "500" }}
            >
                <div>
                    <div className="m-0">
                        <div className="m-0 grid align-items-center justify-content-between">
                            {item.vehicleNumber || item.plateNo ? <p className="m-0">
                                License Plate No:
                                <span
                                    style={{ color: "#1D2129", fontWeight: "500" }}
                                    className="font-bold"
                                >
                                    {item.vehicleNumber || item.plateNo}
                                </span>
                            </p> : item.alias ? <p className="m-0">
                                <span
                                    style={{ color: "#1D2129", fontWeight: "500" }}
                                    className="font-bold"
                                >
                                    {item.alias}
                                </span>
                            </p> : ""}
                            <p className="m-0" style={{ fontWeight: "500" }}>
                                Ignition:
                                <span
                                    className="font-bold"
                                    style={{ color: `${item.ignition ? "#8CC63F" : "#F67E6D"}` }}
                                >
                                    {item.ignition ? "On" : "Off"}
                                </span>
                            </p>
                        </div>
                        <p className="m-0">
                            Make:
                            <span
                                style={{ color: "#1D2129" }}
                                className="font-bold"
                            >
                                {item.make}
                            </span>
                        </p>
                    </div>
                    <div className="col-11 inline-flex justify-content-between p-0">
                        <p
                            className="m-0 font-bold"
                            style={{ color: "#1D2129", fontWeight: "500" }}
                        >
                            <span style={{ color: "#5E6771", fontWeight: "500" }}>Model:</span>{" "}
                            {item.model}
                        </p>
                    </div>
                </div>
            </div>
            <p className="col-12 inline-flex justify-content-between">
                <span style={{ color: "#2A9D8F", fontWeight: "500" }}>
                    <span style={{ color: "#5E6771", fontWeight: "500" }}>Last Updated:</span>{" "}
                    {moment(item.timestamp).format("MMM Do YY, h:mm a")}
                </span>
                {<span style={{ color: "#5E6771", fontWeight: "500" }}>
                    Fuel Level:
                    <span
                        className="font-bold"
                        style={{ color: "#8CC63F", fontWeight: "500" }}
                    >
                        {fuelLevel} Ltr
                    </span>
                </span>}
                {showSpeed && (
                    <span style={{ color: "#5E6771", fontWeight: "500" }}>
                        Speed:
                        <span
                            className="font-bold"
                            style={{ color: "#8CC63F", fontWeight: "500" }}
                        >
                            {item.speed}
                        </span>
                    </span>
                )}
            </p>
        </div>
    );
};


export const vehicleStatusCard = (heading, count, iconColour, iconBack, smScreenWidth) => {
    console.log("-------", `${smScreenWidth} col-12`)
    return (
        <div className={`lg:col-3 col-12 `}>
            <div className='grid card  vehicleStatusCard w-full ' style={{ border: `1px solid ${iconColour}` }}>
                <div className='col-12 flex  flex-wrap justify-content-between align-items-center'>
                    <h5 className='col-12 py-0 mt-0'>{heading}</h5>
                    <p style={{ fontSize: "40px", fontWeight: "600", color: "#1D2129" }}>{count}</p>
                    <i className="pi pi-car col-2 py-3 inline-flex justify-content-center" style={{ color: `${iconColour}`, backgroundColor: `${iconBack}`, borderRadius: "50%" }}></i>
                </div>
            </div>
        </div>
    );
};
