
export const defaultChart = {
    pie: {
        chart: {
            type: 'pie',
            custom: {},
        },
        title: {
            text: "",
            align: "left",
            style: {
                fontSize: '16px',
                color: '#333333',
                fontWeight: 'bold',
                fontFamily: 'Arial, sans-serif',
            },
        },
        subtitle: {
            text: ""
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        legend: {
            //shows index of the chart
            enabled: true
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        click: () => { },
                    },
                },
            },
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
                size: '80%',
                innerSize: "",
            },
        },
        series: [],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 600
                },
                chartOptions: {

                    dataLabels: {
                        enabled: true

                    }
                }
            }]
        }
    },
    column: {
        chart: {
            type: "column",
            scrollablePlotArea: {
                minWidth: 800,  // Minimum width before scrolling kicks in
                scrollPositionX: 1
            },
        },
        title: {
            text: "",
            align: "left",
            style: {
                fontSize: "16px",
                fontWeight: "bold",
            },
        },
        subtitle: {
            text: ""
        },
        xAxis: {},
        yAxis: {},
        plotOptions: {
            column: {
                borderRadius: 5,
                pointPadding: 0.1,
                groupPadding: 0.2,
                grouping: true,
                pointWidth: 20,
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#5E6771",
                    },
                },
            },
            series: {
                point: {
                    events: {
                        click: () => { },
                    },
                },
            }
        },
        legend: {
            align: "center",
            verticalAlign: "top",
            layout: "horizontal",
            itemStyle: {
                fontSize: "12px",
            },
        },
        series: []

    }
}
class GeneralCharts {

    static pieChart = ({ title = "", subtitle = "", seriesData = null, customChart = null, height = null, onClickFunction = null, isPie = false, legendPos = null, legendLayout = null, legendVerticalAlign = null }) => {
        let chartObj = JSON.parse(JSON.stringify(defaultChart.pie))
        chartObj = { ...chartObj, ...customChart }
        chartObj.title.text = title

        if (seriesData) chartObj.series = seriesData
        if (!isPie) chartObj.plotOptions.pie.innerSize = "60%"
        if (subtitle) chartObj.subtitle.text = subtitle
        if (onClickFunction) chartObj.plotOptions.series.point.events.click = onClickFunction
        if (height) chartObj.chart.height = height
        if (legendPos) chartObj.legend = calculateLegendPos(legendPos)
        if (legendLayout) chartObj.legend.layout = legendLayout;
        if (legendLayout) chartObj.legend.verticalAlign = legendVerticalAlign;
        return chartObj
    }

    static barChart = ({ title = "", subtitle = "", xTitle = "", yTitle = "", categories = null, seriesData, customChart, height = null, onClickFunction = null, dataLabels = null, scrollablePlotArea = null }) => {
        let chartObj = JSON.parse(JSON.stringify(defaultChart.column))
        chartObj = { ...chartObj, ...customChart }
        chartObj.title.text = title

        if (seriesData) chartObj.series = seriesData
        if (subtitle) chartObj.subtitle.text = subtitle
        if (onClickFunction) chartObj.plotOptions.series.point.events.click = onClickFunction
        if (height) chartObj.chart.height = height
        if (yTitle) chartObj.yAxis.title = { text: yTitle }
        if (xTitle) chartObj.xAxis.title = { text: xTitle }
        if (categories) chartObj.xAxis.categories = categories
        if (dataLabels === false) chartObj.plotOptions[chartObj.chart.type].dataLabels.enabled = false
        if (scrollablePlotArea) chartObj.chart.scrollablePlotArea.minWidth = scrollablePlotArea
        return chartObj
    }
}

const calculateLegendPos = (align, layout, verticalAlign) => {
    let indexStyle = {}
    switch (align) {
        case 'top':
            indexStyle = {
                align: 'center',
                verticalAlign: 'top',
                layout: 'horizontal',
                itemMarginTop: 10
            };
            break;
        case 'left':
            indexStyle = {
                align: 'left',
                verticalAlign: 'bottom',
                layout: 'vertical',
                itemMarginTop: 10
            };
            break;
        case 'right':
            indexStyle = {
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical',
                itemMarginTop: 10
            };
            break;
        default:
            indexStyle = {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal'
            };
    }
    return indexStyle
}
export default GeneralCharts