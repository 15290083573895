import axios from "axios";

export class Employee {
    constructor() {
        this.token = localStorage.getItem("token");
        this.headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`,
        };
    }
    getGeoFence = async (serviceID) => {
        const res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/docdb/" + serviceID + "/nq-geo-fences", {filter: {}});
        return res.data;
    };
    addGeoFence = async (payload) => {
        const res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/geofence", payload, { headers: this.headers });
        return res.data;
    };
    getLatestRecord = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-location-meta", payload);
        return res.data;
    };

    getEmployeeShift = async (serviceID) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-shift-masters", {});
        return res.data;
    };
    getAllEmployee = async (serviceID) => {
        const payload = {
            serviceId: serviceID,
        };
        const res = await axios.post(process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL + "/get-employee-metadata", payload, { headers: this.headers });
        return res.data;
    };
    getLocationData = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-location-history", payload, { headers: this.headers });
        return res.data;
    };
    getEmployeeLocationHistory = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-location-history", payload || {});
        return res.data;
    };
    getManpower = async (serviceID, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-geo-events", payload, {});
        return res.data;
    };
    getManpowerData = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-geo-events", payload, {});
        return res.data;
    };
    getGeoEvents = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-geo-events", payload, {});
        return res.data;
    };

    getLocationAggrigation = async (serviceID, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-location-history", payload);
        return res.data;
    };

    getTicketCountByStatus = async (serviceID, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-tickets", payload);
        return res.data;
    };

    getTroubleTickets = async (serviceID, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-ticket-daily", payload);
        return res.data;
    };

    getTicketTimings = async (serviceID, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-tickets-technicianwise", payload);
        return res.data;
    };
    
    getEmpAttendanceCount = async (serviceID, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-location-history", payload);
        return res.data;
    }

    getAssignedTicketTillDate = async (serviceID, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-tickets", payload);
       return res.data;
    }

    getTodayClosedTicket = async (serviceID, payload) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-tickets-technicianwise", payload);
        return res.data;
    }

    getTicketsTravelled = async (serviceID, payload) => {
        const res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-tickets-technician-tracking", payload);
        return res.data;
    }

    getMissingSessions=async(serviceId,payload)=>{
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-location-data-missing-sessions",payload);
        return res.data;
    }
    checkGeoFences = async (serviceID) => {
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceID + "/nq-geo-fences", {filter: {}, limit:1});
        return res.data;
    };
}
