import React, { useState, useRef, useEffect } from "react";
import { mapKey } from "../../utilities/constant";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Dialog } from "primereact/dialog";
import LocationStore from "./LocationStore";
import { Toast } from "primereact/toast";
import { Employee } from "../../service/Employee";
import axios from "axios";
import { convertValue } from "../../utilities/unitConversion";
import { map } from "highcharts";

const svgMarkup =
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#ef3d49; stroke:none;" d="M15 28L17 28C19.368 24.4869 21.95 20.9627 23.5208 17C31.413 -2.9091 1.1202 -3.05081 8.2716 16C9.88256 20.2915 12.4852 24.1989 15 28z"/><path style="fill:#ef2121; stroke:none;" d="M15 5L16 6L15 5z"/><path style="fill:#ffffff; stroke:none;" d="M13.7461 7.03241C10.2098 9.89313 14.9493 15.6823 18.3927 12.3781C21.696 9.20841 17.2399 4.20618 13.7461 7.03241z"/><path style="fill:#ff166b; stroke:none;" d="M12 7L13 8L12 7z"/><path style="fill:#36d1ba; stroke:none;" d="M5 27L5 29C11.8299 31.882 20.17 31.882 27 29L27 27L20 25L20 27L24 27L24 28L8 28L8 27L12 27L12 25L5 27z"/></svg>';

function GeoFence() {
    const toast = useRef(null);
    const mapRef = React.createRef();
    const [geoFence, setGoeFence] = useState(null);
    const [maps, setMaps] = useState(null);
    const [myposition, setMyposition] = useState(null);
    const [displayBasic2, setDisplayBasic2] = useState(false);
    const [position, setPosition] = useState("center");
    const serviceDetails = JSON.parse(localStorage.getItem("service"));
    const serviceId = localStorage.getItem("serviceId");
    const employee = new Employee();

    const dialogFuncMap = {
        displayBasic2: setDisplayBasic2,
    };

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const H = window.H;
    const platform = new H.service.Platform({
        apikey: mapKey,
    });
    const defaultLayers = platform.createDefaultLayers();
    const getGeoFence = () => {
        employee.getGeoFence(serviceId).then((res) => {
            setGoeFence(res);
        });
    };
    useEffect(async () => {
        let zip = serviceDetails.location.zip;
        const res = await axios.get("https://geocode.search.hereapi.com/v1/geocode?q=" + zip + "&apiKey=" + mapKey);
        setMyposition(res.data.items[0].position);
        getGeoFence();
    }, []);

    useEffect(() => {
        if (myposition) {
            const map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
                center: myposition,
                zoom: 12,
                pixelRatio: window.devicePixelRatio || 1,
            });
            const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
            const ui = H.ui.UI.createDefault(map, defaultLayers);
            setMaps(map);
        }
    }, [myposition]);

    useEffect(() => {
        if (geoFence && geoFence.length > 0) {
            maps.setCenter({ lat: geoFence[0].lat, lng: geoFence[0].lon });
            maps.removeObjects(maps.getObjects());
            geoFence.forEach((item) => {
                let circle = new H.map.Circle({ lat: item.lat, lng: item.lon }, item.radius);
                maps.addObject(circle);
            });
        }
    }, [geoFence]);

    const saveGeoFence = (data) => {
        let requestBody = { serviceId: serviceId, ...data };
        employee.addGeoFence(requestBody).then((res) => {
            toast.current.show({ severity: "success", summary: "Successfull", detail: "Geo Fence added", life: 3000 });
            getGeoFence();
            onHide("displayBasic2");
        });
    };

    const tableData = [];

    const tableHeader = (
        <ColumnGroup>
            <Row>
                <Column header="Site Name" className="dtHeaderStyle"></Column>
                <Column header="GeoFence Category" className="dtHeaderStyle"></Column>
                <Column header="Latitude/Longitude" className="dtHeaderStyle"></Column>
                <Column header="Radius" className="dtHeaderStyle"></Column>
            </Row>
        </ColumnGroup>
    );

    const Location = (value) => {
        return (
            <p>
                {parseFloat(value.lat).toFixed(5)}/{parseFloat(value.lon).toFixed(5)}
            </p>
        );
    };

    const radiusConvert = (value) => {
        let radius = value.radius;
        if (serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US") {
            return convertValue(radius, "breadth", "FPS", "FPS", false);
        } else {
            return convertValue(radius, "breadth", "MKS", "MKS", false);
        }
    };

    return (
        <div className="col-12">
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12 lg:col-12 flex justify-content-end">
                    <Button aria-label="Save" onClick={() => onClick("displayBasic2")} style={{borderRadius:"0.3725rem",border:"1px solid #2A769E",background:"white",color:"#2A769E"}}>
                        <i className="pi pi-plus mr-1" />Add GeoFence Location
                    </Button>
                </div>

                <div className="col-12 lg:col-6">
                    <div className="card p-0">
                        <div
                            ref={mapRef}
                            style={{
                                width: "100%",
                                height: "76vh",
                                overflow: "hidden",
                            }}
                        ></div>
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <Dialog header="Add GeoFence Location" visible={displayBasic2} breakpoints={{ "960px": "95vw" }} style={{ width: "75vw" }} onHide={() => onHide("displayBasic2")}>
                        <LocationStore saveGeoFence={saveGeoFence} />
                    </Dialog>
                    {geoFence ? (
                        <div className="col-12 lg:col-12">
                            <div className="datatable-selection-demo">
                                <div className="card">
                                    <DataTable value={geoFence} dataKey="" responsiveLayout="scroll" headerColumnGroup={tableHeader} rows={10} paginator>
                                        <Column field="name"></Column>
                                        <Column field="category"></Column>
                                        <Column body={Location}></Column>
                                        <Column body={radiusConvert}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default GeoFence;
