import React, { useContext, useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { RTLContext } from "./App";
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { poolData } from "./lib/constants";
import { removeDomainName } from "./utilities/utillFunction";

const userPool = new CognitoUserPool(poolData);
const AppTopbar = (props) => {
    let nqpermissions = JSON.parse(localStorage.getItem("permissions"));
    const { serviceTypeName } = JSON.parse(localStorage.getItem("service"));
    const [isAdmin, setIsAdmin] = useState(false);
    const isRTL = useContext(RTLContext);
    const history = useHistory();
    const [custLogo, setCustLogo] = useState("");
    const [logo2, setLogo2] = useState("");
    const image = localStorage.getItem("profileImg");
    let admin = 0;
    JSON.parse(localStorage.getItem("permissions")).map((item) => { if (item === "BuildOperateDataPipelineSetup.READ" || item === "BuildOperateDataPipelineSetup.WRITE") admin++; })
    let descriptor;
    descriptor = JSON.parse(localStorage.getItem("service")).descriptor;
    useEffect(() => {
        let imgs = JSON.parse(localStorage.getItem("service")).customer.images;
        let logo = JSON.parse(localStorage.getItem("service")).customer.logo2;
        setLogo2(logo);
        if (imgs) setCustLogo(imgs[imgs.length - 1]);
        if (nqpermissions && nqpermissions.length) {
            let userPersmissions = nqpermissions.find((p) => {
                return p === "UserManagement.WRITE";
            });
            if (userPersmissions) {
                setIsAdmin(true);
            }
        }
    }, []);

    const goLogin = () => {
        history.push("/login");
    };

    const signout = async () => {
        cognitoSignout();
        localStorage.clear();
        localStorage.clear();
        goLogin();
    };

    const cognitoSignout = () => {
        let userData = {
            Username: localStorage.getItem("email"),
            Pool: userPool,
        };

        let cognitoUser = new CognitoUser(userData);
        cognitoUser.signOut();
    };

    const onServiceChange = () => {
        history.push("/services");
    };

    const onManageUsers = () => {
        history.push("/manage-users");
    };

    const onAddThing = () => {
        history.push("/allThings");
    };

    const onLogoClick = () => {
        let menu = JSON.parse(localStorage.getItem("menu")) || [];
        if (menu.length > 0) {
            history.push(removeDomainName(menu[0].url));
        } else {
            history.push("/");
        }
    };
    const changePassword = () => {
        history.push("/change-user-password");
    };
    const userNameOnNavbaar = (name) => {
        let finalName = ""
        if(name){
            if(name.length >= 18)
                finalName = name.slice(0,16) + "..."
            else finalName = name
        }
        return finalName
    }

    return (
        <div className="layout-topbar shadow-4 ">
            <div className="layout-topbar-left">
                <button type="button" style={{ cursor: "pointer" }} className="layout-topbar-logo p-link" onClick={() => onLogoClick()}>
                    {descriptor === "Big Rock Mountain"
                        ? (<img id="ti-logo" src={process.env.REACT_APP_IMAGE_STATIC_URL + "/" + logo2} alt=""
                            style={{ height: "2.25rem" }}
                        />) : (
                            <img id="app-logo" src={process.env.PUBLIC_URL + "/assets/layout/images/logo-light.png"}
                                alt="ultima-layout"
                                style={{ height: "2.25rem" }}
                            />
                        )}
                </button>
                <button type="button" className="layout-menu-button shadow-6 p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-right"></i>
                </button>
                <button type="button" className="layout-topbar-mobile-button p-link">
                    <i className="pi pi-ellipsis-v fs-large" onClick={props.onMobileTopbarButtonClick}></i>
                </button>
            </div>

            <div className={classNames("layout-topbar-right", { "layout-topbar-mobile-active": props.mobileTopbarActive })}>
                {custLogo ? (
                    <div className="layout-topbar-actions-left lg:col-offset-5 lg:col-4">
                        <img id="ti-logo" src={process.env.REACT_APP_IMAGE_STATIC_URL + "/" + custLogo} alt="" style={{ height: "3.5rem" }} />
                    </div>
                ) : null}
                {/* <div className="layout-topbar-actions-left"> */}
                {/* <MegaMenu model={model} className="layout-megamenu" /> */}
                {/* </div> */}
                <div className=" ml-auto layout-topbar-actions-right m-0 p-0">
                    <ul className="layout-topbar-items ">
                        <li className="layout-topbar-items">
                            <button className="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-link" onClick={(event) => history.push("/help")}>
                                <i className="pi pi-question-circle fs-large"></i>
                            </button>
                        </li>
                        <li className="layout-topbar-item">
                            <div className="profile-box m-0 lg:mr-2" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: "profile" })} style={{cursor:"pointer"}}>
                                <button className="" style={{ backgroundColor: "white", border: "none", cursor: "pointer" }} >
                                    {image ? <img src={process.env.REACT_APP_IMAGE_STATIC_URL + "/" + image} alt="avatar" style={{ width: "30px", height: "30px",borderRadius:"50%" }} /> : <i className="pi pi-user fs-large "></i>}
                                </button>
                                <div className="lg:col-9 flex flex-column m-0 p-0 ">
                                    <p className="m-0 p-0" style={{ fontSize: "0.85rem", fontWeight: "700", fontStyle: "Inter", color: "#0F172A" }}>{userNameOnNavbaar(localStorage.getItem("username"))}</p>
                                    <p className="m-0 p-0 " style={{ color: "#74788D", fontSize: "12px", fontWeight: "700" }}>{admin === 2 && "Admin"}</p>
                                </div>
                            </div>
                            <CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === "profile"} unmountOnExit>
                                <ul className="layout-topbar-action-panel shadow-6" style={{ borderTop: "1px solid #e4e4e4", padding: "0" }}>
                                    <li className="layout-topbar-action-item" style={{ borderBottom: "1px solid #e4e4e4" }}>
                                        <div className="flex flex-row p-link">
                                            <button className="  px-2 rounded-circle p-link" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: "profile" })}>
                                                {image ? <img src={process.env.REACT_APP_IMAGE_STATIC_URL + "/" + image} alt="avatar" style={{ width: "40px", height: "40px" }} /> : null}
                                            </button>
                                            <div className={image ? "col-8" : "col-10"}>
                                                <div style={{ marginLeft: "1.6rem" }}>
                                                    <div className="font-bold text-center">{localStorage.getItem("username")}</div>
                                                    <div className="text-center">{localStorage.getItem("email")}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="layout-topbar-action-item" onClick={onServiceChange}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className="fa-solid fa-arrows-spin mr-2"></i>
                                            <span>Change Service</span>
                                        </button>
                                    </li>
                                    {isAdmin ? (
                                        <li className="layout-topbar-action-item" onClick={onManageUsers}>
                                            <button className="flex flex-row align-items-center p-link">
                                                <i className="fa-regular fa-user mr-2"></i>
                                                <span>Manage Users</span>
                                            </button>
                                        </li>
                                    ) : null}
                                    {isAdmin ? (
                                        <li className="layout-topbar-action-item" onClick={onAddThing}>
                                            <button className="flex flex-row align-items-center p-link">
                                                <i className="pi pi-plus mr-2"></i>
                                                <span>Add Thing</span>
                                            </button>
                                        </li>
                                    ) : null}
                                    {isAdmin && (serviceTypeName.includes("Fuel") || serviceTypeName.includes("fuel")) ? (
                                        <li className="layout-topbar-action-item" onClick={() => history.push("/calibration-upload")}>
                                            <button className="flex flex-row align-items-center p-link">
                                                <i className="pi pi-upload mr-2"></i>
                                                <span>Calibration Upload</span>
                                            </button>
                                        </li>
                                    ) : null}
                                    {localStorage.getItem("emailId") === "support@nextqore.com" && (serviceTypeName === "File Sharing Utility") ? (
                                        <li className="layout-topbar-action-item" onClick={() => history.push("/user-group")}>
                                            <button className="flex flex-row align-items-center p-link">
                                                <i className="pi pi-users mr-2"></i>
                                                <span>Manage Vendors</span>
                                            </button>
                                        </li>
                                    ) : null}
                                    {/*serviceTypeName.includes("Temperature Monitoring") ? <li className="layout-topbar-action-item flex flex-wrap">
                                        <button className="flex flex-row align-items-center p-link">
                                            <img style={{ marginRight: "0.5rem" }} src={process.env.PUBLIC_URL + "/images/change-password-icon.svg"} />
                                            <span>Unit</span>
                                        </button>
                                        <div style={{ paddingLeft: "1.5rem" }}>
                                            Imperial
                                            <InputSwitch checked={switchValue} style={{ marginLeft: "0.8rem", marginRight: "0.8rem" }} onChange={(e) => handleUnitChange(e)} />
                                            Metric
                                        </div>
                                    </li> : null*/}
                                    <li className="layout-topbar-action-item" onClick={changePassword}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <img style={{ marginRight: "0.5rem" }} src={process.env.PUBLIC_URL + "/images/change-password-icon.svg"} />
                                            <span>Change Password</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={signout}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames("pi pi-power-off", { "mr-2": !isRTL, "ml-2": isRTL })}></i>
                                            <span>Logout</span>
                                        </button>
                                    </li>
                                </ul>
                            </CSSTransition>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AppTopbar;
