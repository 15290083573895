import React,{memo} from 'react'
import moment from 'moment';

const Greeting = ({name = "", style={}, className=""}) => {

    const getGreetingMessage = () => {
        const currentHour = moment().hour();
    
        if (currentHour >= 5 && currentHour < 12) {
            return "Good Morning";
        } else if (currentHour >= 12 && currentHour < 17) {
            return "Good Afternoon";
        } else if (currentHour >= 17 && currentHour < 21) {
            return "Good Evening";
        } else {
            return "Good Night";
        }
    };

    return (
        <h5  className={className} style={{ color: "#2A2E35", ...style }}>{`👋 Hey ${name}, ${getGreetingMessage()}.`}</h5>
    )
}

export default memo(Greeting)