import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import { TabView, TabPanel } from "primereact/tabview";
import service from '../../service/apiService';
import moment from "moment";
import { colorPalettes } from "../../lib/constants";
import { dateFormatter, formatToTwoDigits } from "../../utilities/utillFunction";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { chartHeadingStyle } from "../../utilities/constant";

const B2COrders = () => {
    const serviceId = localStorage.getItem('serviceId')
    const [forPeriod, setForPeriod] = useState([])
    const [today, setToday] = useState([])
    const [yesterday, setYesterday] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)
    const [todayInfoMsg, setTodayInfoMsg] = useState(null)
    const [yesterdayInfoMsg, setYesterdayMsg] = useState(null)
    const [lineChart, setLineChart] = useState(null)
    const [lastPeriod, setLastPeriod] = useState(null)
    const todayDt = moment().format("YYYY-MM-DD")

    useEffect(() => {
        getChartData()
        getData(todayDt, "forPeriod")
        getData(todayDt, 'today')
    }, [])
    const getCollection = (type) => {
        let collection = {}
        if (type === "forPeriod") {
            collection = {
                'cr': 'nq-dashboard-b2c-orders-cr-by-channel-period-meta',
                'received': 'nq-dashboard-b2c-orders-by-channel-period-meta',
                'shipped': 'nq-dashboard-b2c-orders-shipped-by-channel-period-meta'
            }
        }
        else if (type === "today" || type === "yesterday") {
            collection = {
                'cr': 'nq-dashboard-b2c-orders-cr-by-channel',
                'received': 'nq-dashboard-b2c-orders-by-channel',
                'shipped': 'nq-dashboard-b2c-orders-shipped-by-channel'
            }
        }
        return collection
    }
    const getData = (date, type) => {
        const collections = getCollection(type)
        let query = { filter: { date: date, fdChannelDesc: { "$ne": "CMS" } } }

        Promise.all([
            service.httpDBService(query, serviceId, collections.cr),
            service.httpDBService(query, serviceId, collections.received),
            service.httpDBService(query, serviceId, collections.shipped)
        ])
            .then((res) => {
                let cr_data = res[0], rec_data = res[1], ship_data = res[2]
                let cr_data_len = cr_data.length, rec_data_len = rec_data.length, ship_data_len = ship_data.length
                if (!res[0].length && !res[1].length && !res[2].length) {
                    if (type == "today") setTodayInfoMsg("Data is not available for the day")
                    else if (type == "yesterday") setYesterdayMsg("Data is not available")
                    return
                }
                
                let ecomm_arr = [], rec_ecomm_map = {}, ship_ecomm_map = {}, cr_ecomm_map = {}
                if (cr_data_len) {
                    cr_data.forEach(c => {
                        cr_ecomm_map[c.fdChannelDesc + c.Status] = c
                        ecomm_arr.push(c.fdChannelDesc)
                    })
                }
                if (rec_data_len) {
                    rec_data.forEach(c => {
                        rec_ecomm_map[c.fdChannelDesc] = c
                        ecomm_arr.push(c.fdChannelDesc)
                    })
                }
                if (ship_data_len) {
                    ship_data.forEach(c => {
                        ship_ecomm_map[c.fdChannelDesc] = c
                        ecomm_arr.push(c.fdChannelDesc)
                    })
                }

                if (type == 'forPeriod' && rec_data.length) {
                    
                    let dt_obj = { from: rec_data[0].date + " " + formatToTwoDigits(rec_data[0].start_hr) + ":00:00", to: rec_data[0].date + " " + formatToTwoDigits(rec_data[0].end_hr) + ":00:00" }
                    setLastPeriod(dt_obj)
                }

                let ecomm_set = new Set(...[ecomm_arr])
                let ecomm_ch = [...ecomm_set], arr = []
                ecomm_ch.sort().forEach(e => {
                    let succ = cr_ecomm_map[e + 'Successful'] ? cr_ecomm_map[e + 'Successful']?.Count || 0 : 0, fail = cr_ecomm_map[e + 'Failed'] ? cr_ecomm_map[e + 'Failed']?.Count || 0 : 0;
                    let pending = cr_ecomm_map[e + 'Pending'] ? cr_ecomm_map[e + 'Pending']?.Count || 0 : 0, inprocess = cr_ecomm_map[e + 'In Process'] ? cr_ecomm_map[e + 'In Process']?.Count || 0 : 0
                    let pod = ship_ecomm_map[e] ? ship_ecomm_map[e].Shipped_Count_POD || 0 : 0, psm = ship_ecomm_map[e] ? ship_ecomm_map[e].Shipped_Count_PSM || 0 : 0
                    let rec = rec_ecomm_map[e] ? rec_ecomm_map[e].totalOrderQty : 0
                    let obj = {
                        ecommerce: e, rec: rec, cr_pending: pending, cr_in_process: inprocess, cr_success: succ,
                        cr_failure: fail, ship_pod: pod, ship_psm: psm
                    }
                    arr.push(obj)
                })
                if (type == 'forPeriod') setForPeriod(arr)
                else if (type == 'today') {
                    setToday(arr)
                }
                else if (type == 'yesterday') setYesterday(arr)
            }
            ).catch(e => {
                console.error('Error while getting data', e)
            })
    }
    const getChartData = async () => {
        let prev10dayDt = moment().subtract(10, "days").format("YYYY-MM-DD")
        let dis_query = {
            operation: "distinct",
            key: "fdChannelDesc",
            query: { date: { $gte: prev10dayDt, $lte: todayDt }, fdChannelDesc: { "$ne": 'CMS' } }
        }
        let dis_channel = await service.httpDBService(dis_query, serviceId, 'nq-dashboard-b2c-orders-by-channel')
        let group_by = { _id: '$date' }
        let channel_chart_data = {}
        dis_channel.forEach(c => {
            group_by[c] = { $sum: { $cond: [{ $eq: ["$fdChannelDesc", c] }, '$totalOrderQty', 0] } }
            channel_chart_data[c] = []
        })

        let orders_q = {
            operation: "aggregate",
            aggregate: [
                { $match: { date: { $gte: prev10dayDt, $lte: todayDt } } },
                { $group: group_by },
                { $sort: { _id: 1 } }
            ]
        }

        let channel_data = await service.httpDBService(orders_q, serviceId, 'nq-dashboard-b2c-orders-by-channel')
        let dates = []
        if (channel_data.length) {
            channel_data.forEach(o => {
                dates.push(dateFormatter(o._id, 'DD MMM', 'YYYY-MM-DD'))
                dis_channel.forEach(c => {
                    channel_chart_data[c].push(o[c])
                })
            })
        }
        let series = []
        dis_channel.forEach((c, i) => {
            series.push({ name: c, data: channel_chart_data[c], color: colorPalettes['color' + (i + 1)] })
        })

        let lineChartData = {
            chart: {
                type: "spline",
            },
            title: {
                text: "Orders Received Trend",
                style: chartHeadingStyle,
            },

            xAxis: {
                categories: dates,
            },
            yAxis: {
                title: {
                    text: "Orderd Received ",
                },
                labels: {
                    formatter: function () {
                        return this.value + "";
                    },
                },
            },
            tooltip: {
                crosshairs: true,
                shared: true,
            },
            plotOptions: {
                spline: {
                    dataLabels: {
                        enabled: true
                    },
                },
            },
            series: series
        }

        setLineChart(lineChartData)

    }

    const customHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="blue-bg" header="" colSpan={6} className="text-center"></Column>
                <Column headerClassName="blue-bg" header="Shipped" colSpan={2} className="text-center"></Column>
                <Column style={{backgroundColor: "#80808059"}} header="" className="text-center"></Column>
            </Row>
            <Row>
                <Column headerClassName="blue-bg" header="" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Received" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="CR Pending" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="CR In Process" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="CR Success" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="CR Failure" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="POD" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="PSM" className="text-center" ></Column>
                <Column style={{backgroundColor: "#80808059"}} header=" % Shipped (18hrs)" className="text-center" ></Column>
            </Row>
        </ColumnGroup>
    )
    const TableView = (data) => {
        return (
            <div>
                <DataTable value={data} responsiveLayout="scroll" showGridlines headerColumnGroup={customHeader}>
                    <Column headerClassName="blue-bg" className="text-center" field='ecommerce'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='rec'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='cr_pending'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='cr_in_process'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='cr_success'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='cr_failure'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='ship_pod'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='ship_psm'></Column>
                    <Column bodyStyle={{backgroundColor: "#80808059"}} className="text-center" field='shiiped_18_hr'></Column>
                </DataTable>
            </div>
        )
    }
    const onTabChange = (e) => {
        if (e.index === 1 || e.index === 2) {
            let dt = todayDt
            let type = 'today'
            if (e.index === 2) {
                dt = moment().subtract(1, "days").format("YYYY-MM-DD")
                type = 'yesterday'
            }
            if ((e.index === 1 && !today.length) || (e.index === 2 && !yesterday.length)) getData(dt, type)
        }
        setActiveIndex(e.index)
    }
    return (
        <>
            <div className="col-12" style={{ overflow: "auto" }}>
                <div class="card border-radius-12px pt-0 pt-2 px-0 font-medium mb-2">
                    <p className="pl-3 mb-1 text-2xl font-bold">For Today</p>
                    <p className="m-0 pl-3 text-xl font-semibold" style={{ color: "#74788D" }}>Received</p>
                    <div className="flex flex-wrap">
                        {
                            today.length ?
                                today.map((t, i) => {
                                    return (
                                        <div key={'received' + i} className="lg:col-4 p-0 border-right-1  border-300 flex-grow-1 text-2xl">
                                            <p className="m-0 text-center text-lg" style={{ color: "#5E6771" }}>{t.ecommerce}</p>
                                            <p className={"text-5xl m-0 text-center font-bold color" + (i + 1)}>{t.rec || 0}</p>
                                        </div>
                                    )
                                })
                                : null
                        }
                    </div>
                    <p className="mt-2 m-0 pl-3 text-xl font-semibold" style={{ color: "#74788D" }}>Shipped</p>
                    <div className="flex flex-wrap">
                        {
                            today.length ?
                                today.map((t, i) => {
                                    return (
                                        <div key={'shipped' + i} className="lg:col-4 p-0 border-right-1  border-300 flex-grow-1 text-2xl">
                                            <p className="m-0 text-center text-lg" style={{ color: "#5E6771" }}>{t.ecommerce}</p>
                                            <p className={"text-5xl m-0 text-center font-bold color" + (i + 1)}>{(t.ship_pod + t.ship_psm) || 0}</p>
                                        </div>
                                    )
                                })
                                : null
                        }
                    </div>
                </div>
            </div>
            <div className="col-12 pt-0">
                <div className="card border-radius-12px">
                    <HighchartsReact highcharts={Highcharts} options={lineChart} />
                </div>
            </div>

            <div className="mt-3">
                <h4 className="mb-1">Orders</h4>
            </div>
            <TabView activeIndex={activeIndex} onTabChange={onTabChange}>
                <TabPanel header="For The Period">
                    {lastPeriod ?
                        <>
                            <div className="displayCard bg-white mb-2">
                                {"From:  " + lastPeriod.from + " To : " + lastPeriod.to}
                            </div>

                        </> : null}
                    {forPeriod.length ? TableView(forPeriod) : null}
                </TabPanel>
                <TabPanel header="For The Day">
                    {
                        today.length ? TableView(today) : todayInfoMsg ?
                            <div className="my-info-message">{todayInfoMsg}</div> : null
                    }
                </TabPanel>
                <TabPanel header="Previous Day">
                    {
                        yesterday.length ? TableView(yesterday) : yesterdayInfoMsg ?
                            <div className="my-info-message">{yesterdayInfoMsg}</div> : null
                    }
                </TabPanel>
            </TabView>

        </>
    )
}
export default B2COrders;