import React, { useEffect, useState } from 'react';
import ItemCurrentStatus from '../ChartsComponents/ItemCurrentStatus';
import GeneralBarChart from '../tollPlaza/GeneralBarChart';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { plazaServices } from '../tollPlaza/plazaReportService';
import { convertValue } from '../../utilities/unitConversion';
import { stationaryVehicle } from '../../lib/constants';
import Service from '../../service/dbService';
import { Dialog } from 'primereact/dialog'

export const VehicleSummary = () => {
    const endDay = moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD");
    const startDay = moment().subtract(30, "days").startOf("day").format("YYYY-MM-DD");
    const [things, setThings] = useState([])
    const [currentStatus, setCurrentStatus] = useState()
    const [fleetMileage, setFleetMilageData] = useState()
    const [modelWiseChart, setmodelWiseChart] = useState()
    const [vehicleScore, setVehicleScore] = useState()
    const [scoreDist, setScoreDist] = useState()
    const [showModal, setShowModal] = useState({ makeModal: false, fuelDefuel: false });
    const [usageSummaryData, setUsageSummaryData] = useState()
    const [fuelInventory, setFuelInventory] = useState({ vol: 0, cost: 0 })
    const [fuelInvetoryMake, setFuelINventoryMake] = useState([])
    const [fuelCost, setFuelCost] = useState()
    const [fuelingDefueling, setFuelingDefueling] = useState()
    const [harshParams, setHarashParams] = useState()
    const [listView, setListView] = useState({ current: false, drivingScore: false, fuelingDefueling: false })
    const serviceID = localStorage.getItem("serviceId");
    const service = JSON.parse(localStorage.getItem("service"));
    const unitSys = service.region.includes("us") ? "FPS" : "MKS";
    const name = localStorage.getItem("username")
    const pieColor = ["#81BE56", "#FFD81E", "#FF898B"]
    const colors = {
        color_1: "#5182FF", color_2: "#12C3FF", color_3: "#FFD81E", color_4: "#5182FF", color_5: "#2A9D8F"
    }

    const populateCharts = (thingArr, Res) => {
        const to_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        const from_date = moment().subtract(30, "days").format("YYYY-MM-DD");
        const vehicleNumber = {};
        const mileagePayload = {
            operation: "aggregate",
            aggregate: [
                { $match: { startTime: { $gte: moment().subtract(7, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss"), $lte: moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD HH:mm:ss") } } },
                { $group: { _id: { date: "$date" }, avgMilage: { $sum: "$totalDistanceTravelled" } } },
                { $sort: { "_id.date": -1 } },
            ],
        };
        const usageSummaryPayload = {
            operation: "aggregate",
            aggregate: [

                {
                    $match: {
                        "startTime": {
                            "$gte": startDay,
                            "$lte": endDay
                        },
                        "thingName": {
                            $in: thingArr
                        }
                    }
                },
                {
                    $group: {
                        "_id": "$thingName",
                        "maxDistanceInDay": {
                            "$max": "$totalDistanceTravelled"
                        },
                        "noOfDays": {
                            "$sum": 1
                        },
                        "avgDailyDistance": {
                            "$avg": "$totalDistanceTravelled"
                        },
                        "totalDistance": {
                            "$sum": "$totalDistanceTravelled"
                        }
                    }
                }
            ]

        }
        const fuelingDefuelingPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        date: {
                            $gte: from_date,
                            $lte: to_date,
                        },
                        "thingName": {
                            $in: thingArr
                        }
                    },
                },
                {
                    $group: {
                        _id: {
                            type: "$type",
                            thingName: "$thingName",

                        },
                        amount: {
                            $sum: "$amount",
                        },
                    },
                },
            ],
        };
        if (thingArr.length) {
            let less50 = 0;
            let less60 = 0;
            let less75 = 0;
            let less90 = 0;
            let less100 = 0;
            let onMove = 0;
            let stationary = 0;
            let modelWiseDataSet = [];
            const endTime = moment().format("YYYY-MM-DD HH:mm:ss");
            const startTime = moment().subtract(1, 'hours').format("YYYY-MM-DD HH:mm:ss");
            const payload1 = {
                filter: {
                    date: moment().subtract(1, "days").format("YYYY-MM-DD"),
                },
            };
            let filterObj = {
                ts: {
                    $gte: startTime,
                    $lte: endTime,
                }
            }
            Promise.all([plazaServices.general(serviceID, { filter: filterObj }, "nq-obd-meta"),
            plazaServices.general(serviceID, mileagePayload, "nq-obd-history"),
            //  plazaServices.general(serviceID, {}, "nq-fleets"), 
            plazaServices.general(serviceID, payload1, "nq-obd-history"),
            plazaServices.general(serviceID, {}, "nq-fuel-meta"),
                 /*plazaServices.general(serviceID, usageSummaryPayload, "nq-obd-history")*/]).then((res) => {
                let arr = []
                res = [res[0], res[1], Res, res[2], res[3]]
                res[2].map((item) => {
                    vehicleNumber[item.fleetName] = {
                        "fleetType": item.fleetType,
                        ...item.vehicle,
                        "alias": item.alias
                    }
                })
                res[3].map((item) => {
                    if (vehicleNumber[item.thingName] && !stationaryVehicle.includes(vehicleNumber[item.thingName].fleetType)) {
                        if (item.greendriving?.driveroverallscore) {
                            if (item.greendriving.driveroverallscore < 50) {
                                less50 += 1;
                            } else if (item.greendriving.driveroverallscore >= 50 && item.greendriving.driveroverallscore < 60) {
                                less60 += 1;
                            } else if (item.greendriving.driveroverallscore >= 60 && item.greendriving.driveroverallscore < 75) {
                                less75 += 1;
                            } else if (item.greendriving.driveroverallscore >= 75 && item.greendriving.driveroverallscore < 90) {
                                less90 += 1;
                            } else {
                                less100 += 1;
                            }
                        }
                        vehicleNumber[item.thingName] = {
                            ...vehicleNumber[item.thingName],
                            "score": item.greendriving.driveroverallscore,
                            "thingName": item.thingName,
                            ...item.greendriving

                        }
                        arr.push(vehicleNumber[item.thingName])
                    }
                })
                for (let i = 0; i < arr.length; i++) {
                    for (let j = i + 1; j < arr.length; j++) {
                        if (arr[i].score < arr[j].score) {
                            let temp = arr[i];
                            arr[i] = arr[j];
                            arr[j] = temp;
                        }
                    }
                }
                const obj = {
                    "title": "Driving Score Distribution",
                    "data": [
                        {
                            "name": "< 50",
                            "y": less50,
                            "drilldown": "< 50",
                            "color": pieColor[0]
                        },
                        {
                            "name": "50-60",
                            "y": less60,
                            "drilldown": "50-60",
                            "color": pieColor[1]
                        },
                        {
                            "name": "60-75",
                            "y": less75,
                            "drilldown": "60-75",
                            "color": pieColor[2]
                        },
                        {
                            "name": "75-90",
                            "y": less90,
                            "drilldown": "75-90",
                            "color": colors.color_1
                        },
                        {
                            "name": "90-100",
                            "y": less100,
                            "drilldown": "90-100",
                            "color": colors.color_2
                        }
                    ],
                }
                setScoreDist(obj)
                setVehicleScore(arr)
                /*let tempArr = [...arr]
                setHarashParams(tempArr.reverse())*/
                const fleetData = res[2];
                let thingName_alias_map = {}
                fleetData.forEach(r => {
                    thingName_alias_map[r.fleetName] = r.alias || ""
                })
                let date = [];
                let avgMilage = [];
                let newArray = res[1].map((item) => {
                    let date = item._id.date.split("-");
                    let newDate = `${date[1]}-${date[2]}-${date[0]}`;
                    return {
                        date: newDate,
                        avgMilage: convertValue(item.avgMilage, "distance", unitSys, true, false),
                    };
                });
                newArray.forEach((item) => {
                    date.push(moment(item.date, "MM-DD-YYYY").format("MMM DD"));
                    avgMilage.push(parseFloat(item.avgMilage));
                });
                setFleetMilageData({
                    title: `Fleet Mileage (Last 7 Days)`,
                    data: [{ name: "Mileage", data: avgMilage, color: pieColor[0],unit:"Km" }],
                    category: date,
                });
                if (res[0].length) {
                    res[0].forEach((item, index) => {
                        if (!modelWiseDataSet.some((elm) => elm.name.toLowerCase() === `${item.make}(${item.model})`)) {
                            modelWiseDataSet.push({
                                name: item.make.toUpperCase() + "(" + item.model.toUpperCase() + ")",
                                y: 1,
                                color: colors[`color_${7 % (index + 1)}`],
                                model: item.model
                            });
                        } else {
                            let i = modelWiseDataSet.findIndex((elm) => elm.name.toLowerCase() === `${item.make}(${item.model})`);
                            if (i > -1) {
                                modelWiseDataSet[i].y += 1;
                            }
                        }
                        if (item.ignition === 1) {
                            onMove += 1;
                        } else if (item.ignition === 0) {
                            stationary += 1;
                        }
                    });
                }
                const Current = {
                    "title": `Current Status - (Total ${onMove + stationary})`,
                    "data": [
                        {
                            "name": "Moving",
                            "y": onMove,
                            "color": pieColor[0]
                        },
                        {
                            "name": "Stationary ",
                            "y": stationary,
                            "color": pieColor[1]
                        },
                    ]
                }
                setCurrentStatus(Current)
                setmodelWiseChart({
                    title: "Distribution by Make/Model",
                    data: modelWiseDataSet,
                });
                let fuelInventoryVol = [],
                    fuelInventoryVolCost = [],
                    fuelInventryCat = [];
                const fuelInventoryData = res[4];
                let fuelInventory = []
                let totalFuelVol = 0;
                let totalFuelCost = 0;
                fuelInventoryData.forEach((item) => {
                    let today = moment().format("YYYY-MM-DD");
                    let tsDate = moment(item.ts).format("YYYY-MM-DD");
                    if (moment(tsDate, "YYYY-MM-DD").isSame(moment(today, "YYYY-MM-DD"))) {
                        fuelInventryCat.push((vehicleNumber[item.thingName]["alias"] || item.vehicleNumber.toUpperCase()));
                        fuelInventoryVol.push(item.fuelLevelCalibrated);
                        fuelInventoryVolCost.push(parseFloat((item.fuelrate * item.fuelLevelCalibrated).toFixed(2)));
                        fuelInventory.push({ alias: vehicleNumber[item.thingName]["alias"], vol: item.fuelLevelCalibrated, cost: item.fuelrate * item.fuelLevelCalibrated.toFixed(2) })
                        totalFuelVol += item.fuelLevelCalibrated
                        totalFuelCost += ((item.fuelrate * item.fuelLevelCalibrated));
                    }
                    setFuelINventoryMake(fuelInventory)
                    setFuelInventory({ vol: totalFuelVol, cost: totalFuelCost })
                });
                const fuelConsumedPayload = {
                    operation: "aggregate",
                    aggregate: [
                        {
                            "$match": {
                                "date": {
                                    "$gte": from_date,
                                    "$lte": to_date
                                },
                                "thingName": {
                                    $in: thingArr
                                }
                            }
                        },
                        {
                            "$group": {
                                "_id": "$thingName",
                                "totalRunHours": {
                                    "$sum": "$totalrunhours"
                                },
                                "fuelConsumed": {
                                    "$sum": "$fuelConsumed"
                                },
                                "fuelConsumedCost": {
                                    "$sum": "$fuelConsumedCost"
                                },
                                "noOfDays": {
                                    "$sum": 1
                                },
                                "maxRunHours": {
                                    "$max": "$totalrunhours"
                                }
                            }
                        }
                    ]
                }

                const payload3 = {
                    operation: "aggregate",
                    aggregate: [
                        {
                            $match: {
                                startTime: {
                                    $gte: moment(from_date).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                                    $lte: moment(to_date).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                                },
                                "thingName": {
                                    $in: thingArr
                                }
                            },
                        },
                        {
                            $group: {
                                _id: {
                                    thingName: "$thingName",
                                },
                                total_harse_acc: {
                                    $sum: "$greendriving.harsh_acc",
                                },
                                total_harsh_brak: {
                                    $sum: "$greendriving.harsh_brak",
                                },
                                total_harsh_corn: {
                                    $sum: "$greendriving.harsh_corn",
                                },
                                total_harsh_speeding: {
                                    $sum: "$greendriving.harsh_speeding",
                                },
                                greendriving: {
                                    $avg: "$greendriving.driveroverallscore",
                                },
                            },
                        },
                        {
                            $sort: {
                                "greendriving": 1,
                            },
                        },
                        {
                            $limit: 5
                        }
                    ],
                };
                Promise.all([plazaServices.general(serviceID, usageSummaryPayload, "nq-obd-history"), plazaServices.general(serviceID, fuelConsumedPayload, "nq-daily-history"), plazaServices.general(serviceID, fuelingDefuelingPayload, "nq-fuelling-history"), plazaServices.general(serviceID, payload3, "nq-obd-history")]).then((res) => {
                    let avgDailyDistance = [],
                        maxDistanceInDay = [],
                        totalDays = [],
                        vehicleCategory = [],
                        mixCategory = [];
                    let cc = 0;
                    res[0].forEach((item) => {
                        let avgDD = convertValue(item.avgDailyDistance, "distance", unitSys);
                        let maxDD = convertValue(item.maxDistanceInDay, "distance", unitSys);
                        avgDailyDistance.push(parseFloat(avgDD));
                        maxDistanceInDay.push(parseFloat(maxDD));
                        totalDays.push(item.noOfDays);
                        if (item._id && vehicleNumber[`${item._id}`]) {
                            mixCategory.push(vehicleNumber[`${item._id}`]["alias"]);
                            cc++;
                        }
                    });
                    const useageSummeryData = [
                        {
                            name: "Avg. Daily Distance(Km)",
                            type: "column",
                            data: avgDailyDistance,
                            unit:"Km",
                            color: colors.color_1,

                        },
                        {
                            name: "Days Travelled",
                            type: "line",
                            data: totalDays,
                            color: colors.color_3,
                        },
                        {
                            name: "Max Distance per Day(Km)",
                            type: "column",
                            unit:"Km",
                            data: maxDistanceInDay,

                            color: colors.color_2,

                        },
                    ];
                    setUsageSummaryData({ title: "Vehicle Wise Usage Summary for Last 30 Days", data: useageSummeryData, category: mixCategory, })
                    let fuelConsumptionVol = [];
                    let fuelConsumptionRate = [];
                    let fuelConsumptionCategory = []
                    res[1].map((item) => {
                        fuelConsumptionVol.push(Number(item.fuelConsumed.toFixed(2)))
                        fuelConsumptionRate.push(Number((item.fuelConsumedCost * 0.001).toFixed(2)))
                        if (vehicleNumber[item._id] && vehicleNumber[item._id]["alias"]) fuelConsumptionCategory.push(vehicleNumber[item._id]["alias"])
                        else fuelConsumptionCategory.push(item._id)
                    })
                    const fuelConsumedTitle = "Monthly Fuel Consumption (Volume and Cost)";
                    const fuelConsumedyAxis = [
                        {
                            title: {
                                text: "Consumption Volume in litres",
                            },
                        },
                        {
                            title: {
                                text: "Consumption Currency in Rs",
                            },
                            opposite: true,
                        },
                    ];
                    const fuelConsumedydata = [
                        {
                            type: "column",
                            data: fuelConsumptionVol,
                            unit:"L",
                            name: "Volume",
                            color: "#FFBA47",
                        },
                        {
                            type: "column",
                            data: fuelConsumptionRate,
                            name: "Cost In Thousand",
                            color: "#6877A4",
                        },
                    ];
                    setFuelCost({ title: fuelConsumedTitle, category: fuelConsumptionCategory, data: fuelConsumedydata, yAxis: fuelConsumedyAxis });
                    let fueling = [];
                    let defueling = [];
                    let fuelCount = 0;
                    let deFuelCount = 0;
                    let category = []
                    res[2].map((item) => {
                        category.push(vehicleNumber[item._id?.thingName]?.alias)
                        let temp = item.amount % 1 !== 0 ? Number(item.amount.toFixed(2)) : item.amount
                        if (item._id.type === "fuelling") {
                            fueling.push(temp)
                            fuelCount++;
                        }
                        else {
                            defueling.push(temp)
                            deFuelCount++;
                        }
                    })
                    const arrSum = (arr) => {
                        let res = 0;
                        for (let i = 0; i < arr.length; i++) {
                            res += arr[i]
                        }
                        return res;
                    }
                    const fuelandDefueling = {
                        "title": "Fueling/Defueling",
                        category: category,
                        "data": [
                            {
                                "name": "Fueling",
                                "y": parseFloat(arrSum(fueling).toFixed(2)),
                                data: fueling,
                                count: fuelCount,
                                "drilldown": fueling,
                                "color": pieColor[0]
                            },
                            {
                                "name": "Defueling",
                                "y": parseFloat(arrSum(defueling).toFixed(2)),
                                data: defueling,
                                count: deFuelCount,
                                "drilldown": defueling,
                                "color": pieColor[2]
                            },
                        ],
                    }
                    setFuelingDefueling(fuelandDefueling)
                    let arr = res[3].map((item) => ({
                        "alias": vehicleNumber[item["_id"]["thingName"]]["alias"] || "", // Assign alias directly
                        ...item,
                    }));
                    setHarashParams(arr)
                })
            })
        }
    }

    useEffect(() => {
        let getThing = async () => {
            let projection = {
                "_id": 1,
                "location": 1,
                "alias": 1,
                "vehicle": 1,
                "serviceId": 1,
                "fleetType": 1,
                "fleetName": 1,
                "image": 1,
                "fleetId": 1,
                "fleetArn": 1,
                "repairable": 1,
                "replacable": 1,
                "component": 1,
                "isDeleted": 1,
                "isActive": 1,
                "addedOn": 1,
                "modifiedOn": 1,
                "__v": 1
            }
            let res = await Service.getThingNames(serviceID, projection);
            const thingNames = [];
            res.map((item) => { thingNames.push(item.fleetName); })
            setThings(thingNames);
            populateCharts(thingNames, res)
        }
        getThing()
    }, []);

    useEffect(() => {


    }, [])

    const drivingScoreCard = (item) => {
        const src = `${process.env.REACT_APP_IMAGE_STATIC_URL}/${serviceID}/${item.thingName}.jpg`
        //const src = process.env.REACT_APP_IMAGE_STATIC_URL + "/" + this.serviceID + "/" + item.thingName + ".jpg"
        return <div key={item.thingName} className='drivingScoreCard col-12 grid justify-content-between mb-4 line-height-1 '>
            <div className='lg:col-3 col-12 m-0 p-0' style={{ borderRadius: "8px", border: "1px solid rgba(116, 120, 141, 0.20)", height: "4.375rem", width: "4.375rem" }} >
                <img src={src} className='col-12 h-full' style={{ objectFit: "fit", width: "100%", height: "4.375rem", width: "4.375rem" }}></img>
            </div>
            <div className='flex flex-column text-l lg:col-9 col-12 ' style={{ color: "#5E6771", fontSize: "12px", }}>
                <p className='m-0 p-0' style={{}}>License Plate No: <span style={{ color: "#1D2129" }} className=' font-bold'>{item.vehicleNumber}</span></p>
                <p className='m-0 p-0' style={{}}>Make: <span style={{ color: "#1D2129", }} className=' font-bold'>{item.make}</span></p>
                <p className='col-12 inline-flex justify-content-between m-0 p-0' style={{}}>
                    <span className='m-0 p-0 font-bold' style={{ color: "#1D2129" }}>
                        <span className=' font-normal' style={{ color: "#5E6771" }} >Model:</span> {item.model}
                    </span>
                    <span style={{ lineHeight: "1rem" }}>Score:
                        <span className=' font-bold' style={{ color: "#8CC63F" }}>
                            {Number.isInteger(item.score) ? item.score : item.score.toFixed(2)}
                        </span>
                    </span>
                </p>
            </div>
        </div>
    }

    const newListForm = (arr,headerArr) => {
        return <DataTable
            value={arr}
            selectionMode='single'
            rows={4}
            style={{ marginTop: '0' }}
            paginator
            className='mt-1'
            paginatorTemplate="PrevPageLink NextPageLink">
            <Column
                headerClassName=' bg-white font-bold text-blue-700 '
                header={headerArr[0]}
                bodyClassName='bg-white bodyStyle'
                field="name">
            </Column>
            <Column
                headerClassName=' bg-white headStyle text-blue-700'
                header={headerArr[1]}
                bodyClassName='bg-white bodyStyle2 '
                field="y">
            </Column>
        </DataTable>

    }

    const listForm = (arr) => {
        return <DataTable
            value={arr}
            selectionMode='single'
            rows={4}
            style={{ marginTop: '0' }}
            paginator
            className='mt-1'
            paginatorTemplate="PrevPageLink NextPageLink">
            <Column
                headerClassName=' bg-white font-bold text-blue-700 '
                header="Category"
                bodyClassName='bg-white bodyStyle'
                field="name">
            </Column>
            <Column
                headerClassName=' bg-white headStyle text-blue-700'
                header="Volume(Ltr)"
                bodyClassName='bg-white bodyStyle2 '
                field="y">
            </Column>
            <Column
                headerClassName='bg-white headStyle text-blue-700'
                header="Events"
                field="count"
                bodyClassName='bodyStyle2'>
            </Column>
        </DataTable>

    }

    const getGreetingMessage = () => {
        const currentHour = moment().hour();

        if (currentHour >= 5 && currentHour < 12) {
            return "Good Morning";
        } else if (currentHour >= 12 && currentHour < 17) {
            return "Good Afternoon";
        } else if (currentHour >= 17 && currentHour < 21) {
            return "Good Evening";
        } else {
            return "Good Night";
        }
    };

    return (
        <div className=' grid mb-2 w-full justify-content-center m-0' style={{ fontFamily: "Inter" }}>
            <h5 className="mb-0 col-12" style={{ color: "#2A2E35" }}>{`👋 Hey ${name}, ${getGreetingMessage()}.`}</h5>
            <div className='flex flex-wrap w-full justify-content-around'>
                <div className="lg:col-4 col-12 lg:mb-0 mb-2" style={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
                    <div className="card generalCard" style={{ flex: 1 }}>
                        <div className='col-12 flex justify-content-between align-items-center m-0 p-0'>
                            <p className='chartHeading m-0' style={{ fontSize: '16px', lineHeight: '1.5' }}>
                                {currentStatus && currentStatus.title}
                            </p>
                            <Button label={`View ${listView["current"] ? "Chart" : "List"}`} onClick={() => {
                                setListView({
                                    ...listView,
                                    current: !listView["current"]
                                })
                            }} style={{ backgroundColor: "white", color: "#0C6291", textDecoration: "underline" }} />
                        </div>
                        {currentStatus && !listView.current ? <ItemCurrentStatus chartData={currentStatus} legend={true} radius={true} pie={false} /> : currentStatus && newListForm(currentStatus["data"],["Status","Number Of Vehicles"])}
                    </div>
                </div>
                <div className="lg:col-5 col-12 px-3" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="card generalCard" style={{ flex: 1 }}>
                        <div className='col-12 flex justify-content-between'>
                            <p className='chartHeading m-0' style={{ fontSize: '16px', lineHeight: '1.5' }}>
                                {fleetMileage && fleetMileage.title}
                            </p>
                        </div>
                        {fleetMileage && <GeneralBarChart chartData={fleetMileage} height={180} dontShow={true} />}
                    </div>
                </div>
                <div className="lg:col-3 col-12 flex flex-column" >
                    <div className="generalCard card"
                        style={{ flexGrow: 1 }}>
                        <div className='col-12 flex justify-content-between align-items-center m-0 p-0'>
                            <p className='chartHeading m-0' style={{ fontSize: '16px', lineHeight: '1.5', fontWeight: "600" }}>
                                {modelWiseChart && modelWiseChart.title}
                            </p>
                            <Button label="View All" onClick={() => { setShowModal({ ...showModal, makeModal: true }) }} style={{ backgroundColor: "white", color: "#0C6291", textDecoration: "underline" }} />
                        </div>
                        {modelWiseChart && <DataTable
                            value={modelWiseChart["data"]}
                            selectionMode='single'
                            rows={2}
                            style={{ marginTop: '0' }}
                            paginator
                            className='mt-1'
                            scrollable
                            paginatorTemplate="PrevPageLink NextPageLink">
                            <Column
                                style={{minWidth:"9rem"}}
                                headerClassName=' bg-white font-medium text-blue-700 font-bold'
                                header="Vehicle"
                                bodyClassName='textStyle'
                                field="name">
                            </Column>
                            <Column
                                headerClassName='bg-white font-medium text-blue-700 font-bold'
                                header="Model"
                                field="model"
                                bodyClassName='bodyStyle2'>
                            </Column>
                            <Column
                                headerClassName='bg-white font-medium text-blue-700 font-bold'
                                header="Value"
                                field="y"
                                bodyClassName='bodyStyle2'>
                            </Column>
                        </DataTable>}
                    </div>
                </div>
            </div>
            <div className='flex flex-wrap w-full justify-content-around align-items-stretch'  >
                <div className=" col-12 lg:col-9 flex flex-column"  >
                    <div className="generalCard card"
                        style={{ flexGrow: 1, }}>
                        <div className='col-12 flex justify-content-between align-items-center m-0 p-0' style={{ maxWidth: "400px" }}>
                            <p className='chartHeading mb-1' style={{ fontSize: '16px', lineHeight: '1.5' }}>
                                {usageSummaryData && usageSummaryData.title}
                            </p>
                        </div>
                        {usageSummaryData && <GeneralBarChart chartData={usageSummaryData} legend={true} height={220} scroll={{ overflowX: 'auto' }} dontShow={true} />}
                    </div>
                </div>
                <div className=" col-12 lg:col-3 flex flex-column" >
                    <div className="generalCard card"
                        style={{ flexGrow: 1 }}>
                        <div className='col-12 flex justify-content-between align-items-center m-0 p-0'>
                            <p className='chartHeading m-0 inline-flex flex column' style={{ fontSize: '16px', lineHeight: '1.5' }}>
                                Current Fuel Inventory
                            </p>
                        </div>
                        {fuelInvetoryMake && <DataTable
                            value={fuelInvetoryMake}
                            selectionMode='single'
                            rows={4}
                            style={{ marginTop: '0' }}
                            paginator
                            className='mt-1'
                            paginatorTemplate="PrevPageLink NextPageLink">
                            <Column
                                headerClassName='text-blue-700 font-bold'
                                style={{ maxWidth: "2rem" }}
                                header="Vehicle"
                                bodyClassName='text-style'
                                body={(e) => { return <span className='p-2'>{e.alias}</span> }}>
                            </Column>
                            <Column
                                headerClassName="headStyle text-blue-700"
                                style={{ maxWidth: "4rem" }}
                                header={
                                    <>
                                        {fuelInventory.vol.toFixed(2)}L <br /> Volume
                                    </>
                                }
                                body={(e) => (
                                    <span style={{ color: colors.color_5 }}>
                                        {Number.isInteger(e.vol) ? e.vol : parseFloat(e.vol.toFixed(2))}
                                    </span>
                                )}
                                bodyClassName="bodyStyle2"
                            />
                            <Column
                                headerClassName="headStyle text-blue-700"
                                style={{ maxWidth: "4rem" }}
                                header={
                                    <>
                                        {`${new Intl.NumberFormat("en-IN", {
                                            style: "currency",
                                            currency: "INR",
                                        }).format(fuelInventory.cost * 0.00001)}Lakhs`}
                                        <br />
                                         Cost
                                    </>
                                }
                                body={(e) => (
                                    <span style={{ color: colors.color_4 }}>
                                        {Number.isInteger(e.cost) ? e.cost : Math.round(e.cost)}
                                    </span>
                                )}
                                bodyClassName="bodyStyle2"
                            />
                        </DataTable>}
                    </div>
                </div>
            </div>
            <div className='flex flex-wrap w-full justify-content-around align-items-stretch'>
                <div className=" col-12 lg:col-3 flex flex-column" >
                    <div className="generalCard card"
                        style={{ flexGrow: 1 }}>
                        <div className='col-12 flex justify-content-between align-items-center m-0 p-0'>
                            <p className='chartHeading m-0' style={{ fontSize: '16px', lineHeight: '1.5' }}>
                                {fuelingDefueling && fuelingDefueling.title}
                            </p>
                            <Button label={`Show Details`} onClick={() => {
                                setShowModal({ ...showModal, fuelDefuel: true })
                            }} style={{ backgroundColor: "white", color: "#0C6291", textDecoration: "underline" }} />
                        </div>
                        {fuelingDefueling && listForm(fuelingDefueling["data"])}
                    </div>

                </div>
                <div className=" col-12 lg:col-9 flex flex-column" >
                    <div className="generalCard card"
                        style={{ flexGrow: 1 }}>
                        <div className='col-12 flex justify-content-between align-items-center m-0 p-0'>
                            <p className='chartHeading m-0' style={{ fontSize: '16px', lineHeight: '1.5' }}>
                                {fuelCost && fuelCost.title}
                            </p>
                        </div>
                        {fuelCost && < GeneralBarChart chartData={fuelCost} dontShow={true} legend={true} />}
                    </div>

                </div>
            </div>
            <div className='flex flex-wrap w-full justify-content-around align-items-stretch'>
                <div className="lg:col-4 col-12 flex flex-column" style={{ maxHeight: "28rem" }}>
                    <div className="generalCard card"
                        style={{ flexGrow: 1, overflow: "auto" }}>
                        <div className='col-12 flex justify-content-between align-items-center m-0 p-0'>
                            <p className='chartHeading m-0 mb-4 mt-1' style={{ fontSize: '16px', lineHeight: '1.5' }}>
                                {"Best Driven Vehicles"}
                            </p>
                        </div>
                        {
                            vehicleScore && vehicleScore.map((item) => {
                                return drivingScoreCard(item)
                            })
                        }
                    </div>
                </div>
                <div className="lg:col-4 col-12 flex flex-column lg:mb-0 mb-2"
                    style={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
                    <div className="generalCard card"
                        style={{ flexGrow: 1 }}>
                        <div className='col-12 flex justify-content-between align-items-center m-0 p-0'>
                            <p className='chartHeading m-0' style={{ fontSize: '16px', lineHeight: '1.5' }}>
                                {scoreDist && scoreDist.title}
                            </p>
                            <Button label={`View ${listView["drivingScore"] ? "Chart" : "List"}`} onClick={() => {
                                setListView({
                                    ...listView,
                                    drivingScore: !listView["drivingScore"]
                                })
                            }} style={{ backgroundColor: "white", color: "#0C6291", textDecoration: "underline" }} />
                        </div>
                        {
                            scoreDist && !listView["drivingScore"] ? <ItemCurrentStatus chartData={scoreDist} legend={false} radius={true} height={310} pie={false} /> : scoreDist && newListForm(scoreDist["data"],["Score","Number Of Vehicles"])
                        }
                    </div>
                </div>
                <div className="lg:col-4 col-12 flex flex-column">
                    <div className="generalCard card"
                        style={{ flexGrow: 1 }}>
                        <div className='col-12 flex flex-column justify-content-between align-items-center m-0 p-0'>
                            <p className='chartHeading m-0 col-12 p-0' style={{ fontSize: '16px', }}>
                                {"Top 5 Aggressively Driven Vehicle"}
                            </p>
                        </div>
                        {modelWiseChart && (
                            <DataTable
                                value={harshParams}
                                selectionMode="single"
                                rows={5}
                                paginator
                                style={{ marginTop: '0', }}
                                className="mt-1"
                                paginatorTemplate="PrevPageLink NextPageLink"
                                scrollable
                            >
                                <Column
                                    headerClassName="bg-white font-medium text-blue-700"
                                    header="Vehicle"
                                    bodyClassName="bg-white font-medium surface-300 bg-gray-200 text-l"
                                    field="alias"
                                ></Column>
                                <Column
                                    headerClassName="headStyle text-blue-700"
                                    header="Harsh Acceleration"
                                    body={(e) => (
                                        <div className="p-0 flex justify-content-around">
                                            <p style={{ color: '#5182FF' }}>{e.total_harse_acc}</p>
                                        </div>
                                    )}
                                    bodyClassName="bg-white font-medium surface-300 bg-gray-200 col-1 text-l"
                                ></Column>
                                <Column
                                    headerClassName="bg-white headStyle text-blue-700"
                                    header="Harsh Braking"
                                    body={(e) => (
                                        <div className="p-0 flex justify-content-around">
                                            <p style={{ color: '#FFBA47' }}>{e.total_harsh_brak}</p>
                                        </div>
                                    )}
                                    bodyClassName="bg-white font-medium surface-300 bg-gray-200 col-1 text-l"
                                ></Column>
                                <Column
                                    headerClassName="bg-white headStyle text-blue-700"
                                    header="Harsh Cornering"
                                    body={(e) => (
                                        <div className="p-0 flex justify-content-around">
                                            <p style={{ color: '#FF898B' }}>{e.total_harsh_corn}</p>
                                        </div>
                                    )}
                                    bodyClassName="bg-white font-medium surface-300 bg-gray-200 col-1 text-l"
                                ></Column>
                            </DataTable>
                        )}
                    </div>
                </div>
            </div>
            <Dialog header={showModal["makeModal"] ? modelWiseChart.title : "Details for Fueling and Defueling Events"} visible={showModal["makeModal"] || showModal["fuelDefuel"]} modal style={{ width: "60vw" }} onHide={() => setShowModal({ makeModal: false, fuelDefuel: false })}>
                <div className="col-12">
                    {showModal["makeModal"] ? <ItemCurrentStatus chartData={modelWiseChart} legend={false} radius={false} pie={false} /> : <GeneralBarChart chartData={fuelingDefueling} height={280} dontShow={true} legend={true} />}
                </div>
            </Dialog>
        </div>
    );
};
