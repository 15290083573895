
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { drivingScoreCard } from "../newVehTrackingWithFuel/Components";

const CardList = ({ data, onSelectItem, selectedItem, isVehicle }) => {
    const [dataList, setDataList] = useState(data);
    const duplicateData = data;
    const [value, setValue] = useState("");
    const handleSeaarch = (e) => {
        setValue(e.target.value);
        if (value !== "") {
            const filterData = duplicateData.filter((item) => {
                return Object.values(item).join("").toLowerCase().includes(value.toLowerCase());
            });
            setDataList(filterData);
        } else {
            setDataList(data);
        }
    };
    return (
        <>
            <div className="col-12 md:col-12 flex fex-column justify-content-center align-items-center" >
                <InputText style={{
                    borderRadius: "1.25rem", // 20px = 20 / 16 = 1.25rem
                    border: "1px solid rgba(116, 120, 141, 0.20)",
                    background: "#FAFAFA"
                }} value={value} onChange={(e) => handleSeaarch(e)} placeholder="search" className="w-full mb-1 lg:mr-2" />
            </div>
            <div className="app-list-grid  grid justify-content-center m-0" style={{display:"flex",flexWrap:"wrap",justifyContent:"center",width:"full"}}>
                {dataList && dataList.map((item) => {
                    return drivingScoreCard(item, (e) => { onSelectItem(item); e.stopPropagation() }, selectedItem,"",dataList)
                })
                }
            </div>
        </>
    );
};

export default CardList;
