import React, { useEffect, useState } from 'react'
import { VehicleSrvice } from '../../service/VehicleService'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { colorPalettes, pieChartColors } from "../../utilities/constant";
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { secondsToHm } from '../../utilities/utillFunction';
import ItemCurrentStatus from '../ChartsComponents/ItemCurrentStatus';
import { Dialog } from 'primereact/dialog';
import { stationaryVehicle } from '../../lib/constants';
import GroupBarChart from '../ChartsComponents/GroupBarChart';

const currentMonthYear = moment().format("YYYY-MM");
const prev6MonthYear = moment().subtract(5, "months").format("YYYY-MM");
const colors = ["#FFBA47", "#6877A4", "#BD7EBE"]

const reportsObj = {
    "defuellingVolume": { "key": "defuelVolume", "order": "desc", "name": "By Defuelling Volume", yAxisTitle: 'Volume (Liters)', chart: true },
    "defuellingEvents": { "key": "defuelEvts", "order": "desc", "name": "By Number Of Defuelling Events", yAxisTitle: 'Events', chart: true },

    "highUtilizedAWP": { "key": "totalRunHours", "order": "desc", "name": "Highest utilized AWP", chart: false, machine: true },
    "nofOfDays_RunHourGreaterThanMaxLimit": { "key": "days_rhGt8hr", "order": "desc", "name": "No of days (Run hour > 8 hour)", chart: false, machine: true },

    "lowUtilizedAWP": { "key": "totalRunHours", "order": "asc", "name": "Lowest utilized AWP", chart: false, machine: true },
    "nofOfDays_RunHourLessThanMinLimit": { "key": "days_rhLt1hr", "order": "desc", "name": "No of days (Run hour < 8 hour)", chart: false, machine: true },

    "highUtilizedVehicles": { "key": "totalDistanceTravelled", "order": "desc", "name": "By Distance Travelled", yAxisTitle: 'Distance', chart: true, vehicles: true },
    "nofOfDays_DistanceGreaterThanMaxLimit": { "key": "days_disGt100km", "order": "desc", "name": "Days - Distance Travelled > 100 km", yAxisTitle: "Days", chart: true, vehicles: true },

    "lowUtilizedVehicles": { "key": "totalDistanceTravelled", "order": "asc", "name": "By Distance Travelled", yAxisTitle: 'Distance', chart: true, vehicles: true },
    "nofOfDays_DistanceLessThanMinLimit": { "key": "days_disLt20km", "order": "desc", "name": "Days - Distance Travelled < 20 km", yAxisTitle: 'Days', chart: true, vehicles: true },
}

const LongTermReport = () => {
    const serviceID = localStorage.getItem("serviceId");
    const [chartsData, setChartsData] = useState()
    const [tableData, setTableData] = useState()
    const [aliasModal, setAliasModal] = useState(false)
    const [intervalDefuelling, setIntervalDefuelling] = useState()
    const [aliasToShow, setAliasToShow] = useState(null)
    const [thingMap, setThingMap] = useState()
    const vehicleService = new VehicleSrvice()
    const [errMsg, setErrMsg] = useState("")
    useEffect(() => {
        let dateQuery = { 'year-month': { "$gte": prev6MonthYear, "$lte": currentMonthYear } }
        let locationwiseQuery = {
            operation: "aggregate",
            aggregate: [
                { $match: dateQuery },
                {
                    $group: {
                        _id: "$thingName", defuelEvts: { "$sum": "$defuelling.evtCnt" }, defuelVolume: { $sum: "$defuelling.amount" },
                        totalRunHours: { $sum: '$totalrunhours' }, make: { "$first": "$make" }, city: { "$first": '$city' }, totalDistanceTravelled: { "$sum": '$totalDistanceTravelled' },
                        days_rhLt1hr: { $sum: "$days_runHourlt1hr" }, days_rhGt8hr: { $sum: "$days_runHourgt8hr" }, days_disLt20km: { $sum: "$days_dislt20km" }, days_disGt100km: { $sum: "$days_disgt100km" }
                    }
                },
                { $group: { _id: "$city", things: { "$push": "$$ROOT" } } }
            ]
        }

        let intervalQuery = {
            operation: 'aggregate',
            aggregate:
                [
                    { $match: dateQuery },
                    {
                        $group: {
                            _id: "$city",
                            '0-2': { "$sum": '$defuelling.0-2.event' },
                            '2-4': { "$sum": '$defuelling.2-4.event' },
                            '4-6': { "$sum": '$defuelling.4-6.event' },
                            '6-8': { "$sum": '$defuelling.6-8.event' },
                            '8-10': { "$sum": '$defuelling.8-10.event' },
                            '10-12': { "$sum": '$defuelling.10-12.event' },
                            '12-14': { "$sum": '$defuelling.12-14.event' },
                            '14-16': { "$sum": '$defuelling.14-16.event' },
                            '16-18': { "$sum": '$defuelling.16-18.event' },
                            '18-20': { "$sum": '$defuelling.18-20.event' },
                            '20-22': { "$sum": '$defuelling.20-22.event' },
                            '22-24': { "$sum": '$defuelling.22-24.event' }
                        }
                    },
                ]
        }
        Promise.all([vehicleService.getFleetsData(serviceID, false, { filter: {}, projection: { _id: 0, fleetName: 1, alias: 1, fleetType: 1 } }), vehicleService.getAggregateMonthlyAnalytics(locationwiseQuery), vehicleService.getAggregateMonthlyAnalytics(intervalQuery)]).then(res => {
            let thingMap = {}
            if (res.length && res[0] && res[0].length) {
                res[0].forEach(r => {
                    thingMap[r.fleetName] = r
                })
            }
            setThingMap(thingMap)
            if (res[1] && res[1].length) {
                let chartObj = {}
                let tableObj = {}
                Object.keys(reportsObj).forEach((report) => {
                    let keys = reportsObj[report].key
                    let copyOfArray = JSON.parse(JSON.stringify(res[1]))
                    copyOfArray.forEach((item, index) => {
                        copyOfArray[index].things.forEach(t => {
                            t.type = thingMap[t._id].fleetType
                        })

                        if (reportsObj[report].vehicles) {
                            copyOfArray[index].things = copyOfArray[index].things.filter(f => {
                                return (f[keys] > 0 && !stationaryVehicle.includes(f.type))
                            })
                        }
                        else if (reportsObj[report].machine) {
                            copyOfArray[index].things = copyOfArray[index].things.filter(f => {
                                return (f[keys] > 0 && stationaryVehicle.includes(f.type))
                            })
                        } else {
                            copyOfArray[index].things = copyOfArray[index].things.filter(f => {
                                return (f[keys] > 0)
                            })
                        }
                        copyOfArray[index].things.sort((a, b) => {
                            if (reportsObj[report].order === "desc") return (b[keys] - a[keys])
                            else return (a[keys] - b[keys])
                        })
                    })
                    let filterArray = copyOfArray.filter(f => f.things.length)

                    if (!reportsObj[report].chart) {
                        tableObj[report] = JSON.parse(JSON.stringify(filterArray))
                    } else {
                        /*chartData={{...chartsData["defuellingVolume"],
                            categories:chartsData["defuellingVolume"]?.xAxis?.categories,
                            data:chartsData["defuellingVolume"]?.series

                        }}*/
                        let chart = createChartData(report, JSON.parse(JSON.stringify(filterArray)), true)
                        chart.series = chart.series.map((item, ind) => {
                            return {
                                ...item,
                                color: colors[ind]

                            }
                        })
                        chart = {
                            ...chart,
                            categories: chart?.xAxis?.categories,
                            data: chart.series
                        }
                        chartObj[report] = chart
                    }
                })
                setTableData(tableObj)
                setChartsData(chartObj)
            } else {
                setErrMsg("Report data not generated")
            }

            if (res[2] && res[2].length) {
                let locationWiseData = []
                res[2].forEach(r => {
                    let intervalDataSet = []
                    Object.keys(r).forEach((d, index) => {
                        if (d !== '_id')
                            intervalDataSet.push({
                                name: d,
                                y: r[d],
                                color: pieChartColors[index],
                            })
                    })
                    locationWiseData.push({
                        title: r._id + " - Defuelling events count by hours of day",
                        data: intervalDataSet
                    })
                })
                setIntervalDefuelling(locationWiseData)
            }
        })
    }, [])

    const showTittle = (text) => {
        const titleMap ={
            "highUtilizedVehicles":"By Distance Travelled",
            "nofOfDays_DistanceGreaterThanMaxLimit":"Days - Distance Travelled > 100 km",
            "lowUtilizedVehicles":"By Distance Travelled",
            "nofOfDays_DistanceLessThanMinLimit":"Days - Distance Travelled < 20 km",
            "defuellingVolume":"By Defuelling Volume",
            "defuellingEvents":"By Number Of Defuelling Events"

        }
        return <div className='col-12 flex justify-content-between mt-3 mb-0'>
            <p className='chartHeading' style={{ fontSize: "16px" }}>{titleMap[text]}</p>
        </div>
    }

    const createChartData = (reportType, chartData, isLocationWise) => {
        let chartInfo = {
            name: reportsObj[reportType].name || "",
            yAxisTitle: reportsObj[reportType].yAxisTitle || "",
            key: reportsObj[reportType].key || ""
        }

        if (isLocationWise) {
            const chartPlotData = {
                categories: [],
                top1: [],
                top2: [],
                top3: []
            }
            chartData.forEach((item) => {
                chartPlotData.categories.push(item._id)
                if (item.things.length) {
                    for (let i = 0; i <= 2; i++) {
                        if (item.things[i]) {
                            chartPlotData[`top${i + 1}`].push({ y: Number(item.things[i][reportsObj[reportType].key].toFixed(2)), thing: item.things[i], rank: `top${i + 1}` })
                        }
                        else {
                            chartPlotData[`top${i + 1}`].push({ y: null, thing: item.things[i], rank: `top${i + 1}` })
                        }
                    }
                }
                else {
                    chartPlotData.top1.push({ y: null, thing: null, rank: null })
                    chartPlotData.top2.push({ y: null, thing: null, rank: null })
                    chartPlotData.top3.push({ y: null, thing: null, rank: null })
                }
            })
            let chartres = getChartOPtion({}, chartInfo, chartPlotData)
            chartres.series = [{
                name: "Top 1",
                data: chartPlotData.top1,
                color: colorPalettes.color5,
                tooltip: {
                    valueSuffix: chartInfo.key === "totalDistanceTravelled" ? "Km" : "",
                },
            },
            {
                name: "Top 2",
                data: chartPlotData.top2,
                color: colorPalettes.color4,
                tooltip: {
                    valueSuffix: chartInfo.key === "totalDistanceTravelled" ? "Km" : "",
                },
            },
            {
                name: "Top 3",
                data: chartPlotData.top3,
                color: colorPalettes.color2,
                tooltip: {
                    valueSuffix: chartInfo.key === "totalDistanceTravelled" ? "Km" : "",
                },
            }]
            return chartres
        }
    }

    const getChartOPtion = (chartObj, chartInfo, plotData) => {
        return {
            ...{
                chart: {
                    height: "420px",
                    type: "column",

                },
                title: {
                    text: chartInfo.name,
                    style: {
                        fontSize: "18px",
                        fontWeight: "700",
                        textTransform: "capitalize",
                        fontFamily: "Roboto",
                        color: "black"
                    },
                },
                xAxis: {
                    categories: plotData.categories,
                    crosshair: true,
                    title: {
                        text: "Location"
                    },
                    scrollbar: {
                        enabled: true
                    },
                    gridLineWidth: 1,
                },
                yAxis: {
                    title: {
                        text: chartInfo.yAxisTitle,
                    },
                    crosshair: true,
                    labels: {
                        format: `{value} ${chartInfo.key === "totalDistanceTravelled" ? "Km" : ""}`,
                    },
                    gridLineWidth: 0
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><th style="color:{series.color};padding:0;text-transform:uppercase">{series.name}: </th>' + '<th style="padding:0"><b>{point.y:.1f}</b></th></tr>',
                    footerFormat: "</table>",
                    shared: true,
                    useHTML: true,
                },
                plotOptions: {
                    column: {
                        pointPadding: 0,
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true
                        },
                        groupPadding: 0.2
                    },
                    series: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: onChartClick.bind(this, chartInfo)
                            }
                        }
                    }

                },
            }, ...chartObj
        };
    }

    const onChartClick = (chartInfo, evt) => {
        let obj = { ...evt.point.thing }
        obj.rank = evt.point.rank
        obj.chartInfo = chartInfo
        setAliasToShow(obj)
        setAliasModal(true)
    }

    const showThingData = (row, key, value) => {
        let thing;
        switch (key) {
            case 'top1':
                thing = row.things[0]
                break;
            case 'top2':
                thing = row.things[1]
                break;
            case 'top3':
                thing = row.things[2]
                break;
        }
        return (
            <div>
                {thing ?
                    <><div className='first-letter-cap'>{thingMap[thing._id] ? thingMap[thing._id].alias : thing._id}</div><hr />
                        <div>{value === 'totalRunHours' ? secondsToHm(thing[value]) : thing[value]}</div>
                    </>
                    : null}
            </div>
        )
    }

    const showIcon = (key) => {
        return (
            <div>
                <div> <i className="pi pi-car" style={{ padding: '0.5rem', color: "#0C6291" }}></i></div><hr />
                <div><i className={(key == 'totalRunHours' ? "pi pi-clock" : "pi pi-calendar")} style={{ padding: '0.5rem', color: "#81BE56" }}></i></div>
            </div>
        )
    }

    const showAliasName = (e) => {
        return (
            thingMap ? <div>{thingMap[e._id].alias}</div> : <div>{e._id}</div>
        )
    }

    const showValue = (e) => {
        return (
            <div>{e[e['chartInfo']['key']]}</div>
        )
    }

    const showHeader = (e) => {
        return (e.props && e.props.value && e.props.value.length && e.props.value[0] && e.props.value[0].chartInfo ? e.props.value[0].chartInfo.yAxisTitle : "")
    }

    const getDialog = () => {
        return (
            <Dialog header={""} visible={aliasModal} modal style={{ width: '50vw' }} onHide={() => onCancel()} >
                <DataTable className='color1-bg' value={[aliasToShow]} responsiveLayout="scroll">
                    <Column headerClassName="color1-bg" bodyClassName="color2-bg first-letter-cap" style={{ width: "100px" }} search header="Alias" field={(e) => showAliasName(e)}></Column>
                    <Column headerClassName="color1-bg" bodyClassName="color2-bg first-letter-cap" style={{ width: "100px" }} search header="City" field="city"></Column>
                    <Column headerClassName="color1-bg" bodyClassName="color2-bg first-letter-cap" style={{ width: "100px" }} search header={(e) => showHeader(e)} field={(e) => showValue(e)}></Column>
                </DataTable>
            </Dialog>
        )
    }

    const onCancel = () => {
        setAliasToShow(null)
        setAliasModal(false)
    }

    return (
        <>
            {errMsg ?
                <div className="col-12 " style={{ color: "#01579B" }}>
                    <div className="card flex flex-column align-items-center justify-content-center" style={{ height: "30vh", backgroundColor: "#B3E5FC" }}>
                        <span className="fa-solid fa-circle-info" style={{ fontSize: "3rem" }}></span>
                        <h3>{errMsg}</h3>
                    </div>
                </div> :
                <div className='col-12'>
                    {getDialog()}
                    <div className='col-12 mb-2'>
                        <div className=''>
                            <h3 className='chartHeading'>Vehicles - High Utilization </h3>
                            <div className='grid justify-content-between align-items-center'>
                                <div className='col-12 lg:col-6 '>
                                    <div className='card generalCard'>
                                        {showTittle("highUtilizedVehicles")}
                                        {chartsData && chartsData["highUtilizedVehicles"] ? <GroupBarChart chartData={chartsData["highUtilizedVehicles"]} /> : <Skeleton height="32rem"></Skeleton>}
                                    </div>
                                </div>
                                <div className='col-12 lg:col-6 mt-2'>
                                    <div className='card generalCard'>
                                        {showTittle("nofOfDays_DistanceGreaterThanMaxLimit")}
                                        {chartsData && chartsData["nofOfDays_DistanceGreaterThanMaxLimit"] ? <GroupBarChart chartData={chartsData["nofOfDays_DistanceGreaterThanMaxLimit"]} /> : <Skeleton height="32rem"></Skeleton>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <h3 className='chartHeading'>Machines - High Utilization </h3>
                            <div className='grid justify-content-center align-items-center'>
                                <div className='col-12 lg:col-6'>
                                    <div className='col-12  card generalCard'>{tableData && tableData['highUtilizedAWP'] ?
                                        <>
                                            <h4 className='chartHeading'>By Total Run Hours</h4>
                                            <DataTable value={tableData['highUtilizedAWP']} paginator={true} rows={5} responsiveLayout="scroll">
                                                <Column headerClassName="dtHeaderStyle firstHead" bodyClassName=" first-letter-cap" style={{ width: "100px" }} search header="City" field="_id"></Column>
                                                <Column headerClassName="dtHeaderStyle" bodyClassName="" header="" field={(e) => showIcon('totalRunHours')}></Column>
                                                <Column headerClassName="dtHeaderStyle" bodyClassName="" header="Top 1" field={(e) => showThingData(e, 'top1', 'totalRunHours')}></Column>
                                                <Column headerClassName="dtHeaderStyle" bodyClassName="" header="Top 2" field={(e) => showThingData(e, 'top2', 'totalRunHours')}></Column>
                                                <Column headerClassName="dtHeaderStyle lastHead" bodyClassName="" header="Top 3" field={(e) => showThingData(e, 'top3', 'totalRunHours')}></Column>
                                            </DataTable>
                                        </>
                                        : <Skeleton height="32rem"></Skeleton>}
                                    </div>
                                </div>
                                <div className='col-12 lg:col-6'>
                                    <div className='col-12  card generalCard'>{tableData && tableData['nofOfDays_RunHourGreaterThanMaxLimit'] ?
                                        <>
                                            <h4 className='chartHeading'>{"Days - Run Hours > 8 Hour"}</h4>
                                            <DataTable value={tableData['nofOfDays_RunHourGreaterThanMaxLimit']} paginator={true} rows={5} responsiveLayout="scroll">
                                                <Column headerClassName="dtHeaderStyle firstHead" bodyClassName="first-letter-cap" style={{ width: "100px" }} header="City" field="_id"></Column>
                                                <Column headerClassName="dtHeaderStyle" bodyClassName="" style={{ width: "10px" }} header="" field={(e) => showIcon('days')}></Column>
                                                <Column headerClassName="dtHeaderStyle" bodyClassName="" style={{ width: "100px" }} header="Top 1" field={(e) => showThingData(e, 'top1', 'days_rhGt8hr')}></Column>
                                                <Column headerClassName="dtHeaderStyle" bodyClassName="" style={{ width: "100px" }} header="Top 2" field={(e) => showThingData(e, 'top2', 'days_rhGt8hr')}></Column>
                                                <Column headerClassName="dtHeaderStyle lastHead" bodyClassName="" style={{ width: "100px" }} header="Top 3" field={(e) => showThingData(e, 'top3', 'days_rhGt8hr')}></Column>
                                            </DataTable>
                                        </>
                                        : <Skeleton height="32rem"></Skeleton>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-2'>
                            {<p className='chartHeading'>Vehicles- Low Utilization</p>}
                            <div className='grid justify-content-between align-items-center'>
                                <div className='col-12 lg:col-6'>
                                    <div className='card generalCard m-0'>
                                        {showTittle("lowUtilizedVehicles")}
                                        {chartsData ? <GroupBarChart chartData={chartsData["lowUtilizedVehicles"]} /> : <Skeleton height="32rem"></Skeleton>}
                                    </div>
                                </div>
                                <div className='col-12 lg:col-6 mt-2'>
                                    <div className='card generalCard'>
                                        {showTittle("nofOfDays_DistanceLessThanMinLimit")}
                                        {chartsData ? <GroupBarChart chartData={chartsData["nofOfDays_DistanceLessThanMinLimit"]} /> : <Skeleton height="32rem"></Skeleton>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <h3 className='chartHeading'>Machines - Low Utilization</h3>
                            <div className='grid justify-content-center align-items-center'>
                                <div className='lg:col-6 col-12'>
                                <div className='col-12  card generalCard'>{tableData && tableData['lowUtilizedAWP'] ?
                                    <>
                                        <h4 className='chartHeading'>By Total Run Hours</h4>
                                        <DataTable className='color1-bg' value={tableData['lowUtilizedAWP']} paginator={true} rows={5} responsiveLayout="scroll">
                                            <Column headerClassName="dtHeaderStyle firstHead" bodyClassName="first-letter-cap" style={{ width: "100px" }} search header="City" field="_id"></Column>
                                            <Column headerClassName="dtHeaderStyle" bodyClassName="" style={{ width: "10px" }} header="" field={(e) => showIcon('totalRunHours')}></Column>
                                            <Column headerClassName="dtHeaderStyle" bodyClassName="" style={{ width: "100px" }} header="Top 1" field={(e) => showThingData(e, 'top1', 'totalRunHours')}></Column>
                                            <Column headerClassName="dtHeaderStyle" bodyClassName="" style={{ width: "100px" }} header="Top 2" field={(e) => showThingData(e, 'top2', 'totalRunHours')}></Column>
                                            <Column headerClassName="dtHeaderStyle lastHead" bodyClassName="" style={{ width: "100px" }} header="Top 3" field={(e) => showThingData(e, 'top3', 'totalRunHours')}></Column>
                                        </DataTable>
                                    </>
                                    : <Skeleton height="32rem"></Skeleton>}
                                </div>
                                </div>
                                <div className='col-12 lg:col-6'>
                                <div className='col-12  card generalCard'>{tableData && tableData['nofOfDays_RunHourLessThanMinLimit'] ?
                                    <>
                                        <h4 className='chartHeading'>{"Days - Run Hours < 1 Hour"}</h4>
                                        <DataTable value={tableData['nofOfDays_RunHourLessThanMinLimit']} paginator={true} rows={5} responsiveLayout="scroll">
                                            <Column headerClassName="dtHeaderStyle firstHead" bodyClassName=" first-letter-cap" style={{ width: "100px" }} header="City" field="_id"></Column>
                                            <Column headerClassName="dtHeaderStyle" bodyClassName="" style={{ width: "10px" }} header="" field={(e) => showIcon('days')}></Column>
                                            <Column headerClassName="dtHeaderStyle" bodyClassName="" style={{ width: "100px" }} header="Top 1" field={(e) => showThingData(e, 'top1', 'days_rhLt1hr')}></Column>
                                            <Column headerClassName="dtHeaderStyle" bodyClassName="" style={{ width: "100px" }} header="Top 2" field={(e) => showThingData(e, 'top2', 'days_rhLt1hr')}></Column>
                                            <Column headerClassName="dtHeaderStyle lastHead" bodyClassName="" style={{ width: "100px" }} header="Top 3" field={(e) => showThingData(e, 'top3', 'days_rhLt1hr')}></Column>
                                        </DataTable>
                                    </>
                                    : <Skeleton height="32rem"></Skeleton>}
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-2'>
                            {<h3 className='chartHeading'>High Defuelling</h3>}
                            <div className='grid justify-content-between align-items-center '>
                                <div className='col-12 lg:col-6 '>
                                    <div className='card generalCard'>
                                        {showTittle("defuellingVolume")}
                                        {chartsData ? <GroupBarChart chartData={chartsData["defuellingVolume"]} /> : <Skeleton height="32rem"></Skeleton>}
                                    </div>
                                </div>
                                <div className='col-12 lg:col-6 '>
                                    <div className='card generalCard'>
                                        {showTittle("defuellingEvents")}
                                        {chartsData ? <GroupBarChart chartData={chartsData["defuellingEvents"]} /> : <Skeleton height="32rem"></Skeleton>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grid w-full m-0 p-0 justify-content-between align-items-center mt-2'>
                            {
                                intervalDefuelling && intervalDefuelling.length ?
                                    intervalDefuelling.map((d, i) => {
                                        return <div className='grid col-12 lg:col-4 m-0 lg:mt-1 justify-content-center'><div className='col-12 generalCard' key={i}>
                                            <div className='col-12 flex justify-content-between'>
                                                <p className='chartHeading' style={{ fontSize: "16px" }}>{d && d.title}</p>
                                            </div>
                                            <ItemCurrentStatus chartData={d} legend={true} radius={true} pie={false} /></div></div>
                                    })
                                    : null
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default LongTermReport